








































import Vue from "vue";
import ModalDirection from "@/components/Modal.vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Watch } from "vue-property-decorator";

import { routerData } from "./router";
import ErrorView from "./pages/Error.vue";
import { colsResizeableReset, colsResizeableUpdate } from "./helpers/ApiHelper";
import "vue2-timepicker/dist/VueTimepicker.css";

let template = "";
const data: any = routerData;

@Component({
  inheritAttrs: false,
  components: {
    ModalDirection,
    ErrorView
  }
})
export default class App extends TSXComponent<void> {
  modalOpen = false;
  customerComponents = {
    Quotes: "QuotesCustomer",
    ViewQuote: "QuoteDetailsCustomer",
    Orders: "OrdersCustomer",
    ViewOrder: "OrderDetailsCustomer"
  };

  QuotesCustomer:any = null;
  QuoteDetailsCustomer:any = null;
  OrdersCustomer:any = null;
  OrderDetailsCustomer:any = null;

  async created() {
    try {
      if(localStorage.VARtemplate){
        template = `${localStorage.VARtemplate}/`
      }

      if (template) {
        [this.QuotesCustomer, this.QuoteDetailsCustomer, this.OrdersCustomer, this.OrderDetailsCustomer] = await Promise.all([
          import(`@/customer/pages/Quotes.vue`),
          import(`@/customer/pages/templates/${template}QuoteDetails.vue`),
          import(`@/customer/pages/Orders.vue`),
          import(`@/customer/pages/templates/${template}OrderDetails.vue`),
        ]);
      } else {
        [this.QuotesCustomer, this.QuoteDetailsCustomer, this.OrdersCustomer, this.OrderDetailsCustomer] = await Promise.all([
          import(`@/customer/pages/Quotes.vue`),
          import(`@/customer/pages/QuoteDetails.vue`),
          import(`@/customer/pages/Orders.vue`),
          import(`@/customer/pages/OrderDetails.vue`),
        ]);
      }
    } catch (error) {
      console.error('Error loading components:', error);
    }
  }

  mounted() {
    $(document).on("click", "span.expand-full:not(.overflowed)", () => {
      if ($("body").hasClass("expand-full")) {
        $("body").removeClass("expand-full");
      } else {
        $("body").addClass("expand-full");
      }
      colsResizeableReset();
      colsResizeableUpdate();
    });
  }

  get message() {
    return data.errorData.message;
  }

  get redirectName() {
    return data.errorData.redirectName;
  }

  get redirectQuery() {
    return data.errorData.redirectQuery;
  }

  loginCheck() {
    return sessionStorage.getItem("userRole");
  }

  openModal() {
    this.modalOpen = !this.modalOpen;
  }

  useCustomerComponents() {
    if (
      this.loginCheck() == "Customer" &&
      Object.keys(this.customerComponents).includes(this.$route.name || "")
    ) {
      return true;
    }

    return false;
  }
}
