











































































































































































































































































































































































































































































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component } from "vue-property-decorator";
import { FinancialBoardsAPIRepo, BoardDetailsSortField, BoardDetailsSort } from "@/repos/FinancesDashboardAPIRepo";
import AddFinancialBoardModal from "@/components/AddFinancialBoardModal.vue";
import CustomerAutoSuggestInput from "@/components/CustomerAutoSuggestInput.vue";
import Datepicker from "@/components/Datepicker.vue";
import LaddaButton from "@/components/LaddaButton.vue";
import { notifier } from "../models/common";

const financialBoardsRepo = new FinancialBoardsAPIRepo();

@Component({
  inheritAttrs: false,
  components: {
    AddFinancialBoardModal,
    CustomerAutoSuggestInput,
    Datepicker,
    LaddaButton
  }
})

export default class BoardDetails extends TSXComponent<void> {
  $route: any;
  $router: any;
  loading: boolean = false;
  viewType: string = "YTD";
  accounts: any[] = [];
  boardDetails: any = {};
  boardUUID: string = "";
  loadingBoardView: boolean = false;
  sort: BoardDetailsSort = {
    field: 0,
    direction: {
      [BoardDetailsSortField.revenue]: 1,
      [BoardDetailsSortField.profit]: 1
    }
  };
  addFinancialBoardModalVisible: boolean = false;
  businessLineOptions: any = [];
  isActive: string = "accounts";
  expenses: any[] = [];
  expensesBK: any[] = [];
  saving: boolean = false;

  created(){
    this.boardUUID = this.$route.params.id ? this.$route.params.id : '';
    this.fetchData(true);
  }

  async fetchData(initialLoad: boolean = false) {
    if (initialLoad) {
      this.loading = true;
    } else {
      this.loadingBoardView = true;
    }
    const response: any = await financialBoardsRepo.findOneDetails(
      this.boardUUID,
      this.viewType
    );

    if (response.STATUS === 1) {
      if (response.BOARDLIST.length) {
        this.boardDetails = response.BOARDLIST[0];
      }
      this.businessLineOptions = (response.businessLineOptions || []).map(item => ({
        ID: item.ID,
        TEXT: item.BUSINESSLINENAME,
        data: item
      }));
      this.accounts = response.ACCOUNTS || [];
      this.expenses = response.EXPENSES || [];
    }
    this.addInputRow(this.expenses.length);
    this.expensesBK = JSON.parse(JSON.stringify(this.expenses));
    this.loading = false;
    this.loadingBoardView = false;
  }

  async changeView(type) {
    if (this.viewType != type) {
      this.viewType = type;
      await this.fetchAccountData();
    }
  }

  async fetchAccountData() {
    this.loadingBoardView = true;
    const response: any = await financialBoardsRepo.findOneDetails(
      this.boardUUID,
      this.viewType,
      'loadBoradView',
      '',
      false,
      this.sort
    );

    if (response.STATUS === 1) {
      this.accounts = response.ACCOUNTS || [];
    }
    this.loadingBoardView = false;
  }

  listPageRedirection() {
    this.$router.push({ name: "Boards" });
  }

  openOrderList(item) {
    if (item.ORDERIDS) {
      this.$router.push({
        name: "Orders",
        query: {
          order: item.ORDERIDS.join()
        },
      });
    }
  }

  openOppList(item) {
    if (item.OPPCNTIDS) {
      this.$router.push({
        name: "Opportunities",
        query: {
          ms_opp_id: item.OPPCNTIDS.join()
        },
      });
    }
  }

  sorting(c) {
    switch (c) {
      case "REVENUE":
        this.sortingByRevenue();
        break;
      case "PROFIT":
        this.sortingByProfit();
        break;
    }
  }

  async sortBy(field: BoardDetailsSortField) {
    this.sort.field = field;
    this.sort.direction[field] = this.sort.direction[field] === 1 ? 2 : 1;

    for (const dirField in this.sort.direction) {
      if (dirField.toString() === field.toString()) {
        continue;
      }

      this.sort.direction[dirField] = 1;
    }
    await this.fetchAccountData();
  }

  async sortingByRevenue() {
    this.sortBy(BoardDetailsSortField.revenue);
  }

  async sortingByProfit() {
    this.sortBy(BoardDetailsSortField.profit);
  }

  async reload() {
    this.addFinancialBoardModalVisible = false;
    await this.fetchData();
  }

  toggleActive(activeTab: string = "") {
    this.isActive = activeTab;
  }

  addInputRow(index) {
    if (index === this.expenses.length) {
      var expensesNew = {
        EXPENSEUUID: "",
        EXPENSENAME: "",
        EXPENSEDATE: '',
        EXPENSEAMOUNT: 0
      };
      this.expenses.push(expensesNew);
    }
  }

  deleteExpenses(index) {
    this.$delete(this.expenses, index);
  }

  get expensesEdited() {
		return JSON.stringify(this.expenses) != JSON.stringify(this.expensesBK);
	}

  isRequired(item) {
    if (
      item.EXPENSENAME.length ||
      item.EXPENSEDATE.length
    ) {
      return "required";
    }
    return "";
  }

  async save() {
    var result = await this.$validator.validateAll();
		if (!result) {
			return;
		}

    let expensesData = this.expenses.filter((item: any) => item.EXPENSENAME.length);
    
    if (expensesData.length) {
      this.saving = true;
  
      const response: any = await financialBoardsRepo.addExpenses(
        this.boardUUID,
        expensesData
      );
  
      if (response.STATUS === 1) {
        notifier.success(response.STATUSMESSAGE);
        await this.fetchData();
      } else {
        notifier.alert(response.STATUSMESSAGE);
      }
      this.saving = false;
    }
  }
}
