





















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import LaddaButton from "../components/LaddaButton.vue";
import { notifier, printHtml } from "../models/common";
import { OrderAPIRepo } from "../repos/OrderAPIRepo";
import DropdownControl from "../components/DropdownControl.vue";
import USState from "../static/USstates.json";
import axios from "axios";
import Datepicker from "./Datepicker.vue";
import ConfirmRemoveItemModal from "@/components/ConfirmRemoveItemModal.vue";
import TemplateDetailAddModal from "@/components/Template/TemplateDetailAddModal.vue";
import { ApiHelper } from "@/helpers/all";

export enum ExportFormat {
  csv = "csv",
  pdf = "pdf",
  html = "html",
  detail = "detail",
  report = "report"
}
declare function require(params: any): any;
const Handlebars = require("handlebars/runtime.js");
interface Props {}

interface Events {
  onClose: void;
  onOk: void;
  onPrint: void;
}
interface tempObj {
  HASTEMPLATENAME?: number;
  TEMPLATEID?: number;
  ISDISTY?: number;
  TEMPLATETYPE: number;
}
declare const $: any;
const orderRepo = new OrderAPIRepo();

@Component({
  inheritAttrs: false,
  components: {
    LaddaButton,
    DropdownControl,
    Datepicker,
    ConfirmRemoveItemModal,
    TemplateDetailAddModal
  }
})
export default class ExportModal extends TSXComponent<Props, Events> {
  $refs!: {
    modal: HTMLDivElement;
  };

  @Prop({ required: false, default: false })
  iconload?: boolean | string;

  @Prop({ required: false, default: false })
  csvhide?: boolean;

  @Prop({ required: false, default: false })
  showcustometemplate?: boolean;

  @Prop({ required: false, default: false })
  showExportOrderDetail?: boolean;

  @Prop({ required: false, default: false })
  showExportPO?: boolean;

  @Prop({ required: false, default: "" })
  exportView?: string;

  @Prop({ required: false, default: 0 })
  customtemplateId?: number;

  @Prop({ required: false, default: [] })
  customertemplates?: tempObj[];

  @Prop({ required: false, default: [] })
  quotetemplates!: string[];

  @Prop({ required: false, default: "" })
  bigdeal?: string;

  @Prop({ required: false, default: "" })
  bigDealfromQuotes?: string;

  @Prop({ required: false, default: "" })
  suborders?: string;

  @Prop({ required: false, default: "" })
  skusum?: string;

  @Prop({ required: false, default: "" })
  datagrouped?: string;

  @Prop({ required: false, default: "0" })
  aztempidx?: string;

  @Prop({ required: false, default: false })
  showAdvanced?: boolean;

  @Prop({ required: false, default: false })
  showPrint?: boolean;

  @Prop({ required: false, default: {} })
  customerMessages?: any;

  @Prop({ required: false, default: {} })
  advanced?: any;

  @Prop({ required: false, default: "" })
  objectType?: string;

  @Prop({ required: false, default: false })
  includePayment?: boolean;

  @Prop({ required: false, default: "" })
  customHeader?: string;

  @Prop({ required: false, default: "" })
  currentTemplate?: string;

  @Prop({ required: false, default: false })
  showExportPaycheckDetail?: boolean;

  @Prop({ required: false, default: 0 })
  varSourceName?: string;

  @Prop({ required: false, default: false })
  showSpecialExportInvoice?: boolean;

  @Prop({ required: false, default: false })
  showSpecialExportLedger?: boolean;

  @Prop({ required: false, default: false })
  hidePDFExport?: boolean;

  @Prop({ required: false, default: false })
  hideHTMLExport?: boolean;

  @Prop({ required: false, default: false })
  isMultiEndUsers?: boolean;

  @Prop({ required: false, default: [] })
  hiddenOptions?: string[];

  @Prop({ required: false, default: ["pdf"] })
  defaultOptions?: string[];

  @Prop({ required: false, default: {} })
  templateEditDetails?: any;

  @Prop({ required: false, default: 0 })
  exportDataID!: number;

  @Prop({ required: false, default: {} })
  templateparams!: any;

  currentRole = sessionStorage.getItem("userRole");
  $root: any;
  // aztempidx = 0;
  showSectemps = false;
  pageloading = false;
  hidelineitems = false;
  showTemplateSection = true;
  tempCheck: number = 0;
  TemplateSetting: any = {};
  templateparamsBk: any = {};

  options: {
    value: ExportFormat;
    text: string;
    checked: boolean;
    disable: boolean;
    hide: boolean;
  }[] =
    this.currentRole == "Reseller"
      ? [
          {
            value: ExportFormat.pdf,
            text: "Export to PDF",
            checked: this.hidePDFExport
              ? false
              : (this.defaultOptions || []).includes(ExportFormat.pdf),
            disable: false,
            hide: (this.hiddenOptions || []).includes(ExportFormat.pdf)
          },
          {
            value: ExportFormat.csv,
            text: "Export to CSV",
            checked: this.hidePDFExport
              ? true
              : (this.defaultOptions || []).includes(ExportFormat.csv),
            disable: false,
            hide: (this.hiddenOptions || []).includes(ExportFormat.csv)
          },
          {
            value: ExportFormat.html,
            text: "Export to HTML",
            checked: (this.defaultOptions || []).includes(ExportFormat.html),
            disable: false,
            hide: (this.hiddenOptions || []).includes(ExportFormat.html)
          }
        ]
      : [
          //var customer
          {
            value: ExportFormat.pdf,
            text: "Export to PDF",
            checked: (this.defaultOptions || []).includes(ExportFormat.pdf),
            disable: false,
            hide: (this.hiddenOptions || []).includes(ExportFormat.pdf)
          },
          {
            value: ExportFormat.html,
            text: "Export to HTML",
            checked: (this.defaultOptions || []).includes(ExportFormat.html),
            disable: false,
            hide: (this.hiddenOptions || []).includes(ExportFormat.html)
          }
        ];
  saveAsDefaultTemplate = false;
  displayOnExport = true;
  exportOrderDetail = false;
  exportInvoiceAgingReport = false;
  exportLedgerAgingReport = false;
  reportingOrderDetail = false;
  exportDirectPaycheckDetail = false;
  exportIndirect = true;
  exportDirect = false;
  exportPO = false;
  exporting = false;
  pricingid = this.bigdeal;
  loading = false;
  submitted = false;
  onExport = {};
  isActive = "basic";
  advancedFilters = {
    priceRolledUp: false,
    hideIncludeItems: false,
    hideZeroPrice: false,
    includePayment: true,
    showFees: false
  };
  customMessages = {
    ShipmentNotes: "",
    QuoteNotes: "",
    EndMessage: "",
    PurchasingInstruction: "",
    FooterNotes: "",
    useDefaultMessage: false
  };
  tempPrevTemplate: any = {};
  customPrevTemplate: any = {};
  stateList: any = [];
  CustomDistyName = "";
  CustomDistyAddr1 = "";
  CustomDistyAddr2 = "";
  CustomDistyCity = "";
  addrState = "";
  CustomDistyState: string[] = [];
  CustomDistyZip = "";
  errorZip = false;
  isDistyTemplate = 0;
  today = new Date();
  hardwareCSVExport = false;
  exportInvoiceAgingDate =
    (this.today.getMonth() + 1 < 10 ? "0" : "") +
    (this.today.getMonth() + 1) +
    "/" +
    (this.today.getDate() < 10 ? "0" : "") +
    this.today.getDate() +
    "/" +
    this.today.getFullYear();
  exportETS = false;
  isHPQuote = false;
  isQuoteContact = false;
  isQuoteExpDate = false;
  builderTemplate = false;
  customTemplateDetails: any = {
    cityState: "",
    companyName: "",
    streetAddress: "",
    phoneNo: "",
    paragraphRows: [],
    editableEditParagraph: true,
    editableInformationHeader: true,
    editableRemitAddress: true,
    editableDataFields: true,
    hidePhoneNo: true,
    isHPQuote: false,
    isQuoteContact: true,
    isQuoteExpDate: true,
    quoteExpDate: true,
    showAccRep: true,
    showContactEmail: true,
    showContactInfo: true,
    showContactPhone: true,
    showInformation: true,
    showParagarph: true,
    showQuoteDate: true,
    showQuoteInfo: true,
    showQuoteName: true,
    showQuoteNumber: true,
    showRemitAddress: true,
    contactEmailContent: "",
    contactPhoneContent: "",
    footerContent: "",
    quoteNotes: "",
    orderNotes: "",
    allowOptionChanges: false,
    orderDate: false,
    orderNO: false,
    hasFooterTerms: false,
    shipAddress: false,
    customerPO: false,
    notes: false,
    invoiceId: false,
    invDate: false,
    serialNumber: false,
    assetTag: false,
    trackingInfo: false,
    remitEmail: "",
    companyURL: "",
    dueDate: false,
    invoiceTerms: false,
    contractNumber: true,
    billAddress: false,
    footerTerms: `Work under this purchase order is subject to surveillance at Seller's plant. Seller shall be notified if inspection is to be conducted on specific shipments. No shipments are to be held for inspection unless notification is received prior to, or at time of, material being ready for shipment.Seller shall maintain records of inspections, tests, and process controls called for by this purchase order. These documents shall be on file and available to Buyerfor ten (10) years following the end of the calendar year in which the purchase order was issued. At any time during the retention period, at Buyer’s request, Sellerwill deliver said records, or any part thereof, to Buyer, at no additional cost to Buyer. Certificate of Conformance may be required upon request.Please refer to Milestone Website at the following link for Quality Requirements: <a href="https://milestonetech.com/quality-requirements-and-certifications/">https://milestonetech.com/quality-requirements-and-certifications/</a>`
  };
  editableRemitAddress: boolean = true;
  editableEditParagraph: boolean = true;
  editableInformationHeader: boolean = true;
  editableDataFields: boolean = true;
  pSectionIdx: number = -1;
  contactEmailError: boolean = false;
  phoneNoError: boolean = false;
  contactPhoneNoError: boolean = false;
  confirmRemoveModalVisible: boolean = false;
  templateDetailModalVisible: boolean = false;
  templateTypeId: number = 1;
  remitEmailError: boolean = false;
  pullFromSource: boolean = true;
  customParagraphRows: any = [];
  customQuoteNotes: string = "";
  customFooterMsg: string = "";

  get selected(): ExportFormat[] {
    return this.options.filter(o => o.checked).map(o => o.value);
  }

  async created() {
    // this.pricingid = this.bigdeal;
    this.templateparamsBk = JSON.parse(JSON.stringify(this.templateparams));

    this.customMessages = {
      ShipmentNotes:
        typeof this.customerMessages.ShipmentNotes != "undefined"
          ? this.customerMessages.ShipmentNotes
          : "",
      QuoteNotes:
        typeof this.customerMessages.QuoteNotes != "undefined"
          ? this.customerMessages.QuoteNotes
          : "",
      EndMessage:
        typeof this.customerMessages.EndMessage != "undefined"
          ? this.customerMessages.EndMessage
          : "",
      PurchasingInstruction:
        typeof this.customerMessages.PurchasingInstruction != "undefined"
          ? this.customerMessages.PurchasingInstruction
          : "",
      FooterNotes:
        typeof this.customerMessages.FooterNotes != "undefined"
          ? this.customerMessages.FooterNotes
          : "",
      useDefaultMessage: false
    };
    this.advancedFilters = {
      priceRolledUp:
        typeof this.advanced.priceRolledUp != "undefined"
          ? this.advanced.priceRolledUp
          : false,
      hideIncludeItems:
        typeof this.advanced.hideIncludeItems != "undefined"
          ? this.advanced.hideIncludeItems
          : false,
      hideZeroPrice:
        typeof this.advanced.hideZeroPrice != "undefined"
          ? this.advanced.hideZeroPrice
          : false,
      includePayment:
        typeof this.advanced.includePayment != "undefined"
          ? this.advanced.includePayment
          : false,
      showFees:
        typeof this.advanced.showFees != "undefined"
          ? this.advanced.showFees
          : false
    };

    for (const i in USState) {
      this.stateList.push({
        ID: USState[i].Abbr,
        TEXT: USState[i].Abbr,
        data: USState[i]
      });
    }
    await this.gettemplate();
    this.$forceUpdate();
  }

  mounted() {
    $(this.$refs.modal).modal("show");

    if (this.objectType == "quote") {
      $(this.$refs.modal).on("hide.bs.modal", () => {
        // this.$emit("updateParams", {
        //   templateparams: this.templateparams
        // });
        this.$emit("close");
      });
    }
  }

  beforeDestroy() {
    $(this.$refs.modal).modal("hide");
  }

  async updateCheck() {
    if (
      this.objectType == "quote" &&
      (JSON.stringify(this.tempPrevTemplate) !=
        JSON.stringify(this.customTemplateDetails) ||
        JSON.stringify(this.customPrevTemplate) !=
          JSON.stringify(this.customMessages))
    ) {
      this.confirmRemoveModalVisible = true;
    } else {
      this.ok();
    }
  }

  async ok() {
    let emailRegex = /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (this.templateTypeId == 1) {
      if (
        this.customTemplateDetails.contactEmailContent.length &&
        !this.customTemplateDetails.contactEmailContent.match(emailRegex)
      ) {
        this.contactEmailError = true;
        return;
      }
      if (
        this.customTemplateDetails.phoneNo.toString().length &&
        this.customTemplateDetails.phoneNo.match(/[^0-9]+$/)
      ) {
        this.phoneNoError = true;
        return;
      }
      if (
        this.customTemplateDetails.contactPhoneContent.length &&
        this.customTemplateDetails.contactPhoneContent.match(/[^0-9]+$/)
      ) {
        this.contactPhoneNoError = true;
        return;
      }
    } else if (this.templateTypeId == 2) {
      if (
        this.customTemplateDetails.remitEmail.length &&
        !this.customTemplateDetails.remitEmail.match(emailRegex)
      ) {
        this.remitEmailError = true;
        return;
      }
    } else if (this.templateTypeId == 3) {
      if (
        this.customTemplateDetails.phoneNo.toString().length &&
        this.customTemplateDetails.phoneNo.match(/[^0-9]+$/)
      ) {
        this.phoneNoError = true;
        return;
      }
      if (
        this.customTemplateDetails.showContactEmail &&
        !this.customTemplateDetails.allowOptionChanges &&
        this.customTemplateDetails.contactEmailContent.length &&
        !this.customTemplateDetails.contactEmailContent.match(emailRegex)
      ) {
        this.contactEmailError = true;
        return;
      }
      if (
        this.customTemplateDetails.showContactPhone &&
        !this.customTemplateDetails.allowOptionChanges &&
        this.customTemplateDetails.contactPhoneContent.length &&
        this.customTemplateDetails.contactPhoneContent.match(/[^0-9]+$/)
      ) {
        this.contactPhoneNoError = true;
        return;
      }
    }
    await this.setTemplateDefaultValue();
    this.exporting = true;
    if (!this.confirmRemoveModalVisible) {
      this.iconload = true;
    }
    this.submitted = true;
    // if (this.showSectemps && this.aztempidx == "0") {
    //   this.exporting = false;
    //   setTimeout(() => (this.iconload = "error"), 1000);
    //   return false;
    // }

    if (["Orders"].includes(this.$route.name || "")) {
      this.$emit("ok", {
        selected: this.selected,
        customtemplateId: this.customtemplateId,
        saveAsDefaultTemplate: this.saveAsDefaultTemplate,
        displayOnExport: this.displayOnExport,
        bigdeal: this.bigdeal,
        exportPO: this.exportPO,
        aztempidx: this.aztempidx,
        advancedFilters: this.advancedFilters,
        customMessages: this.customMessages,
        exportOrderDetail: this.exportOrderDetail,
        reportingOrderDetail: this.reportingOrderDetail,
        exportETS: this.exportETS
      });
    } else if (["ViewQuote"].includes(this.$route.name || "")) {
      this.$emit(
        "ok",
        this.selected,
        this.customtemplateId,
        this.saveAsDefaultTemplate,
        this.displayOnExport,
        this.bigdeal,
        this.exportPO,
        this.aztempidx,
        this.advancedFilters,
        this.customMessages,
        this.customTemplateDetails
      );
    } else {
      this.isActive = this.hardwareCSVExport ? "hardware" : "basic";
      // others
      this.$emit(
        "ok",
        this.selected,
        this.customtemplateId,
        this.saveAsDefaultTemplate,
        this.displayOnExport,
        this.bigdeal,
        this.exportPO,
        this.aztempidx,
        this.advancedFilters,
        this.customMessages,
        this.exportOrderDetail,
        this.reportingOrderDetail,
        this.exportDirectPaycheckDetail,
        this.CustomDistyName,
        this.CustomDistyAddr1,
        this.CustomDistyAddr2,
        this.CustomDistyCity,
        this.CustomDistyState,
        this.CustomDistyZip,
        this.varSourceName,
        this.exportInvoiceAgingReport,
        this.exportLedgerAgingReport,
        this.exportInvoiceAgingDate,
        this.exportView,
        this.hardwareCSVExport,
        this.isActive,
        this.isDistyTemplate,
        this.customTemplateDetails
      );
    }
  }

  removeHTML(name, val) {
    var htmlCount = 0;
    if (htmlCheck(val)) {
      this[name] = htmlParse(val);
      htmlCount++;
    }
    if (htmlCount != 0) {
      notifier.alert("HTML content found. This content has been removed!");
    }
  }

  validateZip(name) {
    if (this[name] != "") {
      if (!this[name].match("^[0-9]*$")) {
        this.errorZip = true;
      } else {
        this.errorZip = false;
      }
    }
  }

  async orderDetailsHtml() {
    try {
      const orderDetails = await orderRepo.findOneDetails(
        this.$route.params.id,
        this.onExport
      );

      const tempPosition1 = orderDetails.TemplateSetting.PartialPosition1;
      const tempPosition2 = orderDetails.TemplateSetting.PartialPosition2;
      if (tempPosition1.length > 0) {
        tempPosition1.forEach(item => {
          var orderDetails_position1 = require("../templates/orders/partials/" +
            item.partialname +
            ".handlebars");
          Handlebars.registerPartial(item.partialname, orderDetails_position1);
        });
      }
      if (tempPosition2.length > 0) {
        tempPosition2.forEach(item => {
          var orderDetails_position2 = require("../templates/orders/partials/" +
            item.partialname +
            ".handlebars");
          Handlebars.registerPartial(item.partialname, orderDetails_position2);
        });
      }
      if (orderDetails.TemplateSetting.TemplateName != "") {
        const mainTemplate = orderDetails.TemplateSetting.TemplateName.split(
          "/"
        );
        var template = require("../templates/orders/" +
          mainTemplate[mainTemplate.length - 1]);
        const html = template(orderDetails);
        return html;
      } else {
        return "";
      }
    } catch (err) {
      notifier.alert(err.message);
      return false;
    }
  }

  async print() {
    // this.loading = true;
    if (this.showPrint) {
      this.$emit("print");
    } else {
      const html = await this.orderDetailsHtml();
      if (!html) {
        this.loading = false;
        return;
      }
      printHtml(html);
      this.loading = false;
    }
  }
  async gettemplate() {
    this.showSectemps = false;
    const tempname = this.customertemplates
      ? this.customertemplates.filter(
          x => x.TEMPLATEID == this.customtemplateId
        )
      : [];
    this.isDistyTemplate =
      tempname.length && typeof tempname[0].ISDISTY != "undefined"
        ? tempname[0].ISDISTY
        : 0;
    if (
      (tempname.length &&
        tempname[0].HASTEMPLATENAME &&
        tempname[0].HASTEMPLATENAME == 1) ||
      (tempname.length && [2, 3].includes(tempname[0].TEMPLATETYPE))
    ) {
      this.pageloading = true;
      this.showSectemps = tempname[0].TEMPLATETYPE == 1 ? true : false;
      let TemplateSetting: any = {};
      TemplateSetting.TemplateType = tempname[0].TEMPLATETYPE;

      if (this.templateparams.templates) {
        let getTempDetail = this.templateparams.templates.find(
          (item: any) => item.templateid == tempname[0].TEMPLATEID
        );
        if (getTempDetail && getTempDetail.templateSetting) {
          TemplateSetting = getTempDetail.templateSetting;
          TemplateSetting.TemplateType = tempname[0].TEMPLATETYPE;
          this.builderTemplate =
            TemplateSetting.BuilderTemplate ||
            TemplateSetting.isBuilderTemplate ||
            false;
          if (!this.builderTemplate && TemplateSetting.Message) {
            this.customerMessages = TemplateSetting.Message;
          }
        } else {
          const response = await ApiHelper.callApi("post", {
            controller: "Helpers",
            FunctionName: "TemplateDefinition",
            templateId: tempname[0].TEMPLATEID
          });
          TemplateSetting = response.TemplateSetting || {};
          this.builderTemplate = response.BuilderTemplate;
        }
      } else {
        const response = await ApiHelper.callApi("post", {
          controller: "Helpers",
          FunctionName: "TemplateDefinition",
          templateId: tempname[0].TEMPLATEID
        });
        TemplateSetting = response.TemplateSetting || {};
        this.builderTemplate = response.BuilderTemplate;
      }
      this.TemplateSetting = TemplateSetting || {};
      this.templateTypeId = TemplateSetting.TemplateType;
      this.quotetemplates = TemplateSetting.QuoteTemplates || [];

      this.customTemplateDetails.templateItemReorder =
        TemplateSetting.templateItemReorder || [];
      this.customTemplateDetails.cityState = TemplateSetting.cityState || "";
      this.customTemplateDetails.companyName =
        TemplateSetting.companyName || "";
      this.customTemplateDetails.streetAddress =
        TemplateSetting.streetAddress || "";
      this.customTemplateDetails.phoneNo = TemplateSetting.phoneNo || "";
      this.customTemplateDetails.contactEmailContent =
        TemplateSetting.contactEmailContent || "";
      this.customTemplateDetails.contactPhoneContent =
        TemplateSetting.contactPhoneContent || "";
      this.customTemplateDetails.footerContent =
        TemplateSetting.footerContent || "";
      this.customTemplateDetails.quoteNotes = TemplateSetting.quoteNotes || "";
      this.customQuoteNotes = TemplateSetting.quoteNotes || "";
      this.customFooterMsg = TemplateSetting.footerContent || "";

      this.customTemplateDetails.editableEditParagraph =
        TemplateSetting.editableEditParagraph != undefined
          ? TemplateSetting.editableEditParagraph
          : true;
      this.customTemplateDetails.editableInformationHeader =
        TemplateSetting.editableInformationHeader != undefined
          ? TemplateSetting.editableInformationHeader
          : true;
      this.customTemplateDetails.editableRemitAddress =
        TemplateSetting.editableRemitAddress != undefined
          ? TemplateSetting.editableRemitAddress
          : true;
      this.customTemplateDetails.editableFooterDetail =
        TemplateSetting.editableFooterDetail != undefined
          ? TemplateSetting.editableFooterDetail
          : true;
      this.customTemplateDetails.editablePOInstructions =
        TemplateSetting.editablePOInstructions != undefined
          ? TemplateSetting.editablePOInstructions
          : true;
      this.customTemplateDetails.editableQuoteNotes =
        TemplateSetting.editableQuoteNotes != undefined
          ? TemplateSetting.editableQuoteNotes
          : true;
      this.customTemplateDetails.editableTableContent =
        TemplateSetting.editableTableContent != undefined
          ? TemplateSetting.editableTableContent
          : true;
      this.customTemplateDetails.editableDataFields =
        TemplateSetting.editableDataFields != undefined
          ? TemplateSetting.editableDataFields
          : true;

      this.customTemplateDetails.quoteShippingAddr =
        TemplateSetting.quoteShippingAddr != undefined
          ? TemplateSetting.quoteShippingAddr
          : true;
      this.customTemplateDetails.quoteBillingAddr =
        TemplateSetting.quoteBillingAddr != undefined
          ? TemplateSetting.quoteBillingAddr
          : true;
      // this.customTemplateDetails.bdNumber =
      //     TemplateSetting.bdNumber != undefined
      //     ? TemplateSetting.bdNumber : true;
      // this.customTemplateDetails.sourceQuoteId =
      //     TemplateSetting.sourceQuoteId != undefined
      //     ? TemplateSetting.sourceQuoteId : true;

      this.customTemplateDetails.hidePhoneNo =
        TemplateSetting.hidePhoneNo != undefined
          ? TemplateSetting.hidePhoneNo
          : true;
      this.customTemplateDetails.isHPQuote =
        TemplateSetting.isHPQuote != undefined
          ? TemplateSetting.isHPQuote
          : false;
      this.customTemplateDetails.isQuoteContact =
        TemplateSetting.isQuoteContact != undefined
          ? TemplateSetting.isQuoteContact
          : false;
      this.customTemplateDetails.isQuoteExpDate =
        TemplateSetting.isQuoteExpDate != undefined
          ? TemplateSetting.isQuoteExpDate
          : false;

      this.customTemplateDetails.paragraphRows =
        TemplateSetting.paragraphRows || [];
      this.customParagraphRows = [...this.customTemplateDetails.paragraphRows];
      this.customTemplateDetails.quoteExpDate =
        TemplateSetting.quoteExpDate != undefined
          ? TemplateSetting.quoteExpDate
          : false;
      this.customTemplateDetails.showAccRep =
        TemplateSetting.showAccRep != undefined
          ? TemplateSetting.showAccRep
          : true;
      this.customTemplateDetails.showContactEmail =
        TemplateSetting.showContactEmail != undefined
          ? TemplateSetting.showContactEmail
          : true;
      this.customTemplateDetails.showContactInfo =
        TemplateSetting.showContactInfo != undefined
          ? TemplateSetting.showContactInfo
          : true;
      this.customTemplateDetails.showContactPhone =
        TemplateSetting.showContactPhone != undefined
          ? TemplateSetting.showContactPhone
          : true;
      this.customTemplateDetails.showInformation =
        TemplateSetting.showInformation != undefined
          ? TemplateSetting.showInformation
          : true;
      this.customTemplateDetails.showParagarph =
        TemplateSetting.showParagarph != undefined
          ? TemplateSetting.showParagarph
          : true;
      this.customTemplateDetails.showQuoteDate =
        TemplateSetting.showQuoteDate != undefined
          ? TemplateSetting.showQuoteDate
          : true;
      this.customTemplateDetails.showQuoteInfo =
        TemplateSetting.showQuoteInfo != undefined
          ? TemplateSetting.showQuoteInfo
          : true;
      this.customTemplateDetails.showQuoteName =
        TemplateSetting.showQuoteName != undefined
          ? TemplateSetting.showQuoteName
          : true;
      this.customTemplateDetails.showQuoteNumber =
        TemplateSetting.showQuoteNumber != undefined
          ? TemplateSetting.showQuoteNumber
          : true;
      this.customTemplateDetails.showRemitAddress =
        TemplateSetting.showRemitAddress != undefined
          ? TemplateSetting.showRemitAddress
          : true;
      this.customTemplateDetails.tableContent =
        TemplateSetting.tableContent != undefined
          ? TemplateSetting.tableContent.filter(
              item => item[0].isEditable == true
            )
          : [];
      this.customTemplateDetails.remitEmail = TemplateSetting.remitEmail || "";
      this.customTemplateDetails.orderNotes =
        TemplateSetting.notesContent || "";
      this.customTemplateDetails.taxMsg = TemplateSetting.taxMsg || "";

      if (this.templateTypeId == 3) {
        this.customTemplateDetails.allowOptionChanges =
          TemplateSetting.allowOptionChanges != undefined
            ? TemplateSetting.allowOptionChanges
            : false;
        this.customTemplateDetails.orderDate =
          TemplateSetting.orderDate != undefined
            ? TemplateSetting.orderDate
            : true;
        this.customTemplateDetails.orderNO =
          TemplateSetting.orderNO != undefined ? TemplateSetting.orderNO : true;
        this.customTemplateDetails.hasFooterTerms =
          TemplateSetting.hasFooterTerms != undefined
            ? TemplateSetting.hasFooterTerms
            : true;
        this.customTemplateDetails.shipAddress =
          TemplateSetting.shipAddress != undefined
            ? TemplateSetting.shipAddress
            : true;
        this.customTemplateDetails.customerPO =
          TemplateSetting.customerPO != undefined
            ? TemplateSetting.customerPO
            : true;
        this.customTemplateDetails.notes =
          TemplateSetting.notes != undefined ? TemplateSetting.notes : true;
        this.customTemplateDetails.footerTerms =
          TemplateSetting.footerTerms || this.customTemplateDetails.footerTerms;
      }

      if (this.templateTypeId == 2) {
        this.customTemplateDetails.shipAddress =
          TemplateSetting.shipAddress != undefined
            ? TemplateSetting.shipAddress
            : false;
        this.customTemplateDetails.invoiceId =
          TemplateSetting.invoiceId != undefined
            ? TemplateSetting.invoiceId
            : false;
        this.customTemplateDetails.invDate =
          TemplateSetting.invDate != undefined
            ? TemplateSetting.invDate
            : false;
        this.customTemplateDetails.customerPO =
          TemplateSetting.customerPO != undefined
            ? TemplateSetting.customerPO
            : false;
        this.customTemplateDetails.serialNumber =
          TemplateSetting.serialNumber != undefined
            ? TemplateSetting.serialNumber
            : false;
        this.customTemplateDetails.assetTag =
          TemplateSetting.assetTag != undefined
            ? TemplateSetting.assetTag
            : false;
        this.customTemplateDetails.trackingInfo =
          TemplateSetting.trackingInfo != undefined
            ? TemplateSetting.trackingInfo
            : false;
        this.customTemplateDetails.dueDate =
          TemplateSetting.dueDate != undefined
            ? TemplateSetting.dueDate
            : false;
        this.customTemplateDetails.invoiceTerms =
          TemplateSetting.invoiceTerms != undefined
            ? TemplateSetting.invoiceTerms
            : false;
        this.customTemplateDetails.contractNumber =
          TemplateSetting.contractNumber != undefined
            ? TemplateSetting.contractNumber
            : false;
        this.customTemplateDetails.billAddress =
          TemplateSetting.billAddress != undefined
            ? TemplateSetting.billAddress
            : false;
        this.customTemplateDetails.companyURL =
          TemplateSetting.companyURL || "";
      }
      this.pageloading = false;
      this.aztempidx = "0";
      this.templateNameChange();
      this.showTemplateSection = true;
    } else {
      this.quotetemplates = [];
      this.useDefaultPOInstruction(); //use default po instruction of the customer
      this.showTemplateSection = false;
      this.customMessages.useDefaultMessage = true;
    }

    // show shipment notes for order export
    if (this.objectType == "order") {
      this.showTemplateSection = tempname.length ? true : false;
      if (tempname.length) {
        this.customMessages.useDefaultMessage = false;
      }
    }
  }

  async setTemplateDefaultValue() {
    if (!this.customTemplateDetails.editableInformationHeader) {
      this.customTemplateDetails.contactPhoneContent = "";
      this.customTemplateDetails.contactEmailContent = "";
      this.customTemplateDetails.showQuoteName = true;
      this.customTemplateDetails.showQuoteNumber = true;
      this.customTemplateDetails.showQuoteDate = true;
      this.customTemplateDetails.showAccRep = true;
      this.customTemplateDetails.showContactEmail = true;
      this.customTemplateDetails.showContactPhone = true;
      this.customTemplateDetails.quoteExpDate = true;
    }

    if (!this.customTemplateDetails.editableRemitAddress) {
      this.customTemplateDetails.companyName = "";
      this.customTemplateDetails.streetAddress = "";
      this.customTemplateDetails.cityState = "";
      this.customTemplateDetails.phoneNo = "";
    }

    if (!this.customTemplateDetails.editablePOInstructions) {
      this.customTemplateDetails.paragraphRows = [];
    }

    if (!this.customTemplateDetails.editableQuoteNotes) {
      this.customTemplateDetails.quoteNotes = "";
    }

    if (!this.customTemplateDetails.editableFooterDetail) {
      this.customTemplateDetails.footerContent = "";
    }

    if (!this.customTemplateDetails.editableTableContent) {
      this.customTemplateDetails.tableContent = [];
    }
  }

  templateNameChange() {
    const templateName = this.aztempidx;
    if ((this.quotetemplates || []).length) {
      if (this.builderTemplate) {
        // reset to default values
        const templateparamsBk = (this.templateparamsBk.templates || []).find(
          item => item.templateid == this.customtemplateId
        );
        const templateSettingBk = JSON.parse(
          JSON.stringify(
            templateparamsBk ? templateparamsBk.templateSetting : {}
          )
        );

        if (Object.keys(templateSettingBk).length) {
          const resetKeys = [
            "paragraphRows",
            "quoteNotes",
            "footerContent",
            "quoteShippingAddr",
            "quoteBillingAddr",
            "companyName",
            "streetAddress",
            "cityState",
            "phoneNo",
            "remitEmail",
            "showQuoteName",
            "showQuoteNumber",
            "showQuoteDate",
            "showAccRep",
            "showContactPhone",
            "showContactEmail",
            "contractNumber",
            "contactPhoneContent",
            "contactEmailContent",
            "templateItemReorder"
          ];
          for (const key of resetKeys) {
            if (key in this.customTemplateDetails && key in templateSettingBk) {
              this.customTemplateDetails[key] = templateSettingBk[key];
            }
          }
        }
      }

      const templateNameDetail: any = this.quotetemplates.find(
        (item: any) => item.Name == templateName
      );
      if (templateNameDetail) {
        // purchasing instruction
        if (typeof templateNameDetail["POInstructions"] != "undefined") {
          this.customMessages.PurchasingInstruction =
            templateNameDetail["POInstructions"];
        }
        // quote notes
        if (typeof templateNameDetail["Notes"] != "undefined") {
          this.customMessages.QuoteNotes = this.removeHTMLContent(
            templateNameDetail["Notes"]
          );
        }

        // is template builder, check if has some override settings
        if (this.builderTemplate) {
          this.customTemplateDetails.paragraphRows = [
            {
              EDITPARAGRAPHNAME: "Purchasing Instruction",
              EDITPARAGRAPHCONTENT: this.customMessages.PurchasingInstruction
            }
          ];
          this.customTemplateDetails.quoteNotes = this.customMessages.QuoteNotes;

          // footer details
          this.customTemplateDetails.footerContent =
            typeof templateNameDetail["EndMessage"] != "undefined"
              ? templateNameDetail["EndMessage"]
              : "";

          // check if has override for this template name detail
          const override: any = templateNameDetail.override || {};
          let overrideKeys = [
            "paragraphRows",
            "quoteNotes",
            "footerContent",
            "quoteShippingAddr",
            "quoteBillingAddr"
          ];
          // remit address
          overrideKeys = [
            ...overrideKeys,
            "companyName",
            "streetAddress",
            "cityState",
            "phoneNo",
            "remitEmail"
          ];
          // information header
          overrideKeys = [
            ...overrideKeys,
            "showQuoteName",
            "showQuoteNumber",
            "showQuoteDate",
            "showAccRep",
            "showContactPhone",
            "showContactEmail",
            "contractNumber",
            "contactPhoneContent",
            "contactEmailContent"
          ];
          // elements
          overrideKeys = [...overrideKeys, "templateItemReorder"];

          // data fields
          const dataFields = (
            this.customTemplateDetails.templateItemReorder || []
          ).find(item => item.type == "data_fields");

          for (const key of overrideKeys) {
            if (key in override) {
              if (key == "templateItemReorder") {
                // check override info for elements
                if (
                  (this.customTemplateDetails.templateItemReorder || []).length
                ) {
                  for (const item of override.templateItemReorder) {
                    if (
                      item.id &&
                      ["header", "paragraph", "table"].includes(item.type)
                    ) {
                      const inList = this.customTemplateDetails.templateItemReorder.find(
                        t => t.id == item.id
                      );
                      if (inList) {
                        switch (item.type) {
                          case "header":
                            inList.value = item.value;
                            break;
                          case "paragraph":
                            inList.header = item.header;
                            inList.value = item.value;
                            break;
                          case "table":
                            inList.title = item.title;
                            inList.value = item.value;
                            break;
                        }
                      }
                    }
                  }
                }
              } else {
                this.customTemplateDetails[key] = override[key];
                // correct data
                if (key == "quoteBillingAddr" && dataFields) {
                  dataFields.quoteBillingAddr = override[key];
                }
                if (key == "quoteShippingAddr" && dataFields) {
                  dataFields.quoteShippingAddr = override[key];
                }
              }
            }
          }
        } else {
          // not template builder
          this.customMessages.useDefaultMessage = false;
        }
      } else if (templateName == "0") {
        this.customTemplateDetails.paragraphRows = this.customParagraphRows;
        this.customTemplateDetails.footerContent = this.customFooterMsg;
        this.customTemplateDetails.quoteNotes = this.customQuoteNotes;
      } else {
        this.useDefaultPOInstruction(); //use default po instruction of the customer
        this.useDefaultQuoteNotes(); // use default account quote notes
        this.customMessages.useDefaultMessage = true;
      }

      // quote notes
      // if (
      //   templateNameDetail &&
      //   typeof templateNameDetail["Notes"] != "undefined"
      // ) {
      //   this.customMessages.QuoteNotes = this.removeHTMLContent(
      //     templateNameDetail["Notes"]
      //   );
      //   if (this.builderTemplate) {
      //     this.customTemplateDetails.quoteNotes = this.customMessages.QuoteNotes;
      //   }
      // } else {
      //   // use default account quote notes
      //   this.useDefaultQuoteNotes();
      // }

      if (
        this.aztempidx &&
        !this.pullFromSource &&
        typeof templateNameDetail["ContractNo"] != "undefined"
      ) {
        this.bigdeal = templateNameDetail["ContractNo"];
      }

      // Footer message
      // if (this.builderTemplate && templateNameDetail) {
      //   this.customTemplateDetails.footerContent = "";
      //   if (typeof templateNameDetail["EndMessage"] != "undefined") {
      //     this.customTemplateDetails.footerContent =
      //       templateNameDetail["EndMessage"];
      //   }
      //   if (typeof templateNameDetail["Notes"] == "undefined") {
      //     this.customTemplateDetails.quoteNotes = "";
      //   }
      // }
    }

    //Template Backup Details
    this.customPrevTemplate = JSON.parse(JSON.stringify(this.customMessages));
    this.tempPrevTemplate = JSON.parse(
      JSON.stringify(this.customTemplateDetails)
    );
  }

  useDefaultQuoteNotes() {
    if (typeof this.customerMessages["QuoteNotes"] != "undefined") {
      this.customMessages.QuoteNotes = this.customerMessages["QuoteNotes"];
    } else {
      this.customMessages.QuoteNotes = "";
    }
  }

  useDefaultPOInstruction() {
    if (typeof this.customerMessages["PurchasingInstruction"] != "undefined") {
      this.customMessages.PurchasingInstruction = this.customerMessages[
        "PurchasingInstruction"
      ];
    } else {
      this.customMessages.PurchasingInstruction = "";
    }
  }

  updateCheckbox() {
    if (
      this.exportOrderDetail ||
      this.exportDirectPaycheckDetail ||
      this.exportInvoiceAgingReport ||
      this.exportLedgerAgingReport
    ) {
      $.each(this.options.filter(o => o.checked), function(i, val) {
        val.checked = false;
      });
    }
  }

  reportUpdateCheckbox() {
    if (this.reportingOrderDetail) {
      $.each(this.options.filter(o => o.checked), function(i, val) {
        val.checked = false;
      });
    }
  }
  directUpdateCheckbox() {
    if (this.exportDirect) {
      $.each(this.options, function(i, val) {
        if (val.value == "csv") {
          val.checked = true;
        } else {
          val.checked = false;
        }
      });
      this.exportDirectPaycheckDetail = true;
    }
  }
  indirectUpdateCheckbox() {
    if (this.exportIndirect) {
      $.each(this.options, function(i, val) {
        if (val.value == "pdf") {
          val.checked = true;
        } else {
          val.checked = false;
        }
      });
      this.exportDirectPaycheckDetail = false;
    }
  }
  updateDistyState(selectedID, index) {
    this.CustomDistyState = [selectedID];
    this.addrState = selectedID;
  }
  clickOutside() {
    if (this.objectType != "quote") {
      this.$emit("close");
    }
  }

  get disableExportBtn() {
    let ret = false;
    if (
      (this.selected.length === 0 &&
        !this.exportOrderDetail &&
        !this.reportingOrderDetail &&
        !this.exportDirectPaycheckDetail &&
        !this.exportInvoiceAgingReport &&
        !this.hardwareCSVExport &&
        !this.exportLedgerAgingReport &&
        !this.exportETS) ||
      this.exporting ||
      (!this.exportDirect && !this.exportIndirect)
    ) {
      ret = true;
    }

    return ret;
  }

  exportViewChange() {
    if (["data-grouped", "distribution-view"].includes(this.exportView || "")) {
      // just using csv option if selected "Distribution View"
      for (const item of this.options) {
        if (item.value != "csv") {
          item.disable = true;
          item.checked = false;
        } else {
          item.disable = false;
          item.checked = true;
        }
      }
    } else {
      for (const item of this.options) {
        item.disable = false;
      }
    }

    // default select csv option
    // sub orders/ sku summation after that can be checked for pdf/html
    for (const item of this.options) {
      if (item.value == "csv") {
        item.disable = false;
        item.checked = true;
      } else {
        item.checked = false;
      }
    }
  }

  exportETSChange() {
    for (const item of this.options) {
      item.checked = false;
    }
  }

  async addNewDetail(templateDetail) {
    this.templateDetailModalVisible = false;
    if (this.customtemplateId) {
      this.quotetemplates.push(templateDetail);
      const response = await ApiHelper.callApi("post", {
        controller: "Templates",
        FunctionName: "Update",
        subsystem: "VAR360",
        action: "updateTemplateDetail",
        templateID: this.customtemplateId,
        templateDetails: this.quotetemplates
      });
      if (response.STATUS) {
        notifier.success(response.STATUSMESSAGE);
      } else {
        notifier.alert(response.STATUSMESSAGE);
      }
    }
  }

  overWriteContractNumber() {
    if (this.pullFromSource) {
      this.bigdeal = this.bigDealfromQuotes;
    } else {
      var templateName = this.aztempidx;
      if (
        typeof this.quotetemplates != "undefined" &&
        this.quotetemplates.length
      ) {
        var tmpTemplate = this.quotetemplates.filter(
          (item: any) => item.Name == templateName
        );
        if (
          tmpTemplate.length &&
          typeof tmpTemplate[0]["ContractNo"] != "undefined"
        ) {
          this.bigdeal = tmpTemplate[0]["ContractNo"];
        }
      }
    }
  }

  async updateTemplateDetails(data) {
    if (this.exportDataID) {
      var templateName = this.aztempidx;
      if (this.builderTemplate) {
        for (let key in this.TemplateSetting) {
          if (typeof this.customTemplateDetails[key] != "undefined") {
            if (templateName != "0" && key === "quoteNotes") {
              this.TemplateSetting[key] = this.customTemplateDetails[key];
              this.TemplateSetting.QuoteTemplates.map((item: any) => {
                if (item.Name == templateName) {
                  item["Notes"] = this.customTemplateDetails[key];
                }
              });
            } else {
              this.TemplateSetting[key] = this.customTemplateDetails[key];
            }
          }
        }
      } else {
        if (templateName != "0") {
          this.TemplateSetting.QuoteTemplates.map((item: any) => {
            if (item.Name == templateName) {
              item["Notes"] = this.customMessages.QuoteNotes;
              item[
                "POInstructions"
              ] = this.customMessages.PurchasingInstruction;
            }
          });
        } else {
          this.TemplateSetting["Message"] = this.customMessages;
        }
      }

      const response = await ApiHelper.callApi("post", {
        controller: "Templates",
        FunctionName: "Update",
        subsystem: "VAR360",
        action: "updateExportTemplateDetail",
        templateID: this.customtemplateId,
        templateSetting: this.TemplateSetting,
        isSaveExport: data.actionType === "update" ? false : true,
        exportDataID: this.exportDataID,
        objectType: this.objectType,
        selectedTemplateDetail: this.aztempidx
      });

      if (response.STATUS) {
        this.quotetemplates = this.TemplateSetting.QuoteTemplates;
        //Template Backup Details
        this.customPrevTemplate = JSON.parse(
          JSON.stringify(this.customMessages)
        );
        this.tempPrevTemplate = JSON.parse(
          JSON.stringify(this.customTemplateDetails)
        );
        if (response.quoteTemplateparams) {
          this.templateparams = response.quoteTemplateparams;
        }
        notifier.success(response.STATUSMESSAGE);
        await this.ok();
        this.confirmRemoveModalVisible = false;
      } else {
        notifier.alert(response.STATUSMESSAGE);
      }
    }
  }

  removeHTMLContent(txt) {
    if (htmlCheck(txt)) {
      txt = htmlParse(txt);
    }

    return txt;
  }

  elementEditable(elementType, elementId = "") {
    if (!this.builderTemplate) return true;

    // is template builder
    const selectedElements =
      this.customTemplateDetails.templateItemReorder || [];
    if (!selectedElements.length) return false;

    let inList = selectedElements.find(item =>
      elementId
        ? elementId == (item.id || "")
        : elementType == (item.type || "")
    );

    if (!inList) return false;

    return inList.editable || false;
  }

  get isQuote() {
    return this.templateTypeId == 1;
  }

  get isOrder() {
    return this.templateTypeId == 3;
  }

  get isInvoice() {
    return this.templateTypeId == 2;
  }

  // get shipmentNotesElement() {
  //   return (this.customTemplateDetails.templateItemReorder || []).find(
  //     item => item.type == "shipment_notes"
  //   );
  // }

  updateElement(element) {
    switch (element.type || "") {
      case "data_fields":
        if (this.isOrder) {
          element.showEUs = element.euName || element.euEmail || false;
        }
        break;
    }
  }
}
