






















































































































































































































































































































































































































































































































































































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Watch } from "vue-property-decorator";
import { ApiHelper } from "@/helpers/all";
import GlobalTagSelect from "../GlobalTagSelect.vue";
import { notifier } from "../../models/common";
import { OrderAPIRepo } from "../../repos/OrderAPIRepo";
import DropdownControl from "@/components/DropdownControl.vue";
import opportunityAutoSuggestInput from "@/components/opportunityAutoSuggestInput.vue";
import LaddaButton from "@/components/LaddaButton.vue";

declare const dataURL: string;
declare const $: any;
declare const htmlParse : Function;
declare const htmlCheck : Function;
const orderRepo = new OrderAPIRepo();

interface Props {
  data: any;
  title: string;
}

@Component({
  inheritAttrs: false,
  components: {
    DropdownControl,
    GlobalTagSelect,
    opportunityAutoSuggestInput,
    LaddaButton
  }
})
export default class ModalEditOrderDataField extends TSXComponent<void> {
  $refs!: {
    modal: HTMLDivElement;
  };

  @Prop({ required: true, default: [] })
  data!: any;

  @Prop({ required: true, default: '' })
  title!: string;

  dataFields = {
    priceTypeIds: [0],
    priceType: 0,
  };

  fullCustomData: object[] = [];
  selectedValue = "Please make a selection";
  selectedContractValue = "Please make a selection";
  contractFieldId = 0;
  globalTags: any = [];
  selectedGlobalTags: object[] = [];
  selectedGlobalTagsID: number[] = [];
  customDropdown: object[] = [];
  selectedCustomId = 0;
  orderContractNumber: any = [];
  selectedContractId = 0;
  selectedContracts: any = [];
  selectedOrderContracts = "";
  orderContractsID: number[] = [];
  financialSource: object[] = [];
  selectedFundingSourceId = 0;
  selectedFinSourceValue = "Please make a selection";
  loading = false;
  customDataIndex = -1;
  customSourceData = 0;
  fundingSourceField = -1;
  $parent: any;
  isEditPo = false;
  opportunityName: string = "";
  opportunityID: number = 0;
  opportunityPrevID: number = 0;
  savingOpp: boolean = false;

  @Watch("opportunityName")
  async opportunityChanged(val) {
    if(val == null) {
      this.opportunityName = "";
      this.opportunityID = 0;
    }
  }

  async created() {
    const priceType = this.data.ORDERDETAILS.PRICETYPE || 0;
    this.dataFields.priceTypeIds = [priceType];
    this.dataFields.priceType = priceType;

    this.opportunityID = typeof this.data.ORDERDETAILS.OPPORTUNITYID != "undefined" ? this.data.ORDERDETAILS.OPPORTUNITYID : 0;
    this.opportunityPrevID = typeof this.data.ORDERDETAILS.OPPORTUNITYID != "undefined" ? this.data.ORDERDETAILS.OPPORTUNITYID : 0;
    this.opportunityName = typeof this.data.ORDERDETAILS.OPPORTUNITYID != "undefined" && this.data.ORDERDETAILS.OPPORTUNITYID ? `MOP-${this.data.ORDERDETAILS.OPPORTUNITYID}` : '';
    await this.showCustomData();

    if (typeof this.data.ORDERDETAILS.OPPORTUNITYID != "undefined" && this.data.ORDERDETAILS.OPPORTUNITYID) {
      await this.getOppDetail();
    }
  }

  get dataFieldCountCheck() {
    let saveAsDefaultViewCount = Object.values(this.data.saveAsDefaultView).filter((item: any) => item === true).length;
    if (saveAsDefaultViewCount >= 10) {
      return true;
    }
    return false;
  }

  mounted() {
    $(this.$refs.modal).modal("show");

    $(this.$refs.modal).on("hide.bs.modal", () => {
      this.$emit("close");
    });

    this.$forceUpdate();
  }

  beforeDestroy() {
    $(this.$refs.modal).modal("hide");
  }

  async getOppDetail() {
    const exportObj = {
      controller: "Queries",
      subsystem: "Helpers",
      FunctionName: "opportunities",
      action: 'findById',
      ms_opp_id: this.data.ORDERDETAILS.OPPORTUNITYID
    };

    const response = await ApiHelper.callApi("post", exportObj);

    if (response.STATUS == 1) {
      this.opportunityName = response.OPPORTUNITYDETAIL.DESCRIPTION ? response.OPPORTUNITYDETAIL.DESCRIPTION :`MOP-${this.data.ORDERDETAILS.OPPORTUNITYID}`;
    }
  }

  filterSourceNumbers(sourceNo, sourceList) {
    let sourceOrderArray: any[] = [];
    let sourceListIntoArray = sourceList.split(',');
    sourceOrderArray.push(sourceNo);
    sourceListIntoArray.map((value: any)=>{
      if (!sourceOrderArray.includes(value)) {
        sourceOrderArray.push(value)
      }
    });
    return sourceOrderArray.join(',');
  }

  async showCustomData() {
    try {
      this.loading = true;
      const response = await ApiHelper.callApi(
        'post',
        {
          controller: "Helpers",
          FunctionName: "CustomData",
          DataType: "1",
          Id: this.$route.params.id,
          DropdownField : 25,
          contractDropdown : 26,
          orderCustomData : true,
          aId: this.data.ORDERDETAILS.AID,
          ignoreFieldIds: "26"
        }
      );

      if (response.STATUS == 1) {
        this.fullCustomData = [];
        for (var val of response.CUSTOMDEFINITION) {
          let tmpValue = response.CUSTOMDATA.filter(
            tmp => tmp.CUSTOMFIELDID == val.CUSTOMFIELDID
          );
          this.fullCustomData.push({
            CUSTOMFIELDID: val.CUSTOMFIELDID,
            CUSTOMFIELDNAME: val.CUSTOMFIELDNAME,
            CUSTOMVALUE: tmpValue.length ? tmpValue[0].CUSTOMVALUE : "",
            CUSTOMID: tmpValue.length ? tmpValue[0].CUSTOMID : 0,
            CUSTOMDTYPE : val.CUSTOMDTYPE || 1,
            OPTIONS: val.OPTIONS || []
          });
          if(val.CUSTOMFIELDID == 25)
            this.selectedValue = tmpValue.length ? tmpValue[0].CUSTOMVALUE : "Please make a selection";
          if(val.CUSTOMDTYPE == 3 && val.CUSTOMFIELDNAME == "Custom Data on Export")
            this.selectedContractValue = tmpValue.length ? tmpValue[0].CUSTOMVALUE : "Please make a selection";
            this.contractFieldId = val.CUSTOMFIELDID;
        }

        this.globalTags = response.globalTags.filter(item => item.FORORDERS);
        this.selectedGlobalTags = response.selectedGlobalTags.map(
          (val: any) => ({
            CUSTOMFIELDOPTIONID: parseInt(val.CUSTOMFIELDOPTIONID),
            CUSTOMFIELDOPTIONNAME: val.CUSTOMFIELDOPTIONNAME,
            FORORDERS: val.FORORDERS
          })
        ).filter(item => item.FORORDERS);
        this.selectedGlobalTagsID = this.selectedGlobalTags.map(
          (val: any) => val.CUSTOMFIELDOPTIONID
        );
        this.customDropdown = response.customnDropdownField;
        this.selectedCustomId = response.selectedDropdownField;
        this.orderContractNumber = response.customContractNumber;
        this.selectedContractId = response.selectedContractNo;
        this.selectedContracts = response.selectedContractData;
        if(this.selectedContracts.length) {
          this.selectedOrderContracts = this.selectedContracts.length ? this.selectedContracts[0].CUSTOMFIELDOPTIONNAME : '';
          this.orderContractsID = this.selectedContracts.map(
            (val: any) => val.CUSTOMFIELDOPTIONID
          );
        }

        this.financialSource = response.financialSource;
        this.selectedFundingSourceId = response.FUNDINGSOURCEID;
        this.selectedFinSourceValue = response.FUNDINGSOURCENAME;
      }
    } catch (err) {
      // console.log(err.message);
    } finally {
      this.loading = false;
    }
  }

  showCustomEditBox(index) {
    this.customDataIndex = index;

    setTimeout(() => {
      $(".custom-data-txt")
        .focus()
        .select();
    }, 100);
  }

  hideCustomEditBox() {
    this.customDataIndex = -1;
  }

  showSourceCustomEditBox(val) {
    this.customSourceData = val;
    setTimeout(() => {
      $(".custom-data-source")
        .focus()
        .select();
    }, 100);
  }

  hideSourceCustomEditBox() {
    this.customSourceData = -1;
  }

  showFundingEditBox(val){
    this.fundingSourceField = val;
  }
  
  hideFundingEditBox() {
    this.fundingSourceField = -1;
  }

  async updateCustomData(item) {
    //remove HTML
    if((item.CUSTOMDTYPE || 1) != 3) {
      item.CUSTOMVALUE = this.removeHTML(item.CUSTOMVALUE);
    }

    try {
      const response = await ApiHelper.callApi(
        'post',
        {
          controller: "Helpers",
          FunctionName: "CustomDataUpdate",
          customID: item.CUSTOMID,
          customFieldID: item.CUSTOMFIELDID,
          customValue: item.CUSTOMVALUE,
          Id: this.$route.params.id,
          fieldTypeId: 1
        }
      );

      if (response.STATUS == 1) {
        this.customDataIndex = -1;
        this.$parent.fullCustomData.map((customDataItem: any) => {
          if (customDataItem.CUSTOMFIELDID === item.CUSTOMFIELDID) {
            customDataItem.CUSTOMVALUE = item.CUSTOMVALUE;
          }
        });
        if (item.CUSTOMVALUE == "") {
          //delete this item
          // eslint-disable-next-line require-atomic-updates
          item.CUSTOMID = 0;
        } else if (typeof response.NEWCUSTOMID != "undefined") {
          // eslint-disable-next-line require-atomic-updates
          item.CUSTOMID = response.NEWCUSTOMID;
        }
      }
    } catch (err) {
      // console.log(err.message);
    }
  }

  async updateCustomSoureData(source) {
    //remove HTML
    source = this.removeHTML(source);
    try {
      const response = await ApiHelper.callApi(
        'post',
        {
          controller: "Helpers",
          FunctionName: "CustomDataUpdate",
          customSourceName: source,
          Id: this.$route.params.id
        }
      );

      if (response.STATUS == 1) {
        this.customSourceData = -1;
      }
    } catch (err) {
      // console.log(err.message);
    }
  }

  removeHTML(txt) {
    if (htmlCheck(txt)) {
      txt = htmlParse(txt);
    }

    return txt;
  }

  toggleSelectedContractNumber(selectedID: number, contractName) {
    const index = this.orderContractsID.findIndex(id => id === selectedID);
    let bigDealId = typeof this.data.ORDERDETAILS.NASPOCONTRACT != "undefined" ? this.data.ORDERDETAILS.NASPOCONTRACT : "";
    const contractGTags = this.globalTags.filter(tmp => tmp.CUSTOMFIELDOPTIONNAME == contractName);
    if (index === -1) {
      this.orderContractsID = [selectedID];
      this.selectedOrderContracts = contractName
      this.$parent.selectedOrderContracts = contractName;
      let tmpContractObj = this.orderContractNumber.find(
        tmp => tmp.CUSTOMFIELDOPTIONID == selectedID
      );
      if (typeof tmpContractObj != 'undefined' && typeof tmpContractObj.CUSTOMFIELDOPTIONDESC != "undefined") {
        let descPrefix = tmpContractObj.CUSTOMFIELDOPTIONDESC.length ? ':' : '';
        bigDealId = tmpContractObj.CUSTOMFIELDOPTIONNAME + descPrefix + tmpContractObj.CUSTOMFIELDOPTIONDESC;
      }
      //Add Related Global Tags
      const selectedGTags = this.selectedGlobalTags.findIndex((tmp: any) => tmp.CUSTOMFIELDOPTIONNAME.toUpperCase() == contractName.toUpperCase());
      if(selectedGTags == -1 && contractGTags.length) {
        this.selectedGlobalTagsID.push(contractGTags[0].CUSTOMFIELDOPTIONID);
        this.selectedGlobalTags.push({
          CUSTOMFIELDOPTIONID: contractGTags[0].CUSTOMFIELDOPTIONID,
          CUSTOMFIELDOPTIONNAME: contractGTags[0].CUSTOMFIELDOPTIONNAME
        });
        this.$parent.selectedGlobalTags.push({
          CUSTOMFIELDOPTIONID: contractGTags[0].CUSTOMFIELDOPTIONID,
          CUSTOMFIELDOPTIONNAME: contractGTags[0].CUSTOMFIELDOPTIONNAME
        });
      }
      this.saveContractNumber(bigDealId);
      this.saveGlobalTag();
      return;
    }
    this.orderContractsID.splice(index, 1);
    this.selectedOrderContracts = "";
    this.$parent.selectedOrderContracts = "";
    this.saveContractNumber(bigDealId);
    //Remove Related Global Tags
    if(contractGTags.length) {
      const tagIndex = this.selectedGlobalTagsID.findIndex(id => id === contractGTags[0].CUSTOMFIELDOPTIONID);
      this.selectedGlobalTagsID.splice(tagIndex, 1);
      this.selectedGlobalTags.splice(tagIndex, 1);
      this.$parent.selectedGlobalTags.splice(tagIndex, 1);
      this.saveGlobalTag();
    }
  }

  toggleSelectedGlobalTags(selectedID: number, tagName) {
    const index = this.selectedGlobalTagsID.findIndex(id => id === selectedID);
    if (index === -1) {
      this.selectedGlobalTagsID.push(selectedID);
      this.selectedGlobalTags.push({
        CUSTOMFIELDOPTIONID: selectedID,
        CUSTOMFIELDOPTIONNAME: tagName
      });
      this.$parent.selectedGlobalTags.push({
        CUSTOMFIELDOPTIONID: selectedID,
        CUSTOMFIELDOPTIONNAME: tagName
      });
      this.saveGlobalTag();
      return;
    }

    this.selectedGlobalTagsID.splice(index, 1);
    this.$delete(this.selectedGlobalTags, index);
    this.$delete(this.$parent.selectedGlobalTags, index);
    this.saveGlobalTag();
  }

  async saveGlobalTag() {
    try {
      const response = await ApiHelper.callApi(
        'post',
        {
          controller: "Helpers",
          FunctionName: "CustomDataUpdate",
          optionIDs: this.selectedGlobalTagsID.join(","),
          Id: this.$route.params.id,
          action: "save_globalTags",
          fieldTypeId: 1
        }
      );
    } catch (err) {
      // console.log(err.message);
    }
  }

  selectAllGlobalTags() {
    this.resetGlobalTags();
    this.selectedGlobalTagsID = this.globalTags.map(
      (val: any) => val.CUSTOMFIELDOPTIONID
    );
    this.selectedGlobalTags = this.globalTags.map((val: any) => ({
      CUSTOMFIELDOPTIONID: val.CUSTOMFIELDOPTIONID,
      CUSTOMFIELDOPTIONNAME: val.CUSTOMFIELDOPTIONNAME
    }));
    this.$parent.selectedGlobalTags = this.globalTags.map((val: any) => ({
      CUSTOMFIELDOPTIONID: val.CUSTOMFIELDOPTIONID,
      CUSTOMFIELDOPTIONNAME: val.CUSTOMFIELDOPTIONNAME
    }));
    this.saveGlobalTag();
  }

  resetGlobalTags() {
    this.selectedGlobalTags = [];
    this.$parent.selectedGlobalTags = [];
    this.selectedGlobalTagsID = [];
    this.saveGlobalTag();
  }

  updateGlobalTagList(type, index) {
    if (this.selectedGlobalTagsID && type == "delete") {
      this.$delete(this.selectedGlobalTagsID, index);
      this.$delete(this.selectedGlobalTags, index);
      this.$delete(this.$parent.selectedGlobalTags, index);
    }
    this.saveGlobalTag();
  }

  async saveContractNumber(bigDealId = "") {
    try {
      const response = await ApiHelper.callApi(
        'post',
        {
          controller: "Helpers",
          FunctionName: "CustomDataUpdate",
          optionIDs: this.selectedOrderContracts,
          Id: this.$route.params.id,
          fieldId: this.orderContractNumber[0].CUSTOMFIELDID,
          action: "save_ContractNumber",
          fieldTypeId: 1
        }
      );
      if(response.STATUS === 1 && bigDealId != "") {
        this.$parent.bigDealId = bigDealId;
      }
    } catch (err) {
      // console.log(err.message);
    }
  }

  async updatefundingSource(data) {
    var $this = this;
    try {
      const response = await ApiHelper.callApi(
        'post',
        {
          controller: "Helpers",
          FunctionName: "CustomDataUpdate",
          customID: data,
          action: 'saveFundingSource',
          Id: this.$route.params.id
        }
      );
      if (response.STATUS == 1 && response.STATUSMESSAGE) {
        notifier.success(response.STATUSMESSAGE);
        if(data != 0){
          const selectedFinObj: any = this.financialSource.find((element: any) => element.VARSOURCE_ID == data);
          this.selectedFinSourceValue = selectedFinObj.VARSOURCE_NAME;
          this.$parent.selectedFinSourceValue = selectedFinObj.VARSOURCE_NAME;
          $.each(this.$parent.orderLedgerData, function(i, val) {
            if(val.PL_TYPE == 4 ) {
              val.FINSOURCEID = response.FINSOURCEID;
              val.D_FINANCIALSOURCE_NAME = selectedFinObj.VARSOURCE_NAME;
              $this.data.FUNDINGSOURCEID = response.FINSOURCEID;
            }
          });
        }
        else {
          this.selectedFinSourceValue = "";
          this.$parent.selectedFinSourceValue = "";
        }
        this.fundingSourceField = -1;
      } else if(response.STATUSMESSAGE) {
        notifier.alert(response.STATUSMESSAGE);
      }
    } catch (err) {
      // console.log(err.message);
    }
  }

  hidePoBox() {
    this.isEditPo = false;
  }

  async savePo(e) {
    if (this.data.ORDERDETAILS.POID == this.$parent.PoOrder) {
      return;
    }

    if (this.data.ORDERDETAILS.POID.trim() != "") {
      try {
        var updatePo = await orderRepo.updatePoOrder(
          this.data.ORDERDETAILS.POID,
          this.data.ORDERDETAILS.PURCHASEID,
          this.data.ORDERDETAILS.PODESCRIPTION,
          this.data.ORDERDETAIL.AID,
          false
        );

        //recall update api in case used a duplicated PO
        if (
          updatePo.STATUS == 0 &&
          typeof updatePo.POexisted != "undefined" &&
          updatePo.POexisted
        ) {
          if (
            confirm(
              "This PO is already in use are you sure you want to create?"
            )
          ) {
            updatePo = await orderRepo.updatePoOrder(
              this.data.ORDERDETAILS.POID,
              this.data.ORDERDETAILS.PURCHASEID,
              this.data.ORDERDETAILS.PODESCRIPTION,
              this.data.ORDERDETAIL.AID,
              true
            );
          } else {
            this.data.ORDERDETAILS.POID = this.$parent.PoOrder;
          }
        }

        if (updatePo.STATUS == 1) {
          this.$parent.PoOrder = this.data.ORDERDETAILS.POID;
          this.hidePoBox();
        }

      } catch (error) {
        // console.log("Save Po error", error);
      }
    } else {
      this.data.ORDERDETAILS.POID = this.$parent.PoOrder;
    }
  }

  async saveDefaultView() {
    const response = await ApiHelper.callApi(
      'post',
      {
        controller: "Users",
        FunctionName: "paramsUpdate",
        saveAsDefaultView: this.data.saveAsDefaultView,
        action: "saveAsDefaultView"
      }
    );
    if (response.STATUS === 1) {
      notifier.success("Successfully updated the default view for this User");
    } else {
      notifier.alert(response.STATUSMESSAGE);
    }
  }

  async toggleSelectedPriceType(selectedID: number) {
    const oldPriceType = this.dataFields.priceType;
    this.dataFields.priceTypeIds = [selectedID];
    this.dataFields.priceType = selectedID;
    try {
      const response = await ApiHelper.callApi(
        'post',
        {
          controller: "Helpers",
          FunctionName: "CustomDataUpdate",
          priceType: this.dataFields.priceType,
          Id: this.$route.params.id,
          action: "save_priceType",
          fieldTypeId: 1
        }
      );
      if (response.STATUS == 1) {
        ApiHelper.showSuccessMessage('Updated Price Type successfully!');
      } else {
        this.dataFields.priceType = oldPriceType; // rollback priceType if error
        ApiHelper.showErrorMessage(response.STATUSMESSAGE || 'Something was wrong');
      }
      this.data.ORDERDETAILS.PRICETYPE = this.dataFields.priceType;
      this.$emit("updateCustomPriceType", {
        priceType: this.dataFields.priceType
      });
    } catch (err) {
      // console.log(err.message);
    }
  }

  async updateOpportunity(data) {
    this.opportunityID = data.option.value || 0;
  }

  async saveOpportunity() {
    this.savingOpp = true;
    const response = await ApiHelper.callApi(
      'post',
      {
        controller          : "Orders",
        FunctionName        : "UpdateOpportunity",
        opportunityID       : this.opportunityID,
        opportunityPrevID   : this.opportunityPrevID,
        purchaseID          : this.data.ORDERDETAILS.PURCHASEID || ''
      }
    );
    if (response.STATUS === 1) {
      notifier.success(response.STATUSMESSAGE);
      this.$parent.details.ORDERDETAILS.OPPORTUNITYID = this.opportunityID;
      this.opportunityPrevID = this.opportunityID;
      if (!this.opportunityID) {
        this.opportunityName = "";
      }
    }
    this.savingOpp = false;
  }

  checkOpp() {
    return this.opportunityID == this.opportunityPrevID ? true : false;
  }

}
