





































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import { FinancialBoardsAPIRepo } from "@/repos/FinancesDashboardAPIRepo";

const financialBoardsRepo = new FinancialBoardsAPIRepo();

@Component({
  inheritAttrs: true,
  components: {}
})

export default class BoardAccountComponent extends TSXComponent<void> {

  @Prop({ required: false, default: '' })
  boardUUID!: string;

  @Prop({ required: false, default: false })
  isCompanyBoard!: boolean

  loading: boolean = false;
  accounts: any[] = [];
  totalAccount: number = 0;

  created() {
    this.fetchData();
  }

  async fetchData() {
    this.loading = true;
    const response: any = await financialBoardsRepo.findOneDetails(
      this.boardUUID,
      "YTD",
      "loadBoradView",
      "top5Boards",
      this.isCompanyBoard
    );

    if (response.STATUS === 1) {
      this.accounts = response.ACCOUNTS || [];
      this.totalAccount = response.TOTALACCOUNT || 0;
    }
    this.loading = false;
  }

  clickOutside() {
    this.$emit("close");
  }

}
