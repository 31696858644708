












































































import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import Datepicker from '../components/Datepicker.vue'
import {notifier} from '../models/common'

declare const $: any;
declare const getRouteData: Function;

@Component({
  inheritAttrs: false,
  components: {
    Datepicker,
  },
})
export default class PasswordReset extends TSXComponent<void> {
  $refs!: {
    modal: HTMLDivElement;
  };
  EmailId!: string;
  mounted() {
    $(this.$refs.modal).modal("show");

    $(this.$refs.modal).on("hide.bs.modal", () => {
      this.$emit("close");
    });
  }

  beforeDestroy() {
    $(this.$refs.modal).modal("hide");
  }

  data() {    
      return {
        EmailId:'',
      }
  }
  async validateBeforeSubmit(e) {
      this.$validator.validateAll().then((result) => {
          if (result) {
              e.preventDefault();
                  return this.submit();
          }
          else {
              e.preventDefault();
              e.stopPropagation();
          }
      });
  }
  async submit() {
    var self = this;
      var dataObj = {
        "controller"    : "Authentication",
        "FunctionName"  : "passwordReset",
        "MailId" : this.EmailId,
        "system" :  window.location.href.includes("var360customer") ? "Customer" : "Reseller"
      };
      let resultCategoryTrend = getRouteData(dataObj); 
      resultCategoryTrend.then(function (response, statusText, jqXHR) {
        if(response.data.status)
         notifier.success(response.data.msg)
        else
          notifier.alert(response.data.msg)
      }); 
      $('#emailSubmit').attr('data-dismiss','modal');
      $('#emailSubmit').click();
  }
}
