




















































































































































































































































































































































































































































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import {Component, Prop, Watch} from "vue-property-decorator";
import { validateFn } from "../helpers";
import { notifier } from "../models/common";
import LaddaButton from "../components/LaddaButton.vue";
import axios from "axios";
import ListHeaderActions from "@/components/ListHeaderActions.vue";
import PageTitle from "@/components/pageTitle.vue";
import AccountMultiselect from "@/components/AccountMultiselect.vue";
import Pagination from "@/components/Pagination.vue";
import CompPlanAccountComponent from "@/components/PayrollAccount.vue";
import TemplateAssociationsTooltip from "@/components/Template/TemplateAssociationsTooltip.vue";
import TemplateStatusComponent from "@/components/GlobalTagStatus.vue";
import { ApiHelper } from "@/helpers/all";
import {colsResizeable, getDateRangeFilter, getRangeFilter} from "@/helpers/ApiHelper";
import {InvoiceStatus} from "@/repos/InvoiceAPIRepo";
const Handlebars = require("handlebars/runtime.js");

declare const dataURL: string;
declare const $: any;

@Component({
  inheritAttrs: false,
  components: {
    TemplateAssociationsTooltip,
    CompPlanAccountComponent,
    Pagination,
    AccountMultiselect,
    PageTitle,
    ListHeaderActions,
    LaddaButton,
    TemplateStatusComponent
  }
})
export default class EditTemplate extends TSXComponent<void> {
  loading = false;
  pageNumber = 1;
  ret = {
    CURRENTPAGE: 1,
    TOTALPAGES: 1
  };
  customtemplateId = 0;
  selectedTemplateName = '';
  items = {
		quote_template: [],
		order_template: []
	};
  templates = [];
  compiledTemplate:any = '';
  quotetemplates:string[] = [];
  showSectemps = false;
  aztempidx = 0;
  objectType = "";
  previewTemplate = "";
  showTemplateSection = false;
  USERID = 0;
  selectedAccountIds: number[] = [];
  securityAccountsArr: any[] = [];
  ACCOUNTLIST: any[] = [];

  @Watch("customtemplateId")
  colsChange(val: any) {
    colsResizeable();
  }

  toggleSelectedAccIds(userId: number, accountName) {
    const index = this.selectedAccountIds.findIndex(id => id === userId);
    if (index === -1) {
      this.selectedAccountIds.push(userId);
      this.securityAccountsArr.push({
        USERID: this.USERID,
        ANAME: accountName,
        AID: userId
      });
      return;
    }

    this.selectedAccountIds.splice(index, 1);
    this.$delete(this.securityAccountsArr, index);
  }
  resetAccs() {
    this.selectedAccountIds = [];
    this.securityAccountsArr = [];
  }
  selectAllAccs() {
    this.resetAccs();
    var thisUserID = this.USERID;
    var $this = this;
    // this.selectedAccountIds = this.details.ACCOUNTLIST.map(u => u.AID);

    $.each(this.ACCOUNTLIST, function(i, val) {
      $this.selectedAccountIds.push(val.AID);
      $this.securityAccountsArr.push({
        USERID: thisUserID,
        ANAME: val.ANAME,
        AID: val.AID
      });
    });
  }
  templateData = {
    Status: 1,
    StyleSheet: "",
  };

  customMessages = {
    "Notes": "",
    "EndMessage": "",
    "PurchasingInstruction": "",
    "hideQuoteNotes": false,
    "ShipmentNotes": "",
    "ContractTerms": "",
    "TemplateNotes": "",
    "TaxMessage": "",
    "FooterNotes": ""
  };
  pageloading = false;
  showEditTemplate = false;
  customtemplateName = "";
  showAddTemplate = false;

  async created() {
    this.fetchData();	
  }

  async validateBeforeSubmit(e) {
    const valid = await this.$validator.validateAll();
    if (!valid) {
      // scroll to add new form
      if ($("#customerTemplate").length) {
        $("html, body").animate({
          scrollTop: $("#customerTemplate").offset().top
        }, 500);
      }

      return;
    }

    this.save();
  }

  listPageRedirection(){
    this.customtemplateId = 0;
  }

  async fetchData() {
		this.loading = true;
		try {
      const maxRows = sessionStorage.getItem('maxRows') ? sessionStorage.getItem('maxRows') : getMaxRows();
			const response = await axios.post(dataURL + "?ReturnType=JSON", {
				controller      : "Templates",
				FunctionName    : "List",
				templatetype		: 0,
				subsystem				: "VAR360",
        pageNumber      : this.pageNumber,
        templateTypeList: this.filters.type.join(','),
        status          : this.filters.status.join(','),
        templateName    : this.filters.templateName,
        sDate           : this.filters.sDate || undefined,
        eDate           : this.filters.eDate || undefined,
        maxRows         : maxRows,
        fromListPage    : true
				// getAll					: 1
			});

			if (response.data.ERROR) {
				throw new Error(response.data.ERROR);
			}
			if (response.data.STATUS !== 1) {
				throw new Error(response.data.STATUSMESSAGE);
			}
      this.ret.CURRENTPAGE = response.data.CURRENTPAGE || 1;
      this.ret.TOTALPAGES = response.data.TOTALPAGES || 1;
			this.ACCOUNTLIST = response.data.ACCOUNTLIST || [];
			var templateResult: any = response.data.TEMPLATES;
      this.templates = (response.data.TEMPLATES || []).map((item) => {
        switch (item.TEMPLATETYPE) {
          case 1:
            item.TEMPLATETYPENAME = 'Quote';
            break;
          case 2:
            item.TEMPLATETYPENAME = 'Invoice';
            break;
          case 3:
            item.TEMPLATETYPENAME = 'Order';
            break;
          default:
            item.TEMPLATETYPENAME = 'Other';
            break;
        }
        item.ASSOCIATIONCOUNT = item.ASSOCIATIONS ? item.ASSOCIATIONS.split(',').length : 0;
        return item;
      });
			var order_template: any = [];
			var quote_template: any = [];
			for(let i=0; i < templateResult.length; i++) {
				if(templateResult[i].TEMPLATETYPE == 1) {//quote
						templateResult[i].selected = false;                        
						if(templateResult[i].TEMPLATEID == response.data.QuoteTemplateId) {
								templateResult[i].selected = true;
						}
						quote_template.push(templateResult[i]);
				}
				else if(templateResult[i].TEMPLATETYPE == 3) {//order
						templateResult[i].selected = false;                        
						if(templateResult[i].TEMPLATEID == response.data.OrderTemplateId) {
								templateResult[i].selected = true;
						}
						order_template.push(templateResult[i]);
				}
			}
			this.items = {
				quote_template  : quote_template,
				order_template  : order_template
			}
		}catch (err) {
			// console.log(err.message);
		}finally {
			this.loading = false;
		}
    colsResizeable({ ms: 500 });
	}

  async save() {
    try {
      this.loading = true;
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller        : "Templates",
        FunctionName      : "EditTemplate",
        subsystem         : "VAR360",
        customMessages    : this.customMessages,
        templateId        : this.customtemplateId,
        templateName      : this.customtemplateName,
        objectType        : this.objectType,
        associations      : this.selectedAccountIds.join(','),
        styleSheet        : this.templateData.StyleSheet,
        status            : this.templateData.Status,
        addNewTemplate    : this.showAddTemplate
      });
      if (response.data.STATUS) {
        notifier.success(response.data.STATUSMESSAGE);
        this.loading = false;
        if (this.showAddTemplate) {
          var tempname: any = this.items.quote_template ? this.items.quote_template.filter(
            (x: any) => x.TEMPLATEID == this.customtemplateId
          ):[];
          if (tempname.length && !tempname[0].HASTEMPLATENAME) {
            tempname[0].HASTEMPLATENAME = 1;
          }
        }
        this.gettemplate(true);
        // back to list
        this.customtemplateId = 0;
        this.fetchData();
      }
    }catch (err) {
      // console.log(err);
    }finally {
      this.loading = false;
    }
  }

  async gettemplate(temp = false) {
    this.showSectemps = false;
    if (!temp) {
      this.aztempidx = 0;
    }
    this.showEditTemplate = false;
    this.customtemplateName = "";
    this.compiledTemplate = '';
    this.pageloading = true;

    const findTemplate = this.templates.find((item: any) => item.TEMPLATEID == this.customtemplateId);
    this.selectedAccountIds = [];
    this.templateData.Status = 0;
    this.templateData.StyleSheet = '';
    if (findTemplate) {
      this.templateData.Status = findTemplate.STATUS || 0;
      this.templateData.StyleSheet = findTemplate.STYLESHEET || '';
      if(findTemplate.ASSOCIATIONS) {
        this.selectedAccountIds = findTemplate.ASSOCIATIONS.split(',').map((item) => Number.parseInt(item, 10));
      }
    }

    var tempname: any = this.items.quote_template ? this.items.quote_template.filter(
      (x: any) => x.TEMPLATEID == this.customtemplateId
    ):[];
    this.showAddTemplate = tempname.length ? true : false;
    if (
      tempname.length &&
      tempname[0].HASTEMPLATENAME &&
      tempname[0].HASTEMPLATENAME == 1
    ) {
      this.objectType = "quote";
      this.showSectemps = true;
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Helpers",
        FunctionName: "TemplateDefinition",
        templateId: tempname[0].TEMPLATEID
      });
      if(response.data.TemplateSetting.TemplateName && response.data.TemplateSetting.TemplateType) {
        this.fetchAndRender(response.data.TemplateSetting.TemplateName.replace('../', ''),response.data.TemplateSetting.TemplateType);
      }

      this.quotetemplates = response.data.TemplateSetting.QuoteTemplates;
      this.templateNameChange();
      this.showTemplateSection = true;
    } else if (!tempname.length) {
    // show shipment notes and contract number for order
      this.objectType = "order";
      this.quotetemplates = [];
      //use default of the customer
      this.customMessages.PurchasingInstruction = "";
      this.customMessages.Notes = "";
      this.customMessages.EndMessage = "";
      this.customMessages.hideQuoteNotes = false;
      this.showTemplateSection = false;
      var orderTempname: any = this.items.order_template ? this.items.order_template.filter(
        (x: any) => x.TEMPLATEID == this.customtemplateId
      ):[];
      if (orderTempname.length) {
        const response = await axios.post(dataURL + "?ReturnType=JSON", {
          controller: "Helpers",
          FunctionName: "TemplateDefinition",
          templateId: orderTempname[0].TEMPLATEID
        });

        // if(response.data.TemplateSetting.TemplateName && response.data.TemplateSetting.TemplateType) {
        //   this.fetchAndRender(response.data.TemplateSetting.TemplateName.replace('../', ''),response.data.TemplateSetting.TemplateType);
        // }
        
        var OrderTemplates = typeof response.data.TemplateSetting.OrderTemplates != "undefined" ? response.data.TemplateSetting.OrderTemplates : {};
        //use default of the customer
        this.customMessages.ShipmentNotes = "";
        this.customMessages.ContractTerms = "";
        this.customMessages.TemplateNotes = "";
        this.customMessages.TaxMessage = "";
        if(typeof OrderTemplates["ShipmentNotes"] != "undefined" && OrderTemplates["ShipmentNotes"] != "") {
          this.customMessages.ShipmentNotes = this.removeHTML(OrderTemplates["ShipmentNotes"]);
        }
        if(typeof OrderTemplates["ContractTerms"] != "undefined" && OrderTemplates["ContractTerms"] != "") {
          this.customMessages.ContractTerms = this.removeHTML(OrderTemplates["ContractTerms"]);
        }
        if(typeof OrderTemplates["TemplateNotes"] != "undefined" && OrderTemplates["TemplateNotes"] != "") {
          this.customMessages.TemplateNotes = this.removeHTML(OrderTemplates["TemplateNotes"]);
        }
        if(typeof OrderTemplates["TaxMessage"] != "undefined" && OrderTemplates["TaxMessage"] != "") {
          this.customMessages.TaxMessage = this.removeHTML(OrderTemplates["TaxMessage"]);
        }
        if(typeof OrderTemplates["FooterNotes"] != "undefined" && OrderTemplates["FooterNotes"] != "") {
          this.customMessages.FooterNotes = this.removeHTML(OrderTemplates["FooterNotes"]);
        }

        this.updateTemplatePreview();
      }
      this.showTemplateSection = this.showEditTemplate = orderTempname.length ? true : false;
    } else if (tempname.length) {
      this.objectType = "quote";
      this.showTemplateSection = true;
      //use default of the customer
      this.customMessages.PurchasingInstruction = "";
      this.customMessages.Notes = "";
      this.customMessages.EndMessage = "";
      this.customMessages.hideQuoteNotes = false;
      this.customMessages.ShipmentNotes = "";
      this.customMessages.ContractTerms = "";
      this.customMessages.TemplateNotes = "";
      this.customMessages.TaxMessage = "";
      this.customMessages.FooterNotes = "";
    }
    this.pageloading = false;
  }

  async fetchAndRender(templatePath, type, dummyData: any = {}) {
    if(type == 1){
      type = "quotes"
    }
    else if(type == 3){
      type = "orders"
    }

    // const dummyData = {};
    // const templateResponse = require("../" + templatePath)
    // const templateBody = templateResponse(dummyData);
    // const partials = this.getPartials(templateBody);

    // for (const partial of partials) {
    //   const partialResponse = require(`../templates/${type}/partials/${partial}.handlebars`);
    //   Handlebars.registerPartial(partial, partialResponse);
    // }

    // const template = Handlebars.compile(templateBody);
    // this.compiledTemplate = template(dummyData);

    // register some partials in some old templates
    if(type == "quotes") {
      dummyData.TemplateSetting = {
        PartialPosition1: [
          {
            partialname: "quoteDetails_notes",
          },
          {
            partialname: "quoteDetails_purchasing",
          }
        ],
        PartialPosition2: [],
        showQuoteName: true,
        showQuoteNumber: true,
        showQuoteDate: true,
        showQuoteInfo: true,
        showContactInfo: true,
        showAccRep: true,
        showContactEmail: true,
        showContactPhone: true,
        quoteExpDate: true,
        bdData: true,
        showRemitAddress: true,
        showParagarph: true,
        showInformation: true,
        pricingdetails: true
      };    
      const tempPosition1 = dummyData.TemplateSetting.PartialPosition1 || [];
      const tempPosition2 = dummyData.TemplateSetting.PartialPosition2 || [];
      if (tempPosition1.length > 0) {
        tempPosition1.forEach(item => {
          var quoteDetails_position1 = require(`../templates/quotes/partials/${item.partialname}.handlebars`);
          Handlebars.registerPartial(item.partialname, quoteDetails_position1);
        });
      }
      if (tempPosition2.length > 0) {
        tempPosition2.forEach(item => {
          var quoteDetails_position2 = require(`../templates/quotes/partials/${item.partialname}.handlebars`);
          Handlebars.registerPartial(item.partialname, quoteDetails_position2);
        });
      }
    }

    const template = require("../" + templatePath);
    this.compiledTemplate = template(dummyData);
  }

  getPartials(templateBody) {
    const regex = /{{>\s*(.*?)\s*}}/g;
    const matches = [...templateBody.matchAll(regex)];
    const partials = matches.map(match => match[1]);

    return partials;
  }

  templateNameChange() {
    var templateName = this.aztempidx;
    const field = this.$validator.fields.find({name: 'templateName'});
    if (field){
      this.$validator.errors.remove('templateName');
    }
    if(typeof this.quotetemplates != "undefined" && this.quotetemplates.length) {
      var tmpTemplate = this.quotetemplates.filter((item: any) => item.Name == templateName);
      this.showEditTemplate = tmpTemplate.length ? true : false;
      this.showAddTemplate = tmpTemplate.length ? false : true;
      this.customtemplateName = tmpTemplate.length && typeof tmpTemplate[0]["Name"] != "undefined" ? tmpTemplate[0]["Name"] : "";
      //use default po instruction of the customer
      this.customMessages.PurchasingInstruction = "";
      //use default notes of the customer
      this.customMessages.Notes = "";
      //use default EndMessage of the customer
      this.customMessages.EndMessage = "";
      //use default hideQuoteNotes of the customer
      this.customMessages.hideQuoteNotes = false;
      //use default of the customer
      this.customMessages.ShipmentNotes = "";
      this.customMessages.ContractTerms = "";
      this.customMessages.TemplateNotes = "";
      this.customMessages.TaxMessage = "";
      this.customMessages.FooterNotes = "";
      if(tmpTemplate.length && typeof tmpTemplate[0]["POInstructions"] != "undefined" && tmpTemplate[0]["POInstructions"] != "") {
        this.customMessages.PurchasingInstruction = this.removeHTML(tmpTemplate[0]["POInstructions"]);
      }
      if(tmpTemplate.length && typeof tmpTemplate[0]["Notes"] != "undefined" && tmpTemplate[0]["Notes"] != "") {
        this.customMessages.Notes = this.removeHTML(tmpTemplate[0]["Notes"]);
      }
      if(tmpTemplate.length && typeof tmpTemplate[0]["EndMessage"] != "undefined" && tmpTemplate[0]["EndMessage"] != "") {
        this.customMessages.EndMessage = this.removeHTML(tmpTemplate[0]["EndMessage"]);
      }
      if(tmpTemplate.length && typeof tmpTemplate[0]["hideQuoteNotes"] != "undefined") {
        this.customMessages.hideQuoteNotes = tmpTemplate[0]["hideQuoteNotes"];
      }
      if(tmpTemplate.length && typeof tmpTemplate[0]["FooterNotes"] != "undefined" && tmpTemplate[0]["FooterNotes"] != "") {
        this.customMessages.FooterNotes = this.removeHTML(tmpTemplate[0]["FooterNotes"]);
      }
    }
  }

  removeHTML(txt) {
    if (txt === null || txt === "")
      return false;
    else
      txt = txt.toString();
    return txt.replace(/(<([^>]+)>)/ig, '');
  }

  updateTemplatePreview() {
    const selectedTemplate: any = [
      ...this.items.quote_template,
      ...this.items.order_template
    ].find((item: any) => item.TEMPLATEID == this.customtemplateId);
    const templateLocation = selectedTemplate.TEMPLATELOCATION || "";
    const templateType = selectedTemplate.TEMPLATETYPE || 0;
    if (templateLocation && templateType) {
      // fill some dummy data
      const dummyData: any = {};
      if (templateType == 1) {
        // quote
        // quote notes/ purchasing instructions/ end message
        dummyData.QUOTEDETAILS = {
          DISPLAYQUOTENOTES: (this.customMessages.hideQuoteNotes || false) == false ? 1 : 0,
          QUOTENOTES: this.customMessages.Notes,
          POInstructions: this.customMessages.PurchasingInstruction,
          ENDMESSAGE: this.customMessages.EndMessage,
          FOOTERNOTES: this.customMessages.FooterNotes
        };
      } else if (templateType == 3) {
        // order
        // order notes/ shippment notes/ Contract Terms/ tax message
        dummyData.ORDERDETAILS = {
          TEMPLATENOTES: this.customMessages.TemplateNotes,
          SHIPMENTNOTES: this.customMessages.ShipmentNotes,
          TERMS: this.customMessages.ContractTerms,
          TAXMESSAGE: this.customMessages.TaxMessage,
          FOOTERNOTES: this.customMessages.FooterNotes
        };
      }
      this.fetchAndRender(
        templateLocation.replace("../", ""),
        templateType,
        dummyData
      );
    }
  }

  async loadPage(page: number, type: "prev" | "next" | "") {
    if (type === "prev") {
      this.pageNumber = this.pageNumber - 1;
    } else if (type === "next") {
      this.pageNumber = this.pageNumber + 1;
    } else {
      this.pageNumber = page;
    }

    if (this.pageNumber <= this.ret.TOTALPAGES && this.pageNumber >= 1) {
      await this.fetchData();
    }
  }

  associationCountTooltipIndex = -1;
  hasChangedStatusFilters = false;
  hasChangedTypeFilters = false;
  filters: any = {
    templateName: "",
    sDate: undefined,
    eDate: undefined,
    status: [1,0],
    statusName: ['Active', 'Archived'],
    type: [1,2,3],
    typeName: ['Order', 'Quote', 'Invoice'],
    totalBaseMin: undefined,
    totalBaseMax: undefined,
    totalCompMin: undefined,
    totalCompMax: undefined,
    totalOteMin: undefined,
    totalOteMax: undefined
  }
  searchFilters: any = {
    templateName: "",
    sDate: undefined,
    eDate: undefined,
    status: [],
    type: [],
    totalBaseMin: undefined,
    totalBaseMax: undefined,
    totalCompMin: undefined,
    totalCompMax: undefined,
    totalOteMin: undefined,
    totalOteMax: undefined
  }
  async searchByTemplateName() {
    this.pageNumber = 1;
    if (this.searchFilters.templateName == "") {
      notifier.alert("Please Fill Template Name");
    } else {
      this.filters.templateName = this.searchFilters.templateName;
      await this.fetchData();
    }
  }

  async checkStatusList(invoiceStatus: number, name: string) {
    this.hasChangedStatusFilters = true;
    this.pageNumber = 1;
    const i = this.filters.status.findIndex(s => s === invoiceStatus);
    const statusIndex = this.filters.statusName.findIndex(statusValue => statusValue === name);
    if (i === -1) {
      this.filters.status.push(invoiceStatus);
    } else {
      this.filters.status.splice(i, 1);
    }
    if(statusIndex === -1){
      this.filters.statusName.push(name);
    } else {
      this.filters.statusName.splice(statusIndex, 1);
    }
  }
  async uncheckAllStatusList() {
    this.filters.status = [];
    this.filters.statusName = [];
    this.pageNumber = 1;
    await this.fetchData();
  }

  async checkAllStatusList(allowFetchData = true) {
    this.filters.status = [1, 0];
    this.filters.statusName = ['Active', 'Archived'];
    this.pageNumber = 1;
    if(allowFetchData) {
      await this.fetchData();
    }
  }
  async resetStatusList(allowFetchData = true) {
    this.pageNumber = 1;
    this.filters.status = [];
    this.filters.statusName = [];
    this.checkAllStatusList(allowFetchData);
  }

  // Type filter
  async checkTypeList(type: number, name: string) {
    this.hasChangedTypeFilters = true;
    this.pageNumber = 1;
    const i = this.filters.type.findIndex(s => s === type);
    const typeIndex = this.filters.typeName.findIndex(typeValue => typeValue === name);
    if (i === -1) {
      this.filters.type.push(type);
    } else {
      this.filters.type.splice(i, 1);
    }
    if(typeIndex === -1){
      this.filters.typeName.push(name);
    } else {
      this.filters.typeName.splice(typeIndex, 1);
    }
  }
  async uncheckAllTypeList() {
    this.filters.type = [];
    this.filters.typeName = [];
    this.pageNumber = 1;
    await this.fetchData();
  }

  async checkAllTypeList(allowFetchData = true) {
    this.filters.type = [1, 2, 3];
    this.filters.typeName = ['Order', 'Quote', 'Invoice'];
    this.pageNumber = 1;
    if(allowFetchData) {
      await this.fetchData();
    }
  }
  async resetTypeList(allowFetchData = true) {
    this.pageNumber = 1;
    this.filters.type = [1, 2, 3];
    this.filters.typeName = [];
    this.checkAllTypeList(allowFetchData);
  }
  // date filter
  async searchByDate() {
    this.pageNumber = 1;
    if (
        (this.searchFilters.sDate == undefined || this.searchFilters.sDate == "") &&
        (this.searchFilters.eDate == "" || this.searchFilters.eDate == undefined)
    ) {
      notifier.alert("Please Fill From or To Date");
    } else {
      if(this.searchFilters.sDate == "") {
        this.filters.sDate = this.searchFilters.sDate = undefined;
      } else if(this.searchFilters.eDate == "") {
        this.filters.eDate = this.searchFilters.eDate = undefined;
      }
      this.filters.sDate = this.searchFilters.sDate;
      this.filters.eDate = this.searchFilters.eDate
      await this.fetchData();
    }
  }
  async resetSearchByDate(allowFetchData = true) {
    this.pageNumber = 1;
    if (
        (this.searchFilters.sDate == undefined || this.searchFilters.sDate == "") &&
        (this.searchFilters.eDate == "" || this.searchFilters.eDate == undefined)
    ) {
      return;
    } else {
      this.filters.sDate = this.searchFilters.sDate = undefined;
      this.filters.eDate = this.searchFilters.eDate = undefined;
      this.filters.filterDueDate = undefined;
      if(allowFetchData) {
        await this.fetchData();
      }
    }
  }
  getPreDefinedFilter(status, filter) {
    if(!this.loading){
      if (!filter.length) {
        return "None";
      } else if(!((Object.keys(status).length / 2) == filter.length))
        return filter.join(', ');
    }
  }
  getFilterValue(header) {
    let filter = '';
    let searchKey: string | undefined = '';
    switch (header) {
      case 'TEMPLATENAME':
        searchKey = this.filters.templateName;
        break;
      case 'DUEDATE':
        filter = getDateRangeFilter(this.filters.sDate, this.filters.eDate);
        break;
      case 'STATUS':
        filter = this.filters.statusName.length == 2 ? '' : this.filters.statusName.join(", ") || 'None';
        break;
      case 'TYPE':
        filter = this.filters.typeName.length == 3 ? '' : this.filters.typeName.join(", ") || 'None';
        break;
      case 'DATE':
        filter = getDateRangeFilter(this.filters.sDate, this.filters.eDate);
        break;
      default:
        // nothing
        break;
    }
    return (filter ? `${filter}` : '') + (searchKey ? (filter ? ' - ' : '') + searchKey : '');
  }

  async resetSearchByTemplateName(allowFetchData = true) {
    this.pageNumber = 1;
    this.filters.templateName = this.searchFilters.templateName = "";
    if(allowFetchData) {
      await this.fetchData();
    }
  }
  async resetColumn(header) {
    switch (header) {
      case 'TEMPLATENAME':
        this.resetSearchByTemplateName();
        break;
      case 'TYPE':
        this.resetTypeList();
        break;
      case 'STATUS':
        this.resetStatusList();
        break;
      case 'DATE':
        this.resetSearchByDate();
        break;
      default:
        break;
    }
  }

  async changeStatus(templateId, status) {
    const response = await ApiHelper.callApi("post", {
      controller      : "Templates",
      FunctionName    : "Update",
      subsystem				: "VAR360",
      templateID      : templateId,
      status          : status,
      action          : "statusUpdate"
    });
    if (response.STATUS) {
      notifier.success(response.STATUSMESSAGE);
    } else {
      notifier.alert("Error Occured");
    }
    await this.fetchData();
  }
}
