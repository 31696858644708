




















































































































































































































































































































































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import ProductCatTooltip from "../../components/ProductCatTooltip.vue";
import MarginViewTooltip from "../../components/MarginViewTooltip.vue";
import directives from "../../helpers/directives";
import DistyPAOptionsTooltip from "../../components/DistyPAOptionsTooltip.vue";
import ConfirmYesNoTooltip from "../../components/ConfirmYesNoTooltip.vue";
import Datepicker from '../../components/Datepicker.vue'

@Component({
  inheritAttrs: false,
  components: {
    ProductCatTooltip,
    MarginViewTooltip,
    DistyPAOptionsTooltip,
    ConfirmYesNoTooltip,
    Datepicker
  },
  directives
})
export default class StandardItems extends TSXComponent<void> {
  $parent: any;
  
  @Prop({ required: false, default: () => [] })
  items!: Array<{ endOfLifeDate: string; [key: string]: any }>;

  @Prop({ required: false, default: 0 })
  type!: string;

  async childValidation() {
    const valid = await this.$validator.validateAll();
    return valid;
  }

  getTotal(item) {
    const price = item.price || 0;
    const quantity = Number.parseInt(item.quantity, 10) || 0;
    const total = price * quantity;
    return total;
  }

  addNewItemRow(itemIndex) {
    const focusedItem = this.items[itemIndex];

    if (focusedItem.sku.trim() || focusedItem.name.trim()) {
      if (itemIndex === this.items.length - 1) {
        this.$emit('addNew');
        this.$forceUpdate();
      } else if (itemIndex < this.items.length - 1) {
        let diff = this.items.length - (itemIndex + 1);
        for (let rowIndex = itemIndex; rowIndex <= this.items.length - 1; rowIndex++) {
          const item = this.items[rowIndex];
          if (item.isDeleted) {
            diff--;
          }
        }

        if (diff === 0) { // last row
          this.$emit('addNew');
          this.$forceUpdate();
        }
      }
    }
  }
}
