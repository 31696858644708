



























































































































































































































































































































































































































































































































































































































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import FilterCurrencyBox from "../components/FilterCurrencyBox.vue";

import { notifier } from "../models/common";
import { PaycheckAPIRepo, Sort, SortField } from "../repos/PaycheckAPIRepo";
import Pagination from "../components/Pagination.vue";
import paystatuscomponent, {
  SpiffStatusValue
} from "../components/paycheckStatus.vue";
import PaycheckGenerateModal from "../components/PaycheckGenerateModal.vue";
import InvoiceStatementsModal from "../components/InvoiceStatementsModal.vue";
import ConfirmCancelItemModal from "../components/ConfirmRemoveItemModal.vue";
import ListHeaderActions from "@/components/ListHeaderActions.vue";
import { colsResizeable, getDateRangeFilter, getRangeFilter } from "@/helpers/ApiHelper";
import PayrollAccountComponent from "@/components/PayrollAccount.vue";

const paycheckRepo = new PaycheckAPIRepo();

@Component({
  inheritAttrs: false,
  components: {
    paystatuscomponent,
    Pagination,
    FilterCurrencyBox,
    PaycheckGenerateModal,
    InvoiceStatementsModal,
    ConfirmCancelItemModal,
    ListHeaderActions,
    PayrollAccountComponent
  },
  methods: {
    getDateRangeFilter: (from, to) => {
      return getDateRangeFilter(from, to);
    },
    getRangeFilter: (min, max) => {
      return getRangeFilter(min, max);
    }
  }
})
export default class Paychecks extends TSXComponent<void> {
  $route: any;
  $router: any;
  $allowedFunctions: any;
  $userFunctions: any;

  loading = false;
  isSearch = 0;
  totalAll = 0;
  ret = {
    CURRENTPAGE: 1,
    TOTALPAGES: 1
  };
  totalpaycheckitems = 0;
  pageNumber = sessionStorage.pageDetails && JSON.parse(sessionStorage.pageDetails)["paychecks"] ? JSON.parse(sessionStorage.pageDetails)["paychecks"] : 1;
  detail = [];
  Employee = 1;
  paycheckItems: any = [];
  filters = {
    user: "",
    sTotalMin: "",
    sTotalMax: "",
    paycheckID: "",
    paycheckName: "",
    payDateRange: {
      sDate: "",
      eDate: ""
    },
    createdDate: {
      sDate: "",
      eDate: ""
    },
    paycheckStatus: [0, 1],
    paycheckStatusName: ['Pending', 'Paid'],
    batchID: "",
    compTotalMin: "",
    compTotalMax: ""
  };
  sort = {
    field: 0,
    direction: {
      [SortField.paycheckID]: 2,
      [SortField.User]: 1,
      [SortField.paycheckName]: 1,
      [SortField.dateRange]: 1,
      [SortField.createdDate]: 1,
      [SortField.commissionPercent]: 1,
      [SortField.payTotal]: 1,
      [SortField.status]: 1,
      [SortField.batchID]: 2
    }
  };
  viewType = "CurrentUsers";
  selectedID: (number | undefined)[] = [];
  selectedView = false;
  searchFilters = {
    user: "",
    sTotalMin: "",
    sTotalMax: "",
    paycheckID: "",
    paycheckName: "",
    payDateRange: {
      sDate: "",
      eDate: ""
    },
    createdDate: {
      sDate: "",
      eDate: ""
    },
    paycheckStatus: [0, 1],
    batchID: "",
    compTotalMin: "",
    compTotalMax: ""
  };
  invoiceStatementsModalVisible = false;
  invoicesDetails: String[] = [];
  statementDetails: String[] = [];
  confirmPaycheckCancel = false;
  cancelPaycheckList = [];
  customCancelPaycheckMessage = "";
  activeTab = "Cycle";
  payrollAccountTooltipIndex = -1;
  cyclesCheckAll: boolean = false;
  totalCycles: (number | undefined)[] = [];
  confirmCyclesDelete: boolean = false;
  deletingCycles: boolean = false;

  async created() {
    if (typeof this.$route.query.sTotalMin === "string") {
      this.filters.sTotalMin = this.searchFilters.sTotalMin = this.$route.query.sTotalMin;
    }
    if (typeof this.$route.query.sTotalMax === "string") {
      this.filters.sTotalMax = this.searchFilters.sTotalMax = this.$route.query.sTotalMax;
    }
    if (typeof this.$route.query.tab === "string" && ['Cycle', 'Employee'].includes(this.$route.query.tab )) {
      this.activeTab = this.$route.query.tab;
    }
    this.fetchData();
  }

  async searchByUser() {
    this.pageNumber = 1;
    if (this.searchFilters.user == "") {
      return;
    } else {
      this.filters.user = this.searchFilters.user;
      await this.fetchData();
    }
  }
  async resetSearchByUser() {
    this.pageNumber = 1;
    this.filters.user = this.searchFilters.user = "";
    await this.fetchData();
  }
  async searchById() {
    this.pageNumber = 1;
    if (this.searchFilters.paycheckID == "") {
      return;
    } else {
      this.filters.paycheckID = this.searchFilters.paycheckID;
      await this.fetchData();
    }
  }
  async resetSearchById() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.paycheckID = this.searchFilters.paycheckID = "";
    await this.fetchData();
  }

  async searchByPaycheckName() {
    this.pageNumber = 1;
    if (this.searchFilters.paycheckName == "") {
      return;
    } else {
      this.filters.paycheckName = this.searchFilters.paycheckName;
      await this.fetchData();
    }
  }
  async searchByBatchID() {
    this.pageNumber = 1;
    if (this.searchFilters.batchID == "") {
      return;
    } else {
      this.filters.batchID = this.searchFilters.batchID;
      await this.fetchData();
    }
  }
  async resetSearchByPaycheckName() {
    this.pageNumber = 1;
    this.filters.paycheckName = this.searchFilters.paycheckName = "";
    await this.fetchData();
  }
  async resetSearchByBatchID() {
    this.pageNumber = 1;
    this.filters.batchID = this.searchFilters.batchID = "";
    await this.fetchData();
  }
  async searchByPayDateRange() {
    this.pageNumber = 1;
    if (
      (this.searchFilters.payDateRange.sDate == undefined ||
        this.searchFilters.payDateRange.sDate == "") &&
      (this.searchFilters.payDateRange.eDate == "" ||
        this.searchFilters.payDateRange.eDate == undefined)
    ) {
      return;
    } else {
      this.filters.payDateRange.sDate = this.searchFilters.payDateRange.sDate;
      this.filters.payDateRange.eDate = this.searchFilters.payDateRange.eDate;
      await this.fetchData();
    }
  }
  async resetSearchByPayDateRange() {
    this.pageNumber = 1;
    this.filters.payDateRange.sDate = this.searchFilters.payDateRange.sDate = "";
    this.filters.payDateRange.eDate = this.searchFilters.payDateRange.eDate = "";
    await this.fetchData();
  }
  async searchByCreatedDate() {
    this.pageNumber = 1;
    if (
      (this.searchFilters.createdDate.sDate == undefined ||
        this.searchFilters.createdDate.sDate == "") &&
      (this.searchFilters.createdDate.eDate == "" ||
        this.searchFilters.createdDate.eDate == undefined)
    ) {
      notifier.alert("Please fill From or To Date");
    } else {
      this.filters.createdDate.sDate = this.searchFilters.createdDate.sDate;
      this.filters.createdDate.eDate = this.searchFilters.createdDate.eDate;
      await this.fetchData();
    }
  }
  async resetSearchByCreatedDate() {
    this.pageNumber = 1;
    if (
      (this.searchFilters.createdDate.sDate == undefined ||
        this.searchFilters.createdDate.sDate == "") &&
      (this.searchFilters.createdDate.eDate == "" ||
        this.searchFilters.createdDate.eDate == undefined)
    ) {
      return;
    } else {
      this.filters.createdDate.sDate = this.searchFilters.createdDate.sDate = "";
      this.filters.createdDate.eDate = this.searchFilters.createdDate.eDate = "";
      await this.fetchData();
    }
  }
  async resetSearchByTotal() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.sTotalMin = this.searchFilters.sTotalMin = "";
    this.filters.sTotalMax = this.searchFilters.sTotalMax = "";
    await this.fetchData();
  }
  async searchByTotal() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.sTotalMin = this.searchFilters.sTotalMin;
    this.filters.sTotalMax = this.searchFilters.sTotalMax;
    await this.fetchData();
  }
  async resetSearchByCompTotal() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.compTotalMin = this.searchFilters.compTotalMin = "";
    this.filters.compTotalMax = this.searchFilters.compTotalMax = "";
    await this.fetchData();
  }
  async searchByCompTotal() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.compTotalMin = this.searchFilters.compTotalMin;
    this.filters.compTotalMax = this.searchFilters.compTotalMax;
    await this.fetchData();
  }
  async checkStatusList(status: number, name: string) {
    this.pageNumber = 1;
    const i = this.filters.paycheckStatus.findIndex(s => s === status);
    if (i === -1) {
      this.filters.paycheckStatus.push(status);
    } else {
      this.filters.paycheckStatus.splice(i, 1);
    }
    if (i === -1) {
      this.filters.paycheckStatusName.push(name);
    } else {
      this.filters.paycheckStatusName.splice(i, 1);
    }
    await this.fetchData();
  }
  async checkAllStatusList() {
    this.pageNumber = 1;
    this.filters.paycheckStatus = [0, 1, 2];
    this.filters.paycheckStatusName = [];
    await this.fetchData();
  }
  async uncheckAllStatusList() {
    this.pageNumber = 1;
    this.filters.paycheckStatus = [];
    this.filters.paycheckStatusName = [];
    await this.fetchData();
  }
  async resetStatusList() {
    this.pageNumber = 1;
    this.filters.paycheckStatus = [0, 1];
    this.filters.paycheckStatusName = ['Pending', 'Paid'];
    await this.fetchData();
  }
  async sortBy(field: SortField) {
    this.sort.field = field;
    this.sort.direction[field] = this.sort.direction[field] === 1 ? 2 : 1;

    for (const dirField in this.sort.direction) {
      if (dirField.toString() === field.toString()) {
        continue;
      }

      this.sort.direction[dirField] = 1;
    }
    await this.fetchData();
  }
  async sortingByPaycheckID() {
    await this.sortBy(SortField.paycheckID);
  }
  async sortingByUser() {
    await this.sortBy(SortField.User);
  }
  async sortingByPaycheckName() {
    await this.sortBy(SortField.paycheckName);
  }
  async sortingByBatchID() {
    await this.sortBy(SortField.batchID);
  }
  async sortingByPayDateRange() {
    await this.sortBy(SortField.dateRange);
  }
  async sortingByCreatedDate() {
    await this.sortBy(SortField.createdDate);
  }
  async sortingByCommissionPercent() {
    await this.sortBy(SortField.commissionPercent);
  }
  async sortingByPayTotal() {
    await this.sortBy(SortField.payTotal);
  }
  async sortingByCompTotal() {
    await this.sortBy(SortField.compTotal);
  }
  async sortingByStatus() {
    await this.sortBy(SortField.status);
  }
  async loadPage(page: number, type: "prev" | "next" | "") {
    if (type === "prev") {
      this.pageNumber = this.pageNumber - 1;
    } else if (type === "next") {
      this.pageNumber = this.pageNumber + 1;
    } else {
      this.pageNumber = page;
    }
    if(sessionStorage.pageDetails != undefined && JSON.parse(sessionStorage.pageDetails)["paychecks"]){
      var oldData = JSON.parse(sessionStorage.pageDetails);
      oldData["paychecks"] = this.pageNumber;
      sessionStorage.setItem('pageDetails',JSON.stringify(oldData))
    }

    if (this.pageNumber <= this.ret.TOTALPAGES && this.pageNumber >= 1) {
      await this.fetchData();
    }
  }
  async fetchData() {
    //this.$router.replace({ path: this.$route.name, query: this.query});
    this.loading = true;
    try {
      const ret = await paycheckRepo.find(
        this.filters,
        this.sort,
        this.pageNumber,
        this.viewType,
        this.selectedView,
        this.activeTab
      );
      this.ret = ret;
      this.paycheckItems = ret.PAYCHECKOVERVIEW;
      this.isSearch = ret.ISSEARCH;
      this.totalAll = ret.TOTALALL;
      this.totalpaycheckitems = ret.TOTALPAYCHECKITEMS;

      if (
        !this.selectedView &&
        (
          typeof ret["userSelectedCurrentView"] != "undefined" ||
          typeof ret["userSelectedActiveTabView"] != "undefined"
        )
      ) {
        this.selectedView = true;
        if (typeof ret["userSelectedCurrentView"] != "undefined") {
          this.viewType = ret["userSelectedCurrentView"];
        }
        if (typeof ret["userSelectedActiveTabView"] != "undefined") {
          this.activeTab = ret["userSelectedActiveTabView"];

          // user must has company payroll to access Cycle view
          if(this.activeTab == "Cycle" && !this.$allowedFunctions.includes(this.$userFunctions.Company_Paychecks)) {
            this.activeTab = "Employee";
          }
        }
      }

      if (this.activeTab == 'Cycle') {
        this.totalCycles = [];
        for (var val of this.paycheckItems) {
          if (this.selectedID.includes(val.PGGROUPID)) {
            this.totalCycles.push(val.PGGROUPID);
          }
        }
        if(this.paycheckItems.length == this.totalCycles.length) {
          this.cyclesCheckAll = true;
        }
        else{
          this.cyclesCheckAll = false;
        }
      }
    } catch (err) {
      notifier.alert(err.message);
    } finally {
      this.loading = false;
    }

    colsResizeable({ms: 500});
  }
  async changeStatus(
    poId: string,
    status: SpiffStatusValue,
    totalValue: string,
    invoiceIds: string,
    groupId: number,
    paycheckName: string
  ) {
    const statusIndex =
      status === SpiffStatusValue.Paid
        ? "1"
        : status === SpiffStatusValue.Pending
        ? "0"
        : status === SpiffStatusValue.Cancel
        ? "2"
        : "0";

    try {
      if (status === 2) {
        this.confirmPaycheckCancel = true;
        this.cancelPaycheckList = this.paycheckItems.filter((item: any) => item.PGGROUPID === groupId && item.STATUS != 2);
        this.customCancelPaycheckMessage = `This action will revert all of ${this.cancelPaycheckList.length} payroll included in this series, <strong class='up'>${paycheckName}(ID# ${poId})</strong>. Would you like to proceed?`;
      } else {
        this.loading = true;
        const paysData = await paycheckRepo.updateStatus(
          poId,
          statusIndex,
          invoiceIds,
          groupId,
          totalValue
        );
        await this.fetchData();
        notifier.success(`Payroll ${poId}'s Status Updated Successfully`);
      }
    } catch (err) {
      notifier.alert(err.message);
    } finally {
      this.loading = false;
    }
  }

  async cancelPaycheck(poId) {
    try {
      this.loading = true;
      await paycheckRepo.updateStatus(
        poId.join(","),
        "2"
      );
      await this.fetchData();
      notifier.success(`Payroll Status Updated Successfully`);
      this.confirmPaycheckCancel = false;
    } catch (err) {
      notifier.alert(err.message);
    } finally {
      this.loading = false;
    }
  }
  // }

  paycheckGenerateModalVisible = false;
  generatedPCGroupId = "";

  async paycheckGenerated({ PCGroupId }: { PCGroupId: string }) {
    this.generatedPCGroupId = PCGroupId;
    this.paycheckGenerateModalVisible = false;
    await this.fetchData();
  }

  toggleCustomView(currStatus) {
    this.viewType = currStatus;
    this.selectedView = true;
    this.pageNumber = 1;
    this.fetchData();
  }

  showInvoiceStatments(invoices: String[], statements: String[]) {
    this.invoicesDetails = invoices;
    this.statementDetails = statements;
    this.invoiceStatementsModalVisible = true;
  }

  getFilterValue(header) {
    let filter = '';
    switch (header) {
      case 'STATUS':
        var statusFilters = [0, 1];
        if (!this.filters.paycheckStatusName.length) {
          filter = "None";
        } else if (statusFilters.length == this.filters.paycheckStatus.length && statusFilters.every(value => this.filters.paycheckStatus.includes(value))) {
          filter = "";
        } else if (this.filters.paycheckStatusName.length) {
          filter = this.filters.paycheckStatusName.join(", ");
        }
        break;
      default:
        // nothing
        break;
    }
    return filter ? filter : '';
  }

  toggleActiveTab(currTab) {
    if (this.activeTab == currTab) {
      return;
    }
    this.activeTab = currTab;
    this.selectedView = true;
    this.pageNumber = 1;
    this.fetchData();
  }

  cyclesCheckAllChange() {
    this.cyclesCheckAll = !this.cyclesCheckAll;
    if (!this.cyclesCheckAll) {
      this.selectInPage(false);
    } else {
      this.selectInPage(true);
    }
  }

  selectInPage(selectedAllPage = true) {
    if (selectedAllPage) {
      for (var val of this.paycheckItems) {
        if(this.selectedID.indexOf(val.PGGROUPID) == -1)  {
          this.selectedID.push(val.PGGROUPID);
        }
        if(this.totalCycles.indexOf(val.PGGROUPID) == -1)  {
          this.totalCycles.push(val.PGGROUPID);
        }
      }
    } else {
      for (var val of this.paycheckItems) {
        let selectedIDIndex = this.selectedID.indexOf(val.PGGROUPID);
        let totalEmpPlanIndex = this.totalCycles.indexOf(val.PGGROUPID);
        this.selectedID.splice(selectedIDIndex, 1);
        this.totalCycles.splice(totalEmpPlanIndex, 1);
      }
    }
  }

  checkExcludedIDs(pgGroupID) {
    if(pgGroupID) {
      if (this.selectedID.includes(pgGroupID)) {
        this.totalCycles.push(pgGroupID);
      }
      else {
        this.$delete(
          this.totalCycles,
          this.totalCycles.findIndex((id) => id == pgGroupID)
        );
      }
    }
    if(this.paycheckItems.length == this.totalCycles.length && this.paycheckItems.length != 0) {
      this.cyclesCheckAll = true;
    }
    else{
      this.cyclesCheckAll = false;
    }
  }

  async deleteCycles() {
    try {
      this.deletingCycles = true;
      const response: any = await paycheckRepo.deleteCycles(
        this.selectedID
      );

      if (response.STATUS === 1) {
        this.selectedID = [];
        this.totalCycles = [];
        notifier.success(response.STATUSMESSAGE);
        await this.fetchData();
      } else {
        notifier.alert(response.STATUSMESSAGE);
      }
      this.confirmCyclesDelete = false;
    } catch (err) {
      notifier.alert(err.message);
    } finally {
      this.deletingCycles = false;
    }
  }
}
