











































































































































































































































































































































































































































































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import axios from "axios";
import { validateFn } from "../helpers";
import { downloadFileUrl, notifier } from "../models/common";
import directives from "@/helpers/directives";
import Pagination from "../components/Pagination.vue";
import Header from "@/components/Header.vue";
import ConfirmRemoveItemModal from "../components/ConfirmRemoveItemModal.vue";
import { ApiHelper } from "@/helpers/all";
import { priceTypeStr } from "@/helpers/ApiHelper";
import AddAccountsModal from "@/components/AddAccountsModal.vue";

@Component({
  inheritAttrs: false,
  components: {
    Pagination,
    ConfirmRemoveItemModal,
    AddAccountsModal
  },
  directives,
  methods: {
    priceTypeStr
  }
})
export default class ReportBuilderDetails extends TSXComponent<void> {
  loading: boolean = true;
  items: any = [];
  pageNumber = 1;
  totalPages = 1;
  filters: any = {
    contentType: "",
    createDate: {
      active: false,
      timeFrame: "",
      timeFrameValue: ""
    },
    statusNotChangedIn: {
      active: false,
      timeFrame: "",
      timeFrameValue: ""
    },
    securityGroups: {
      active: false,
      groupIds: []
    },
    tags: {
      active: false,
      tagIds: []
    },
    accounts: {
      active: false,
      aIds: []
    }
  };
  details: any = {
    view: "",
    execution: "",
    scheduled: {
      timeFrame: "",
      timeFrameValue: "",
      emailList: ""
    }
  };
  reportDetails: any = {};
  selectedColumns: any = [];
  contentType = "";
  contentView = "";
  confirmReportDelete = false;
  // present
  present: any = {
    overview: [
      { header: "Status", field: "STATUSASTEXT", show: 0 },
      // { header: "SKU", field: "SKU", show: 0 },
      // { header: "Description", field: "POPRODDESC", show: 0 },
      // { header: "Serial Number", field: "SERIALNUMBERS", show: 0 },
      // { header: "Asset Tag", field: "ASSETTAGS", show: 0 },
      { header: "Order Name", field: "PODESCRIPTION", show: 0 },
      { header: "Customer", field: "ACCOUNTNAME", show: 0 },
      { header: "Customer PO", field: "POID", show: 0 },
      { header: "Var Cost", field: "ORDERVARCOSTTOTAL", show: 0 },
      { header: "Var360 Order ID", field: "PURCHASEID", show: 0 },
      { header: "Created Date", field: "PODATETIME", show: 0 },
      { header: "Profit", field: "PROFIT", show: 0 },
      { header: "Tax", field: "TOTALTAX", show: 0 },
      { header: "Subtotal", field: "TOTALFORMATTED", show: 0 },
      { header: "Grand Total", field: "GRANDTOTALFORMATTED", show: 0 },
      { header: "Source", field: "SOURCE", show: 0 },
      { header: "Source Quote ID", field: "INDIRECTQUOTEID", show: 0 },
      { header: "Var PO", field: "CUSTOMERPO", show: 0 },
      { header: "Source Order Number", field: "CUSTOMERORDERNUMBER", show: 0 },
      { header: "Payroll %", field: "DIRECTLINEPER", show: 0 },
      { header: "Invoiced %", field: "INVOICEDPERCENT", show: 0 },
      { header: "Contract Number", field: "CONTRACTNUMBER", show: 0 },
      { header: "System", field: "SSURL", show: 0 },
      { header: "Security Group", field: "SECURITYGROUPSNAME", show: 0 },
      { header: "Global Tags", field: "GTAGOPTIONNAMES", show: 0 },
      { header: "Business Line", field: "BUSINESSLINENAME", show: 0 },
      { header: "COGS Not billed", field: "TOTALCOGS", show: 0 },
      { header: "Sales not Invoiced", field: "TOTALSALES", show: 0 },
      { header: "Quote Number", field: "QUOTEID", show: 0 },
      { header: "Quote Name", field: "QDESCRIPTION", show: 0 },
      { header: "Date", field: "NEWDATEFORMATTED", show: 0 },
      { header: "Quote Creator", field: "QUOTECREATOR", show: 0 },
      { header: "Invoice ID", field: "INVOICEID", show: 0 },
      { header: "Invoice Profit", field: "INVOICEPROFIT", show: 0 },
      { header: "Invoice Date", field: "IDATEFORMATTED", show: 0 },
      { header: "Paid Date", field: "INVOICEPAIDDATE", show: 0 },
      { header: "Due Date", field: "DUEDATE", show: 0 },
      { header: "Invoice Email", field: "INVOICEEMAIL", show: 0 },
      { header: "Reported", field: "INVOICEREPORTED", show: 0 },
      { header: "Invoice Type", field: "INVOICETYPE", show: 0 },
      { header: "Billing Address", field: "BADDRESS", show: 0 },
      { header: "Billing State", field: "BSTATE", show: 0 },
      { header: "End User Name", field: "EUNAME", show: 0 },
      { header: "Address", field: "ORDERADDRESS", show: 0 },
      { header: "Order Status", field: "ORDERSTATUS", show: 0 },
      { header: "Shipping Address", field: "IADDRESS", show: 0 },
      { header: "Shipping State", field: "ISTATE", show: 0 }
    ],
    detailed: [
      { header: "Status", field: "STATUSASTEXT", show: 0 },
      { header: "SKU", field: "SKU", show: 1 },
      { header: "Description", field: "POPRODDESC", show: 1 },
      { header: "Order Name", field: "PODESCRIPTION", show: 0 },
      { header: "Customer", field: "ACCOUNTNAME", show: 0 },
      { header: "Customer PO", field: "POID", show: 0 },
      { header: "Var Cost", field: "ORDERVARCOSTTOTAL", show: 0 },
      { header: "Var360 Order ID", field: "PURCHASEID", show: 0 },
      { header: "Created Date", field: "PODATETIME", show: 1 },
      { header: "Profit", field: "PROFIT", show: 0 },
      { header: "Tax", field: "TOTALTAX", show: 0 },
      { header: "Subtotal", field: "TOTALFORMATTED", show: 0 },
      { header: "Grand Total", field: "GRANDTOTALFORMATTED", show: 0 },
      { header: "Source", field: "SOURCE", show: 0 },
      { header: "Source Quote ID", field: "INDIRECTQUOTEID", show: 0 },
      { header: "Var PO", field: "CUSTOMERPO", show: 0 },
      { header: "Source Order Number", field: "CUSTOMERORDERNUMBER", show: 0 },
      { header: "Payroll %", field: "DIRECTLINEPER", show: 0 },
      { header: "Invoiced %", field: "INVOICEDPERCENT", show: 0 },
      { header: "Contract Number", field: "CONTRACTNUMBER", show: 0 },
      { header: "System", field: "SSURL", show: 0 },
      { header: "Security Group", field: "SECURITYGROUPSNAME", show: 0 },
      { header: "Global Tags", field: "GTAGOPTIONNAMES", show: 0 },
      { header: "Business Line", field: "BUSINESSLINENAME", show: 0 },
      { header: "COGS Not billed", field: "TOTALCOGS", show: 0 },
      { header: "Sales not Invoiced", field: "TOTALSALES", show: 0 },
      { header: "Quote Number", field: "QUOTEID", show: 0 },
      { header: "Quote Name", field: "QDESCRIPTION", show: 0 },
      { header: "Date", field: "NEWDATEFORMATTED", show: 0 },
      { header: "Quote Creator", field: "QUOTECREATOR", show: 0 },
      { header: "Invoice ID", field: "INVOICEID", show: 0 },
      { header: "Invoice Profit", field: "INVOICEPROFIT", show: 0 },
      { header: "Invoice Date", field: "IDATEFORMATTED", show: 0 },
      { header: "Paid Date", field: "INVOICEPAIDDATE", show: 0 },
      { header: "Due Date", field: "DUEDATE", show: 0 },
      { header: "Invoice Email", field: "INVOICEEMAIL", show: 0 },
      { header: "Reported", field: "INVOICEREPORTED", show: 0 },
      { header: "Invoice Type", field: "INVOICETYPE", show: 0 },
      { header: "Billing Address", field: "BADDRESS", show: 0 },
      { header: "Billing State", field: "BSTATE", show: 0 },
      { header: "Serial Number", field: "SERIALNUMBER", show: 0 },
      { header: "Asset Tag", field: "ASSETTAG", show: 0 },
      { header: "End User Name", field: "EUNAME", show: 0 },
      { header: "Line Item Source", field: "LISOURCEORDERNUMBER", show: 0 },
      { header: "Address", field: "ORDERADDRESS", show: 0 },
      { header: "Product Line", field: "PRODUCTLINENAME", show: 0 },
      { header: "Cost Per", field: "COSTPERFORMATTED", show: 0 },
      { header: "Contract Price", field: "CONTRACTPRICEFORMATTED", show: 0 },
      { header: "List Price", field: "LISTPRICEFORMATTED", show: 0 },
      { header: "Customer Price", field: "PRICEPERFORMATTED", show: 0 },
      { header: "Price Per", field: "PRICEPERFORMATTED", show: 0 },
      { header: "Product Category", field: "CATEGORYNAME", show: 0 },
      { header: "Last Modified", field: "LASTMODIFIEDFORMATTED", show: 0 },
      { header: "Invoice Number", field: "INVOICEID", show: 0 },
      { header: "Warranty Name", field: "WARRANTYNAME", show: 0 },
      { header: "Warranty Start Date", field: "STARTDATEFORMATTED", show: 0 },
      { header: "Warranty End Date", field: "WARRENTYEXPFORMATTED", show: 0 },
      { header: "Warranty Last Checked", field: "LASTCHECKEDFORMATTED", show: 0 },
      { header: "Order Status", field: "ORDERSTATUS", show: 0 },
      { header: "Ship Date", field: "SHIPPEDDATE_FORMATTED", show: 0 },
      { header: "SKU Status", field: "SKUSTATUS", show: 0 },
      { header: "Ship and ETS Date", field: "LISHIPPEDDATE", show: 0 },
      { header: "Shipping Address", field: "IADDRESS", show: 0 },
      { header: "Shipping State", field: "ISTATE", show: 0 }
    ],
    technical: [
      { header: "Status", field: "STATUSASTEXT", show: 0 },
      { header: "SKU", field: "SKU", show: 1 },
      { header: "Description", field: "POPRODDESC", show: 1 },
      { header: "Order Name", field: "PODESCRIPTION", show: 0 },
      { header: "Customer", field: "ACCOUNTNAME", show: 0 },
      { header: "Customer PO", field: "POID", show: 0 },
      { header: "Var Cost", field: "ORDERVARCOSTTOTAL", show: 0 },
      { header: "Var360 Order ID", field: "PURCHASEID", show: 0 },
      { header: "Created Date", field: "PODATETIME", show: 1 },
      { header: "Profit", field: "PROFIT", show: 0 },
      { header: "Tax", field: "TOTALTAX", show: 0 },
      { header: "Subtotal", field: "TOTALFORMATTED", show: 0 },
      { header: "Grand Total", field: "GRANDTOTALFORMATTED", show: 0 },
      { header: "Source", field: "SOURCE", show: 0 },
      { header: "Source Quote ID", field: "INDIRECTQUOTEID", show: 0 },
      { header: "Var PO", field: "CUSTOMERPO", show: 0 },
      { header: "Source Order Number", field: "CUSTOMERORDERNUMBER", show: 0 },
      { header: "Payroll %", field: "DIRECTLINEPER", show: 0 },
      { header: "Invoiced %", field: "INVOICEDPERCENT", show: 0 },
      { header: "Contract Number", field: "CONTRACTNUMBER", show: 0 },
      { header: "System", field: "SSURL", show: 0 },
      { header: "Security Group", field: "SECURITYGROUPSNAME", show: 0 },
      { header: "Global Tags", field: "GTAGOPTIONNAMES", show: 0 },
      { header: "Business Line", field: "BUSINESSLINENAME", show: 0 },
      { header: "COG Not Billed", field: "TOTALCOGS", show: 0 },
      { header: "Sales Not Invoiced", field: "TOTALSALES", show: 0 },
      { header: "Quote Number", field: "QUOTEID", show: 0 },
      { header: "Quote Name", field: "QDESCRIPTION", show: 0 },
      { header: "Date", field: "NEWDATEFORMATTED", show: 0 },
      { header: "Quote Creator", field: "QUOTECREATOR", show: 0 },
      { header: "Invoice ID", field: "INVOICEID", show: 0 },
      { header: "Invoice Profit", field: "INVOICEPROFIT", show: 0 },
      { header: "Invoice Date", field: "IDATEFORMATTED", show: 0 },
      { header: "Paid Date", field: "INVOICEPAIDDATE", show: 0 },
      { header: "Due Date", field: "DUEDATE", show: 0 },
      { header: "Invoice Email", field: "INVOICEEMAIL", show: 0 },
      { header: "Reported", field: "INVOICEREPORTED", show: 0 },
      { header: "Invoice Type", field: "INVOICETYPE", show: 0 },
      { header: "Billing Address", field: "BADDRESS", show: 0 },
      { header: "Billing State", field: "BSTATE", show: 0 },
      { header: "Serial Number", field: "SERIALNUMBER", show: 1 },
      { header: "Asset Tag", field: "ASSETTAG", show: 1 },
      { header: "End User Name", field: "EUNAME", show: 1 },
      { header: "Line Item Source", field: "LISOURCEORDERNUMBER", show: 0 },
      { header: "Address", field: "ORDERADDRESS", show: 0 },
      { header: "Product Line", field: "PRODUCTLINENAME", show: 0 },
      { header: "Cost Per", field: "COSTPERFORMATTED", show: 0 },
      { header: "Contract Price", field: "CONTRACTPRICEFORMATTED", show: 0 },
      { header: "List Price", field: "LISTPRICEFORMATTED", show: 0 },
      { header: "Customer Price", field: "PRICEPERFORMATTED", show: 0 },
      { header: "Price Per", field: "PRICEPERFORMATTED", show: 0 },
      { header: "Product Category", field: "CATEGORYNAME", show: 0 },
      { header: "Last Modified", field: "LASTMODIFIEDFORMATTED", show: 0 },
      { header: "Invoice Number", field: "INVOICEID", show: 0 },
      { header: "Warranty Name", field: "WARRANTYNAME", show: 0 },
      { header: "Warranty Start Date", field: "STARTDATEFORMATTED", show: 0 },
      { header: "Warranty End Date", field: "WARRENTYEXPFORMATTED", show: 0 },
      { header: "Warranty Last Checked", field: "LASTCHECKEDFORMATTED", show: 0 },
      { header: "Order Status", field: "ORDERSTATUS", show: 0 },
      { header: "Ship Date", field: "SHIPPEDDATE_FORMATTED", show: 0 },
      { header: "SKU Status", field: "SKUSTATUS", show: 0 },
      { header: "Ship and ETS Date", field: "LISHIPPEDDATE", show: 0 },
      { header: "Shipping Address", field: "IADDRESS", show: 0 },
      { header: "Shipping State", field: "ISTATE", show: 0 }
    ]
  };
  totalAll = 0;
  showAccountModal: boolean = false;
  accountsList: any[] = [];
  dynamicAccounts: any[] = [];
  allowAddAccounts: boolean = false;
  groupList: any[] = [];
  dynamicGroups: any[] = [];

  async created() {
    await this.fetchData();

    // show more columns based on filters
    // show created date
    const createDate = this.filters.createDate;
    if (
      (createDate.active || false) &&
      (createDate.timeFrameValue || 0) > 0 &&
      (createDate.timeFrame || "") != ""
    ) {
      const inOverview = this.present.overview.find(
        item => item.field == "PODATETIMEFORMATTED"
      );
      if (inOverview) {
        inOverview.show = 1;
      }
      const inDetailed = this.present.detailed.find(
        item => item.field == "PODATETIMEFORMATTED"
      );
      if (inDetailed) {
        inDetailed.show = 1;
      }
    }
    // show last updated data
    const statusNotChangedIn = this.filters.statusNotChangedIn;
    if (
      (statusNotChangedIn.active || false) &&
      (statusNotChangedIn.timeFrameValue || 0) > 0 &&
      (statusNotChangedIn.timeFrame || "") != ""
    ) {
      const inOverview = this.present.overview.find(
        item => item.field == "LASTUPDATEDFORMATTED"
      );
      if (inOverview) {
        inOverview.show = 1;
      }
      const inDetailed = this.present.detailed.find(
        item => item.field == "LASTUPDATEDFORMATTED"
      );
      if (inDetailed) {
        inDetailed.show = 1;
      }
    }
  }

  async fetchData() {
    try {
      this.loading = true;
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        Controller: "Reports",
        FunctionName: "ReportBuilderContent",
        reportId: parseInt(this.$route.params.id) || 0,
        maxRows: sessionStorage.getItem("maxRows") || getMaxRows(),
        pageNumber: this.pageNumber,
        isUpdateReportLastRunDate: true
      });
      if (response.data.STATUS == 1) {
        this.reportDetails = response.data.reportDetails || {};
        if (response.data.filters && response.data.filters.contentType) {
          this.filters = response.data.filters;
        }
        if (response.data.details && response.data.details.view) {
          this.details = response.data.details;
        }
        this.items = response.data.items || [];
        this.accountsList = response.data.ACCOUNTLIST || [];
        this.groupList = response.data.GROUPLIST || [];
        this.allowAddAccounts = response.data.allowAddAccounts;
        this.contentType = response.data.filters.contentType;
        this.contentView = response.data.details.view;
        this.totalPages = response.data.totalPages || 1;
        this.totalAll = response.data.totalAll || 0;
        this.selectedColumns = (response.data.filters.outputColumns || []).map(
          item => item.columnName
        );

        if (this.contentType != "orders") {
          // quotes/invoices
          if (this.contentView == "technical") {
            for (let i = 0; i < this.present.technical.length; i++) {
              if (
                this.present.technical[i].header == "Created Date" ||
                this.present.technical[i].header == "End User Name" ||
                this.present.technical[i].header == "Asset Tag" ||
                this.present.technical[i].header == "Serial Number"
              ) {
                this.present.technical[i].show = 0;
              }
            }
          }
          if (this.contentView == "detailed") {
            for (let i = 0; i < this.present.detailed.length; i++) {
              if (this.present.detailed[i].header == "Created Date") {
                this.present.detailed[i].show = 0;
              }
            }
          }
        } else if (this.contentType == "orders") {
          if (["detailed", "technical"].includes(this.contentView)) {
            // auto show "line item source" if report order detailed/technical
            this.selectedColumns.push("Line Item Source");
            if (this.selectedColumns.includes("SKU Status")) {
              this.selectedColumns.push("Ship and ETS Date");
            }
          }
        }

        if (this.contentView == "detailed") {
          for (let i = 0; i < this.present.detailed.length; i++) {
            if (
              this.selectedColumns.includes(this.present.detailed[i].header)
            ) {
              this.present.detailed[i].show = 1;
            }
          }
        } else if (this.contentView == "overview") {
          for (let i = 0; i < this.present.overview.length; i++) {
            if (
              this.selectedColumns.includes(this.present.overview[i].header)
            ) {
              this.present.overview[i].show = 1;
            }
          }
        } else if (this.contentView == "technical") {
          for (let i = 0; i < this.present.technical.length; i++) {
            if (
              this.selectedColumns.includes(this.present.technical[i].header)
            ) {
              this.present.technical[i].show = 1;
            }
          }
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.loading = false;
    }
  }

  async loadPage(page: number, type: "prev" | "next" | "") {
    let currentPage = this.pageNumber;
    if (type === "prev") {
      currentPage = currentPage - 1;
    } else if (type === "next") {
      currentPage = currentPage + 1;
    } else {
      currentPage = page;
    }
    if (currentPage <= this.totalPages && currentPage >= 1) {
      this.pageNumber = currentPage;
      await this.fetchData();
    }
  }

  getInitials(name) {
    const words = name.split(" ");
    let initials = "";
    for (let i = 0; i < Math.min(2, words.length); i++) {
      initials += words[i].charAt(0);
    }
    return initials.toUpperCase();
  }

  get showingColumns() {
    let ret = [];
    if (this.contentView == "overview") {
      ret = this.present.overview.filter(t => t.show);
    } else if (this.contentView == "detailed") {
      ret = this.present.detailed.filter(t => t.show);
    } else if (this.contentView == "technical") {
      ret = this.present.technical.filter(t => t.show);
    }

    return ret;
  }

  async performExport(data: any = {}) {
    const formats = ["csv"];
    const exportFns: any = [];
    if (data.accountIds) {
      this.dynamicAccounts = data.accountIds;
    }
    if (data.groupIds) {
      this.dynamicGroups = data.groupIds;
    }

    // if (this.totalAll > 2000) {
    //   notifier.alert("Export CSV error: Can't export more than 2000 rows");
    //   return;
    // }

    if (formats.includes("csv")) {
      exportFns.push(this.csvDownLoadFn());
    }

    try {
      this.loading = true;
      await Promise.all(exportFns);
    } catch (err) {
      console.log(err);
    } finally {
      this.loading = false;
      this.showAccountModal = false;
    }
  }

  async csvDownLoadFn() {
    let fileUrl;
    try {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        Controller: "Reports",
        FunctionName: "ReportBuilderContent",
        reportId: parseInt(this.$route.params.id) || 0,
        ExportType: "CSV",
        reportColumns: this.selectedColumns.toString(),
        dynamicAccounts: this.dynamicAccounts,
        dynamicGroups: this.dynamicGroups
      });
      if (response.data.STATUS == 1) {
        fileUrl = response.data.S3URL || "";
      } else {
        notifier.alert(response.data.STATUSMESSAGE || "Something Went Wrong");
      }
    } catch (err) {
      const message = err.message || "";
      if (message) {
        notifier.alert("Export CSV error: " + message);
      }
    }

    downloadFileUrl(fileUrl);
  }

  async deleteReport() {
    this.confirmReportDelete = false;
    const response = await ApiHelper.callApi("post", {
      controller: "Reports",
      FunctionName: "UpdateReportBuilder",
      action: "delete",
      reportId: parseInt(this.$route.params.id)
    });
    if (response.STATUS) {
      notifier.success("Report Deleted Successfully");
      this.$router.push({ name: "Reports" })
    } else {
      notifier.alert("Error Occured");
    }
  }
}
