import AccountTags from './pages/AccountTags.vue'
import Activities from './pages/Activities.vue'
import Admin from './pages/Admin.vue'
import AgentReports from './pages/AgentReports.vue'
import AgentReportsDetails from './pages/AgentReportsDetails.vue'
import Cashflow from './pages/Cashflow.vue'
import CreatePortal from './pages/CreatePortal.vue'
import CreateTag from './pages/CreateTag.vue'
import CustomData from './pages/CustomData.vue'
import CustomDataAdd from './pages/customDataAdd.vue'
import CustomerAdd from './pages/CustomerAdd.vue'
import CustomerPortals from './pages/CustomerPortals.vue'
import Customers from './pages/Customers.vue'
import Dashboard from './pages/Dashboard.vue'
import Error from './pages/Error.vue'
import ExportSetting from './pages/ExportSetting.vue'
import External from './pages/External.vue'
import ApiKeys from './pages/ApiKeys.vue'
import FinancesDashboard from './pages/Finance.vue'
import distribution from './pages/Distribution.vue'
import GlobalParams from './pages/GlobalParams.vue'
import IncognitoMode from './pages/IncognitoMode.vue'
import GroupDetails from './pages/GroupDetails.vue'
import HardwareAdd from './pages/HardwareAdd.vue'
import HardwareAddNew from './pages/HardwareAddNew.vue'
import HardwareDetails from './pages/HardwareDetails.vue'
import HardwareImportListing from './pages/HardwareImportListing.vue'
import HardwareImportSelect from './pages/HardwareImportSelect.vue'
import Hardware from './pages/Hardwares.vue'
import HardwaresImport from './pages/HardwaresImport.vue'
import HardwaresImportNew from './pages/HardwaresImportNew.vue'
import Indirectorders from './pages/Indirectorders.vue'
import InvoiceCreate from './pages/InvoiceCreate.vue'
import InvoiceDetails from './pages/InvoiceDetails.vue'
import Invoices from './pages/Invoices.vue'
import Audit from './pages/Audit.vue'
import Ledgers from './pages/Ledgers.vue'
import Login from './pages/Login.vue'
import Opportunities from './pages/Opportunities.vue'
import OpportunityCreate from './pages/OpportunityCreate.vue'
import OpportunityDetails from './pages/OpportunityDetails.vue'
import OrderStatus from './pages/OrderStatus.vue'
import Orders from './pages/Orders.vue'
import PayrollDetail from './pages/PaycheckDetail.vue'
import PayrollGenerate from './pages/PaycheckGenerate.vue'
import Payroll from './pages/Paychecks.vue'
import PayrollByUser from './pages/PaychecksByUser.vue'
import Print from './pages/Print.vue'
import Profile from './pages/Profile.vue'
import HardwareUserProfile from './pages/HardwareUserProfile.vue'
import QuoteCreate from './pages/QuoteCreate.vue'
import QuoteRequest from './pages/QuoteRequest.vue'
import QuoteRequestDetail from './pages/QuoteRequestDetail.vue'
import Quotes from './pages/Quotes.vue'
import QuotesEdit from './pages/QuotesEdit.vue'
import Reports from './pages/Reports.vue'
import Engine from './pages/Engine.vue'
import RequestNewQuotes from './pages/RequestNewQuotes.vue'
import RequestQuotes from './pages/RequestQuotes.vue'
import Router from 'vue-router'
import Search from './pages/Search.vue'
import SecurityGroupAdd from './pages/SecurityGroupAdd.vue'
import SecurityGroups from './pages/SecurityGroups.vue'
import ServiceAdd from './pages/ServiceAdd.vue'
import ServiceDetails from './pages/ServiceDetails.vue'
import Services from './pages/Services.vue'
import Spiffs from './pages/Spiffs.vue'
import Subscriptions from './pages/Subscriptions.vue'
import SupportDetails from './pages/SupportDetails.vue'
import Supports from './pages/Supports.vue'
import TemplateEdit from './pages/TemplateEdit.vue'
import { UserAPIRepo } from "./repos/UserAPIRepo"
import UserAdd from './pages/UserAdd.vue'
import Users from './pages/Users.vue'
import Vue from 'vue'
import customerList from './pages/customerList.vue'
import passwordChange from './pages/passwordChange.vue'
import signUp from './pages/signup.vue'
import supportTicket from './pages/supportTicket.vue'
import reportDetail from './pages/reportDetails.vue'
import ReportBuilder from './pages/ReportBuilder.vue'
import ReportBuilderDetails from './pages/ReportBuilderDetails.vue'
import DistyAvailability from './pages/DistyAvailability.vue'
import LedgerPayments from './pages/LedgerPayments.vue'
import InvoiceDeposits from './pages/InvoiceDeposits.vue'
import InvoiceDeposits2 from './pages/InvoiceDeposits2.vue'
import LedgerReports from './pages/LedgerReports.vue'
import GlobalTags from './pages/GlobalTags.vue'
import CreateGlobalTag from './pages/CreateGlobalTag.vue'
import EditTemplate from './pages/EditTemplate.vue'
import Deployments from './pages/Deployments.vue'
import CreateDeployment from './pages/CreateDeployment.vue'
import DeploymentDetails from './pages/DeploymentDetails.vue'
import CreateEndUser from './pages/CreateEndUser.vue'
import ImportProducts from './pages/ImportProducts.vue'
import AssetServiceAdd from './pages/AssetServiceAdd.vue'
// import InvoiceEdit from './pages/InvoiceEdit.vue'
import Logs from './pages/Logs.vue'
import TestOrderStatus from './pages/TestOrderStatus.vue'
import CommissionStatement from './pages/CommissionStatementImport.vue'
import Files from './pages/Files.vue'
import CustomerLanding from "@/pages/CustomerLanding.vue";
import Pay from "@/pages/Pay.vue";
import Demo from "@/pages/Demo.vue";
import TestCommissionImport from './pages/TestCommissionImport.vue'
import Deploy from "@/pages/Deploy.vue";
import SpiffSources from './pages/SpiffSources.vue'
import SpiffSourceDetails from './pages/SpiffSourceDetails.vue'
import DeploymentsNew from "@/pages/DeploymentsNew.vue";
import QuoteStandards from "@/pages/QuoteStandards.vue";
import QuoteStandardEdit from "@/pages/QuoteStandardEdit.vue";
import QuoteStandardDetail from "@/pages/QuoteStandardDetail.vue";
import HPDirectOSSAuto from "@/pages/HPDirectOSSAuto.vue";
import Contracts from "@/pages/Contracts.vue";
import ContractDetails from "@/pages/ContractDetails.vue";
import PricingList from '@/pages/PricingList.vue';
import PricingListDetails from '@/pages/PricingListDetails.vue';
import Alerts from "@/pages/Alerts.vue";
import DeploymentSchedule from "@/pages/Portal/DeploymentSchedule.vue";
import HardwareDashboard from "@/pages/HardwareDashboard.vue";
import GlobalSecurity from "@/pages/GlobalSecurity.vue";
import AddAssetsServices from "@/pages/AddAssetsServices.vue";
import EmployeePlans from "@/pages/EmployeePlans.vue";
import EmployeePlanDetails from "@/pages/EmployeePlanDetails.vue";
import EmployeePlanEdit from "@/pages/EmployeePlanEdit.vue";
import GlobalPortal from "@/pages/GlobalPortal.vue";
import PlanGroups from "@/pages/PlanGroups.vue";
import PlanGroupEdit from "@/pages/PlanGroupEdit.vue";
import PlanGroupDetails from "@/pages/PlanGroupDetails.vue";
import PayInvoice from "@/pages/PayInvoice.vue";
import TemplateBuilder from '@/pages/TemplateBuilder.vue'
import Boards from '@/pages/Boards.vue'
import BoardDetails from '@/pages/BoardDetails.vue'
import OutlookAddIn from "@/pages/OutlookAddIn.vue"
import Sources from './pages/Sources.vue'
import SourceAdd from './pages/SourceAdd.vue'
import FinanceAudit from './pages/FinanceAudit.vue'

// customers page
// import QuotesCustomer from './customer/pages/Quotes.vue'
// import QuoteDetailsCustomer from './customer/pages/QuoteDetails.vue'
// import OrdersCustomer from './customer/pages/Orders.vue'
// import OrderDetailsCustomer from './customer/pages/OrderDetails.vue'

const userRepo = new UserAPIRepo();

export const routerData = new Vue({
  data: {
    errorData: {},
    isDataLoading: {
      value: false
    }
  }
})

function resetRouterData() {
  Vue.set(routerData.errorData, 'message', '')
  Vue.set(routerData.errorData, 'redirectName', '')
  Vue.set(routerData.errorData, 'redirectQuery', {});
  const win = (window as any)
  win.errorData = null
}

function getAccessOption(tabOptions, key) {
  const option = tabOptions.find((item) => item.key == key);
  return option ? option.checked : true;
}

function loadTemplateCSS(template) {
  const head  = document.getElementsByTagName('head')[0];
  const cssName = template;
  if ($(`link[href='/css/${cssName}.css'`).length > 0) {
    // $(`.${template}`).remove();
    console.log(`${cssName} CSS is existed`);
  } else {
    console.log(`added new ${cssName} CSS`);
    const link  = document.createElement('link');
    link.rel  = 'stylesheet';
    link.type = 'text/css';
    link.href = `/css/${cssName}.css`;
    link.className = cssName;
    head.appendChild(link);
  }


  if(window.location.href.includes("customer") && template == ""){
    template = "minimal_outline";
  }
  const customerCssName = `${template}_customer`;
  if(sessionStorage.getItem("userRole") == "Customer") {
    if ($(`.${customerCssName}`).length > 0) {
      // nothing
      console.log(`${customerCssName} CSS is existed`);
    } else {
      console.log(`added new ${customerCssName} CSS`);
      const link  = document.createElement('link');
      link.rel  = 'stylesheet';
      link.type = 'text/css';
      link.href = `/css/${customerCssName}.css`;
      link.className = customerCssName;
      head.appendChild(link);
    }
  } else {
    if ($(`.${customerCssName}`).length > 0) {
      console.log(`remove ${customerCssName} CSS if changing login role`);
      $(`.${customerCssName}`).remove();
    }
  }
}

Vue.use(Router)

// const isCustomer = sessionStorage.getItem("userRole") == "Customer" || localStorage.getItem("userRole") == "Customer";
const router = new Router({
  routes: [
    {
      path: '/',
      name: 'Login',
      component: Login
    },
    {
      path: '/passwordChange/:id/:token/:email',
      name: 'passwordChange',
      component: Login,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: '/pay',
      name: 'Pay',
      component: Pay,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: '/payinvoice',
      name: 'PayInvoice',
      component: PayInvoice,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: '/demo',
      name: 'Demo',
      component: Demo,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: '/deploy',
      name: 'Deploy',
      component: Deploy,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: '/dashboard',
      name: 'Dashboard',
      component: Dashboard,
      meta: {
        requiresAuth: true,
        parent: ''
      }
    },
    {
      path: '/quotes',
      name: 'Quotes',
      // component: isCustomer ? QuotesCustomer : Quotes,
      component: Quotes,
      meta: {
        requiresAuth: true,
        parent: ''
      }
    },
    {
      path: '/quoteRequests',
      name: 'QuoteRequest',
      component: QuoteRequest,
      meta: {
        requiresAuth: true,
        parent: 'Quotes'
      }
    },
    {
      path: '/QuoteStandards',
      name: 'QuoteStandards',
      component: QuoteStandards,
      meta: {
        requiresAuth: true,
        parent: 'Quotes'
      }
    },
    {
      path: '/Contracts',
      name: 'Contracts',
      component: Contracts,
      meta: {
        requiresAuth: true,
        parent: 'Quotes'
      }
    },
    {
      path: '/ContractDetails/:id',
      name: 'ContractDetails',
      component: ContractDetails,
      meta: {
        requiresAuth: true,
        parent: 'Quotes'
      }
    },
    {
      path: '/pricing-list',
      name: 'PricingList',
      component: PricingList,
      meta: {
        requiresAuth: true,
        parent: 'Quotes'
      }
    },
    {
      path: '/pricing-list-details/:id',
      name: 'PricingListDetails',
      component: PricingListDetails,
      meta: {
        requiresAuth: true,
        parent: 'Quotes'
      }
    },
    {
      path: '/quoteCreate',
      name: 'QuoteCreate',
      component: QuoteCreate,
      meta: {
        requiresAuth: true,
        parent: 'Quotes'
      }
    },
    {
      path: '/StandardQuoteCreate/:createquote',
      name: 'StandardQuoteCreate',
      component: QuoteCreate,
      meta: {
        requiresAuth: true,
        parent: 'Quotes'
      }
    },
    {
      path: '/quoteCreate/:qID',
      name: 'QuoteEdit',
      component: QuoteCreate,
      meta: {
        requiresAuth: true,
        parent: 'Quotes'
      }
    },
    {
      path: '/standardEdit/:id',
      name: 'QuoteStandardEdit',
      component: QuoteStandardEdit,
      meta: {
        requiresAuth: true,
        parent: 'Quotes'
      }
    },
    {
      path: '/standard/:id',
      name: 'QuoteStandardDetail',
      component: QuoteStandardDetail,
      meta: {
        requiresAuth: true,
        parent: 'Quotes'
      }
    },
    {
      path: '/quoteDetails/:id',
      name: 'ViewQuote',
      component: () => {
        let template = "";
        let topLevel = "./pages";
        if(sessionStorage.getItem("userRole") == "Customer"){
          topLevel = "./customer/pages"
        }
        if (Vue.prototype.$VARtemplate) {
          template = "/templates/" + Vue.prototype.$VARtemplate;
        }
        return import(`${topLevel}${template}/QuoteDetails.vue`);
      },
      meta: {
        requiresAuth: true,
        parent: 'Quotes'
      }
    },
    {
      path: '/quoteRequestDetail/:id',
      name: 'QuoteRequestDetail',
      component: QuoteRequestDetail,
      meta: {
        requiresAuth: true,
        parent: 'Quotes'
      }
    },
    {
      path: '/quote/:id',
      name: 'QuotesEdit',
      component: QuotesEdit,
      meta: {
        requiresAuth: true,
        parent: 'Quotes'
      }
    },
    {
      path: '/quote',
      name: 'RequestQuotes',
      component: RequestQuotes,
      meta: {
        requiresAuth: true,
        parent: 'Quotes'
      }
    },
    {
      path: '/requestNewQuote',
      name: 'RequestNewQuotes',
      component: RequestNewQuotes,
      meta: {
        requiresAuth: true,
        parent: 'Quotes'
      }
    },
    {
      path: '/requestNewQuote/:qID',
      name: 'RequestEditQuotes',
      component: RequestNewQuotes,
      meta: {
        requiresAuth: true,
        parent: 'Quotes'
      }
    },
    {
      path: '/opportunities',
      name: 'Opportunities',
      component: Opportunities,
      meta: {
        requiresAuth: true,
        parent: 'Quotes'
      }
    },
    {
      path: '/opportunityCreate',
      name: 'opportunityCreate',
      component: OpportunityCreate,
      meta: {
        requiresAuth: true,
        parent: 'Quotes'
      }
    },
    {
      path: '/opportunityEdit/:id',
      name: 'opportunityEdit',
      component: OpportunityCreate,
      meta: {
        requiresAuth: true,
        parent: 'Quotes'
      }
    },
    {
      path: '/opportunityDetails/:id',
      name: 'OpportunityDetails',
      component: OpportunityDetails,
      meta: {
        requiresAuth: true,
        parent: 'Quotes'
      }
    },
    {
      path: '/orders',
      name: 'Orders',
      // component: isCustomer ? OrdersCustomer : Orders,
      component: Orders,
      meta: {
        requiresAuth: true,
        parent: ''
      }
    },
    {
      path: '/orderCreate/:fromQuote',
      name: 'OrderCreate',
      component: () => {
        let template = "";
        let topLevel = "./pages";
        if(sessionStorage.getItem("userRole") == "Customer"){
          topLevel = "./customer/pages"
        }
        if (Vue.prototype.$VARtemplate) {
          template = "/templates/" + Vue.prototype.$VARtemplate;
        }
        return import(`${topLevel}${template}/OrderCreate.vue`);
      },
      meta: {
        requiresAuth: true,
        parent: 'Orders'
      }
    },
    {
      path: '/standardOrderCreate/:createOrder',
      name: 'StandardOrderCreate',
      component: () => {
        let template = "";
        let topLevel = "./pages";
        if(sessionStorage.getItem("userRole") == "Customer"){
          topLevel = "./customer/pages"
        }
        if (Vue.prototype.$VARtemplate) {
          template = "/templates/" + Vue.prototype.$VARtemplate;
        }
        return import(`${topLevel}${template}/OrderCreate.vue`);
      },
      meta: {
        requiresAuth: true,
        parent: 'Orders'
      }
    },
    {
      path: '/generateOrder/:customerPO',
      name: 'GenerateOrder',
      component: () => {
        let template = "";
        let topLevel = "./pages";
        if(sessionStorage.getItem("userRole") == "Customer"){
          topLevel = "./customer/pages"
        }
        if (Vue.prototype.$VARtemplate) {
          template = "/templates/" + Vue.prototype.$VARtemplate;
        }
        return import(`${topLevel}${template}/OrderCreate.vue`);
      },
      meta: {
        requiresAuth: true,
        parent: 'Orders'
      }
    },
    {
      path: '/orderEdit/:id',
      name: 'OrderEdit',
      component: () => {
        let template = "";
        let topLevel = "./pages";
        if(sessionStorage.getItem("userRole") == "Customer"){
          topLevel = "./customer/pages"
        }
        if (Vue.prototype.$VARtemplate) {
          template = "/templates/" + Vue.prototype.$VARtemplate;
        }
        console.log(`${topLevel}${template}/OrderCreate.vue`);
        return import(`${topLevel}${template}/OrderCreate.vue`);
      },
      meta: {
        requiresAuth: true,
        parent: 'Orders'
      }
    },
    {
      path: '/securityGroups',
      name: 'SecurityGroups',
      component: SecurityGroups,
      meta: {
        requiresAuth: true,
        parent: 'Admin'
      }
    },
    {
      path: '/orderDetails/:id',
      name: 'ViewOrder',
      // component: isCustomer ? OrderDetailsCustomer : OrderDetails,
      component: () => {
        let template = "";
        let topLevel = "./pages";
        if(sessionStorage.getItem("userRole") == "Customer"){
          topLevel = "./customer/pages"
        }
        if (Vue.prototype.$VARtemplate) {
          template = "/templates/" + Vue.prototype.$VARtemplate;
        }
        return import(`${topLevel}${template}/OrderDetails.vue`);
      },
      meta: {
        requiresAuth: true,
        parent: 'Orders'
      }
    },
    {
      path: '/orderStatus',
      name: 'OrderStatus',
      component: OrderStatus,
      meta: {
        requiresAuth: true,
        parent: 'Orders'
      }
    },
    {
      path: '/HPDirectOSSAuto/:id',
      name: 'HPDirectOSSAuto',
      component: HPDirectOSSAuto,
      meta: {
        requiresAuth: true,
        parent: 'Orders'
      }
    },
    {
      path: '/supports',
      name: 'Supports',
      component: Supports,
      meta: {
        requiresAuth: false,
        parent: ''
      }
    },
    {
      path: '/supportdetail/:id',
      name: 'ViewSupport',
      component: SupportDetails,
      meta: {
        requiresAuth: false,
        parent: 'Supports'
      }
    },
    {
      path: '/hardware',
      name: 'Hardware',
      component: Hardware,
      props: true,
      meta: {
        requiresAuth: false,
        parent: ''
      }
    },
    {
      path: '/hardware/dashboard',
      name: 'HardwareDashboard',
      component: HardwareDashboard,
      props: true,
      meta: {
        requiresAuth: false,
        parent: ''
      }
    },
    {
      path: '/editHardwareUser/:id',
      name: 'HardwareUserProfile',
      component: HardwareUserProfile,
      meta: {
        requiresAuth: false,
        parent: 'Users'
      }
    },
    {
      path: '/hardwaredetail/:id',
      name: 'ViewHardware',
      component: HardwareDetails,
      meta: {
        requiresAuth: false,
        parent: 'Hardware'
      }
    },
    {
      path: '/addhardwareOld/:id',
      name: 'AddHardwareOld',
      component: HardwareAdd,
      meta: {
        requiresAuth: false,
        parent: 'Hardware'
      }
    },
    {
      path: '/addhardware/:id',
      name: 'AddHardware',
      component: HardwareAddNew,
      meta: {
        requiresAuth: false,
        parent: 'Hardware'
      }
    },
    {
      path: '/ossImport',
      name: 'OSSImport',
      component: HardwaresImport,
      meta: {
        requiresAuth: false,
        parent: 'Hardware'
      }
    },
    {
      path: '/importhardwares',
      name: 'ImportHardwares',
      component: HardwaresImportNew,
      meta: {
        requiresAuth: false,
        parent: 'Hardware'
      }
    },
    {
      path: '/importHardwareSelect',
      name: 'ImportHardwareSelect',
      component: HardwareImportSelect,
      meta: {
        requiresAuth: false,
        parent: 'Hardware'
      }
    },
    {
      path: '/importHardwareListing',
      name: 'ImportHardwareListing',
      component: HardwareImportListing,
      meta: {
        requiresAuth: false,
        parent: 'Hardware'
      }
    },
    {
      path: '/customer-landing',
      name: 'CustomerLanding',
      component: CustomerLanding,
      meta: {
        requiresAuth: false,
        parent: 'Customers'
      }
    },
    {
      path: '/customers',
      name: 'Customers',
      component: Customers,
      meta: {
        requiresAuth: false,
        parent: ''
      }
    },
    {
      path: '/customers/:id',
      name: 'ViewCustomer',
      component: () => {
        let template = "";
        let topLevel = "./pages";
        if(sessionStorage.getItem("userRole") == "Customer"){
          topLevel = "./customer/pages"
        }
        if (Vue.prototype.$VARtemplate) {
          template = "/templates/" + Vue.prototype.$VARtemplate;
        }
        return import(`${topLevel}${template}/CustomerDetails.vue`);
      },
      meta: {
        requiresAuth: false,
        parent: 'Customers'
      }
    },
    {
      path: '/users',
      name: 'Users',
      component: Users,
      meta: {
        requiresAuth: false,
        parent: ''
      }
    },
    {
      path: '/addcustomer',
      name: 'AddCustomer',
      component: CustomerAdd,
      meta: {
        requiresAuth: false,
        parent: 'Customers'
      }
    },
    {
      path: '/services',
      name: 'Services',
      component: Services,
      meta: {
        requiresAuth: false,
        parent: ''
      }
    },
    {
      path: '/servicedetail/:id',
      name: 'ViewService',
      component: ServiceDetails,
      meta: {
        requiresAuth: false,
        parent: 'Services'
      }
    },
    {
      path: '/addservice/',
      name: 'AddService',
      component: ServiceAdd,
      meta: {
        requiresAuth: false,
        parent: 'Services'
      }
    },
    {
      path: '/financesdashboard/',
      name: 'Finances',
      component: FinancesDashboard,
      meta: {
        requiresAuth: false,
        parent: 'Finances'
      }
    },
    {
      path: '/distribution/',
      name: 'Distribution',
      component: distribution,
      meta: {
        requiresAuth: false,
        parent: 'Finances'
      }
    },
    {
      path: '/cashflow/',
      name: 'Cashflow',
      component: Cashflow,
      meta: {
        requiresAuth: false,
        parent: 'Finances'
      }
    },
    {
      path: '/ledgers/',
      name: 'Ledgers',
      component: Ledgers,
      meta: {
        requiresAuth: false,
        parent: 'Finances'
      }
    },
    {
      path: '/hpagent/',
      name: 'HPAgent',
      component: AgentReports,
      meta: {
        requiresAuth: true,
        parent: 'Finances'
      }
    },
    {
      path: '/hpagent_details/:id',
      name: 'HPAgentDetails',
      component: AgentReportsDetails,
      meta: {
        requiresAuth: true,
        parent: 'Finances'
      }
    },
    {
      path: '/payrollGenerate',
      name: 'PayrollGenerate',
      component: PayrollGenerate,
      meta: {
        requiresAuth: true,
        parent: 'Finances'
      }
    },
    {
      path: '/payroll/',
      name: 'Payroll',
      component: Payroll,
      meta: {
        requiresAuth: false,
        parent: 'Finances'
      }
    },
    {
      path: '/payroll_details/:id/:empid',
      name: 'PayrollDetail',
      component: PayrollDetail,
      meta: {
        requiresAuth: false,
        parent: 'Finances'
      }
    },
    {
      path: '/payroll_details/:batchID',
      name: 'PayrollAccountDetail',
      component: PayrollDetail,
      meta: {
        requiresAuth: false,
        parent: 'Finances'
      }
    },
    {
      path: '/payroll_user/:id',
      name: 'PayrollByUser',
      component: PayrollByUser,
      meta: {
        requiresAuth: false,
        parent: 'Finances'
      }
    },
    {
      path: '/spiffs/',
      name: 'Spiffs',
      component: Spiffs,
      meta: {
        requiresAuth: false,
        parent: 'Finances'
      }
    },
    {
      path: '/spiffSources/',
      name: 'SpiffSources',
      component: SpiffSources,
      meta: {
        requiresAuth: false,
        parent: 'Finances'
      }
    },
    {
      path: '/spiffSourceDetails/:id',
      name: 'ViewSpiffSources',
      component: SpiffSourceDetails,
      meta: {
        requiresAuth: false,
        parent: 'Finances'
      },
    },
    {
      path: '/employeePlans',
      name: 'EmployeePlans',
      component: EmployeePlans,
      meta: {
        requiresAuth: false,
        parent: 'Finances'
      }
    },
    {
      path: '/employeePlan/:id',
      name: 'EmployeePlanDetails',
      component: EmployeePlanDetails,
      meta: {
        requiresAuth: false,
        parent: 'Finances'
      }
    },
    {
      path: '/employeePlanEdit/:id',
      name: 'EmployeePlanEdit',
      component: EmployeePlanEdit,
      meta: {
        requiresAuth: false,
        parent: 'Finances'
      }
    },
    {
      path: '/planGroups',
      name: 'PlanGroups',
      component: PlanGroups,
      meta: {
        requiresAuth: false,
        parent: 'Finances'
      }
    },
    {
      path: '/planGroupEdit/:id',
      name: 'PlanGroupEdit',
      component: PlanGroupEdit,
      meta: {
        requiresAuth: false,
        parent: 'Finances'
      }
    },
    {
      path: '/planGroup/:id',
      name: 'PlanGroupDetails',
      component: PlanGroupDetails,
      meta: {
        requiresAuth: false,
        parent: 'Finances'
      }
    },
    {
      path: '/supportTicket/',
      name: 'SupportTicket',
      component: supportTicket,
      meta: {
        requiresAuth: false,
        parent: ''
      }
    },
    {
      path: '/reports/',
      name: 'Reports',
      component: Reports,
      meta: {
        requiresAuth: false,
        parent: ''
      }
    },
    {
      path: '/reportDetail/:id',
      name: 'ReportDetails',
      component: reportDetail,
      meta: {
        requiresAuth: false,
        parent: 'Reports'
      }
    },
    {
      path: '/reportBuilder/:id',
      name: 'ReportBuilder',
      component: ReportBuilder,
      meta: {
        requiresAuth: true,
        parent: 'Reports'
      }
    },
    {
      path: '/reportBuilderDetails/:id',
      name: 'ReportBuilderDetails',
      component: ReportBuilderDetails,
      meta: {
        requiresAuth: true,
        parent: 'Reports'
      }
    },
    {
      path: '/alerts',
      name: 'Alerts',
      component: Alerts,
      meta: {
        requiresAuth: false,
        parent: 'Reports'
      }
    },
    {
      path: '/profile',
      name: 'Profile',
      component: Profile,
      meta: {
        requiresAuth: false,
        parent: 'Admin'
      }
    },
    {
      path: '/userAdd',
      name: 'UserAdd',
      component: UserAdd,
      meta: {
        requiresAuth: false,
        parent: 'Users'
      }
    },
    {
      path: '/SecurityGroupAdd',
      name: 'SecurityGroupAdd',
      component: SecurityGroupAdd,
      meta: {
        requiresAuth: false,
        parent: 'Admin'
      }
    },
    {
      path: '/admin',
      name: 'Admin',
      component: Admin,
      meta: {
        requiresAuth: false,
        parent: 'Admin'
      }
    },
    {
      path: '/editUser/:id',
      name: 'EditUser',
      component: Profile,
      meta: {
        requiresAuth: false,
        parent: 'Users'
      }
    },
    {
      path: '/logout',
      name: 'Logout',
    },
    {
      path: '/search/:key/for/:searchIn',
      name: 'Search',
      component: Search,
      meta: {
        requiresAuth: true,
        parent: ''
      }
    },
    {
      path: '/search',
      name: 'SearchMain',
      component: Search,
      meta: {
        requiresAuth: true,
        parent: ''
      }
    },
    {
      path: '/groupDetails/:groupID',
      name: 'GroupDetails',
      component: GroupDetails,
      meta: {
        requiresAuth: true,
        parent: ''
      }
    },
    {
      path: '/print',
      name: 'Print',
      component: Print,
      meta: {
        requiresAuth: false,
        parent: ''
      }
    },
    {
      path: '/invoices',
      name: 'Invoices',
      component: Invoices,
      meta: {
        requiresAuth: true,
        parent: 'Orders'
      }
    },
    {
      path: '/invoiceCreate/:fromOrder',
      name: 'InvoiceCreate',
      component: InvoiceCreate,
      meta: {
        requiresAuth: true,
        parent: 'Orders'
      }
    },
    {
      path: '/invoiceEdit/:id',
      name: 'InvoiceEdit',
      component: InvoiceCreate,
      meta: {
        requiresAuth: true,
        parent: 'Orders'
      }
    },
    {
      path: '/invoiceDetails/:id',
      name: 'InvoiceDetails',
      component: InvoiceDetails,
      meta: {
        requiresAuth: true,
        parent: 'Orders'
      }
    },
    {
      path: '/export_setting',
      name: 'ExportSetting',
      component: ExportSetting,
      meta: {
        requiresAuth: true,
        parent: 'Admin'
      }
    },
    {
      path: '/templateBuilder',
      name: 'TemplateBuilder',
      component: TemplateBuilder,
      meta: {
        requiresAuth: true,
        parent: 'Admin'
      }
    },
    {
      path: '/templateBuilder/:id',
      name: 'TemplateBuilder',
      component: TemplateBuilder,
      meta: {
        requiresAuth: true,
        parent: 'Admin'
      }
    },
    {
      path: '/templateEdit/:id',
      name: 'TemplateEdit',
      component: TemplateEdit,
      meta: {
        requiresAuth: true,
        parent: ''
      }
    },
    {
      path: '/external',
      name: 'external',
      component: External,
      meta: {
        requiresAuth: true,
        parent: 'Admin'
      }
    },
    {
      path: '/apikeys',
      name: 'ApiKeys',
      component: ApiKeys,
      meta: {
        requiresAuth: true,
        parent: 'Admin'
      }
    },
    {
      path: '/subscription',
      name: 'subscription',
      component: Subscriptions,
      meta: {
        requiresAuth: true,
        parent: 'Admin'
      }
    },
    {
      path: '/indirectorders',
      name: 'Indirectorders',
      component: Indirectorders,
      meta: {
        requiresAuth: false,
        parent: 'Finances'
      }
    },
    {
      path: '/error',
      name: 'Error',
      component: Error,
      meta: {
        requiresAuth: true,
        parent: ''
      }
    },
    {
      path: '/customerList',
      name: 'customerList',
      component: customerList,
      meta: {
        requiresAuth: true,
        parent: 'Admin'
      }
    },
    {
      path: '/signup',
      name: 'signUp',
      component: signUp,
      meta: {
        requiresAuth: false,
        parent: ''
      }
    },
    {
      path: '/customerPortals',
      name: 'CustomerPortals',
      component: CustomerPortals,
      meta: {
        requiresAuth: true,
        parent: ''
      }
    },
    {
      path: '/customerPortals/:id',
      name: 'UpdatePortal',
      component: CreatePortal,
      meta: {
        requiresAuth: true,
        parent: 'Portals'
      }
    },
    {
      path: '/createPortal',
      name: 'CreatePortal',
      component: CreatePortal,
      meta: {
        requiresAuth: true,
        parent: 'Portals'
      }
    },
    {
      path: '/accountTags',
      name: 'AccountTags',
      component: AccountTags,
      meta: {
        requiresAuth: true,
        parent: 'Admin'
      }
    },
    {
      path: '/accountTagsDetails/:id',
      name: 'UpdateTag',
      component: CreateTag,
      meta: {
        requiresAuth: true,
        parent: 'Admin'
      }
    },
    {
      path: '/createTag',
      name: 'CreateTag',
      component: CreateTag,
      meta: {
        requiresAuth: true,
        parent: 'Admin'
      }
    },
    {
      path: '/customdata',
      name: 'CustomData',
      component: CustomData,
      meta: {
        requiresAuth: true,
        parent: 'Admin'
      }
    },
    {
      path: '/customadd',
      name: 'CustomAdd',
      component: CustomDataAdd,
      meta: {
        requiresAuth: true,
        parent: 'Admin'
      }
    },
    {
      path: '/customadd/:id',
      name: 'CustomdataUpdate',
      component: CustomDataAdd,
      meta: {
        requiresAuth: true,
        parent: 'Admin'
      }
    },
    {
      path: '/activities',
      name: 'Activities',
      component: Activities,
      meta: {
        requiresAuth: true,
        parent: 'Admin'
      }
    },
    {
      path: '/globalparams',
      name: 'GlobalParams',
      component: GlobalParams,
      meta: {
        requiresAuth: true,
        parent: 'Admin'
      }
    },
    {
      path: '/distyAvailability',
      name: 'DistyAvailability',
      component: DistyAvailability,
      meta: {
        requiresAuth: true,
        parent: 'Custom'
      }
    },
    {
      path: '/duplicateOrder/:id',
      name: 'DuplicateOrder',
      component: () => {
        let template = "";
        let topLevel = "./pages";
        if(sessionStorage.getItem("userRole") == "Customer"){
          topLevel = "./customer/pages"
        }
        if (Vue.prototype.$VARtemplate) {
          template = "/templates/" + Vue.prototype.$VARtemplate;
        }
        return import(`${topLevel}${template}/OrderCreate.vue`);
      },
      meta: {
        requiresAuth: true,
        parent: 'Orders'
      }
    },
    {
      path: '/engine/',
      name: 'Engine',
      component: Engine,
      meta: {
        requiresAuth: false,
        parent: ''
      }
    },
    {
      path: '/incognitoMode/',
      name: 'IncognitoMode',
      component: IncognitoMode,
      meta: {
        requiresAuth: false,
        parent: 'Admin'
      }
    },
    {
      path: '/audit',
      name: 'Audit',
      component: Audit,
      meta: {
        requiresAuth: false,
        parent: 'Orders'
      }
    },
    {
      path: '/ledgerPayments/',
      name: 'ledgerPayments',
      component: LedgerPayments,
      meta: {
        requiresAuth: false,
        parent: 'Finances'
      }
    },
    {
      path: '/invoiceDeposits/',
      name: 'invoiceDeposits',
      component: InvoiceDeposits,
      meta: {
        requiresAuth: false,
        parent: 'Orders'
      }
    },
    {
      path: '/invoiceDepositsModal',
      component: InvoiceDeposits2,
      meta: {
        requiresAuth: false,
        parent: 'Orders'
      }
    },
	{
      path: '/ledgerReports/',
      name: 'ledgerReports',
      component: LedgerReports,
      meta: {
        requiresAuth: false,
        parent: 'Finances'
      }
    },
    {
      path: '/globalTags',
      name: 'GlobalTags',
      component: GlobalTags,
      meta: {
        requiresAuth: true,
        parent: 'Admin'
      }
    },
    {
      path: '/globalTagsDetails/:id',
      name: 'UpdateGlobalTag',
      component: CreateGlobalTag,
      meta: {
        requiresAuth: true,
        parent: 'Admin'
      }
    },
    {
      path: '/createGlobalTag',
      name: 'CreateGlobalTag',
      component: CreateGlobalTag,
      meta: {
        requiresAuth: true,
        parent: 'Admin'
      }
    },
    {
      path: '/templates',
      name: 'EditTemplate',
      component: EditTemplate,
      meta: {
        requiresAuth: true,
        parent: 'Admin'
      }
    },
    {
      path: '/deployments',
      name: 'Deployments',
      component: Deployments,
      meta: {
        requiresAuth: true,
        parent: ''
      }
    },
    {
      path: '/deployments-new',
      name: 'DeploymentsNew',
      component: DeploymentsNew,
      meta: {
        requiresAuth: true,
        parent: ''
      }
    },
    {
      path: '/createDeployment',
      name: 'CreateDeployment',
      component: CreateDeployment,
      meta: {
        requiresAuth: true,
        parent: 'Deployments'
      }
    },
    {
      path: '/DeploymentDetails/:id',
      name: 'ViewDeployment',
      component: DeploymentDetails,
      meta: {
        requiresAuth: true,
        parent: 'Deployments'
      }
    },
    {
      path: '/createEndUser/:id',
      name: 'CreateEndUser',
      component: CreateEndUser,
      meta: {
        requiresAuth: true,
        parent: 'Admin'
      }
    },
 	{
      path: '/editEndUser/:id/:endUserId',
      name: 'CreateEndUser',
      component: CreateEndUser,
      meta: {
        requiresAuth: true,
        parent: 'Admin'
      }
    },
    {
      path: '/importProducts/:id',
      name: 'ImportProducts',
      component: AddAssetsServices,
      meta: {
        requiresAuth: true,
        parent: ''
      }
    },
    {
      path: '/addProducts/:type/:id',
      name: 'AssetServiceAdd',
      component: AddAssetsServices,
      meta: {
        requiresAuth: true,
        parent: ''
      }
    },
    {
      path: '/logs',
      name: 'Logs',
      component: Logs,
      meta: {
        requiresAuth: true,
        parent: ''
      }
    },
    {
      path: '/testOrderStatus',
      name: 'TestOrderStatus',
      component: TestOrderStatus,
      meta: {
        requiresAuth: true,
        parent: ''
      }
    },
    {
      path: '/testCommissionImport',
      name: 'TestCommissionImport',
      component: TestCommissionImport,
      meta: {
        requiresAuth: true,
        parent: ''
      }
    },
    {
      path: '/commissionStatement',
      name: 'CommissionStatement',
      component: CommissionStatement,
      meta: {
        requiresAuth: true,
        parent: 'Admin'
      }
    },
    {
      path: '/files',
      name: 'Files',
      component: Files,
      meta: {
        requiresAuth: false,
        parent: ''
      }
    },
    {
      path: '/deployschedule/:aID/:uuid/:userId',
      name: 'DeploymentSchedule',
      component: DeploymentSchedule,
      meta: {
        requiresAuth: false,
        parent: ''
      }
    },
    {
      path: '/globalSecurity',
      name: 'GlobalSecurity',
      component: GlobalSecurity,
      meta: {
        requiresAuth: true,
        parent: 'Admin'
      }
    },
    {
      path: '/globalPortal',
      name: 'GlobalPortal',
      component: GlobalPortal,
      meta: {
        requiresAuth: true,
        parent: 'Admin'
      }
    },
    {
      path: '/sources',
      name: 'Sources',
      component: Sources,
      meta: {
        requiresAuth: true,
        parent: 'Admin'
      }
    },
    {
      path: '/SourceAdd/:id',
      name: 'SourceAdd',
      component: SourceAdd,
      meta: {
        requiresAuth: false,
        parent: 'Admin'
      }
    },
    {
      path: '/boards',
      name: 'Boards',
      component: Boards,
      meta: {
        requiresAuth: true,
        parent: 'Finances'
      }
    },
    {
      path: '/boards/:id',
      name: 'BoardDetails',
      component: BoardDetails,
      meta: {
        requiresAuth: true,
        parent: 'Finances'
      }
    },
    {
      path: '/financeAudit',
      name: 'FinanceAudit',
      component: FinanceAudit,
      meta: {
        requiresAuth: false,
        parent: 'Finances'
      }
    }
  ],
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

//declare user functions
const userFunctions = {
  Security_Groups: 1,
  All_Milestone_Accounts: 6,
  Orders: 7,
  Quotes: 8,
  Add_Quote: 9,
  Invoices: 10,
  Opportunities: 11,
  Add_Invoice: 13,
  Add_Order: 14,
  Order_Status: 15,
  Invoice_Details: 18,
  Order_Details: 21,
  Quote_Details: 22,
  Paychecks: 28,
  HP_Agent_Reports: 29,
  Users: 31,
  Finances: 32,
  SPIFFS: 33,
  Resale_Indirect: 34,
  Support: 35,
  Customers: 36,
  Reports: 37,
  //Admin_Profile           : 38,
  Setting_Export: 39,
  External: 40,
  Subscription: 41,
  All_SPIFFs: 43,
  Company_Paychecks: 44,
  Hardware_List: 45,
  Add_Hardware: 46,
  Import_From_Excel: 47,
  AccountTag: 48,
  CustomData: 49,
  Engine: 50,
  IncognitoMode: 51,
  Audit: 52,
  Cashflow: 53,
  Ledgers: 54,
  GlobalTag: 55,
  Logs: 56,
  CommissionStatement: 57,
  Files: 58,
  Deployments: 59,
  Generate_Paycheck: 60,
  Dashboard: 61,
  Global_Security: 62,
  Compensation: 63,
  CompAdmin: 64,
  Contracts: 65,
  Boards: 66,
  Standards: 67,
  RequestQuote: 68,
  FinanceAudit: 69
};
Vue.prototype.$userFunctions = userFunctions;

//can access to some user functions?
Vue.prototype.$allowedFunctions = [];
Vue.prototype.$systemGroups = [];
Vue.prototype.$isCustomer = false;
Vue.prototype.$defaultView = '/dashboard';
Vue.prototype.$showDashboard = true;
Vue.prototype.$customerPortalLogo = '';
Vue.prototype.$secondaryColor = '';
Vue.prototype.$primaryColor = '';
Vue.prototype.$decimals = 4;
Vue.prototype.$decimalsView = 2;
Vue.prototype.$isHideReseller = false;
Vue.prototype.$hideEmployeePlanNumbers = false;
Vue.prototype.$pinEmployeePlanStats = false;
Vue.prototype.$employeePlanIds = '';
Vue.prototype.$dueDateAlerts = false;
Vue.prototype.$employeePlanInfo = {
  UNPAID_COMM: 0,
  UNPAID_COMM_FORMATTED: '',
  PERIOD_COMM: 0,
  PERIOD_COMM_FORMATTED: '',
  PERCENT: 0,
  PERCENT2: 0,
  ITEMS: [],
};
Vue.prototype.$alertCountReseller = 0;
router.beforeEach(
  async (to, from, next) => {
    Vue.prototype.$showDashboard = true;
    Vue.prototype.$customerPortalLogo = '';
    Vue.prototype.$secondaryColor = '';
    Vue.prototype.$primaryColor = '';
    try {
      const session = sessionStorage.getItem('sessionID');

      if(to.name == 'Print') {
        next();
        return;
      }

      if(to.name === "OutlookAddIn"){
        resetRouterData()
        next({ path: '/outlook' });
        return;
      } else if (to.name === 'Login' && session) {
        resetRouterData()
        next({ path: '/dashboard' });
        return;
      } else if (to.name != "Login" && to.name != "passwordChange" && to.name != "signUp" && to.name != "Pay" && to.name != "PayInvoice" && to.name != "Demo" && to.name != "Deploy" && to.name != "DeploymentSchedule" && to.name != "InvoiceDeposits2" && session) {
        var getAccess = await userRepo.getAllowedFunctions();
        if (getAccess === false) {
          sessionStorage.clear();
          resetRouterData()
          next({ path: '/' });
          return;
        }

        var allowedFunction = getAccess["allowedFunctions"];
        var systemGroups = getAccess["systemGroups"];

        if (getAccess["isCustomer"]) {
          Vue.prototype.$customerPortalLogo = getAccess.customerPortalLogo;
          Vue.prototype.$primaryColor = getAccess.primaryColor;
          Vue.prototype.$secondaryColor = getAccess.secondaryColor;
          const tabOptions = getAccess.tabOptions || [];
          const accessQuotes = getAccessOption(tabOptions, 'Quotes');
          if (!accessQuotes) {
            allowedFunction = allowedFunction.filter((functionId) => functionId != userFunctions.Quotes)
          }
          const accessQuoteDetails = getAccessOption(tabOptions, 'Quote_Details');
          if (!accessQuoteDetails) {
            allowedFunction = allowedFunction.filter((functionId) => functionId != userFunctions.Quote_Details)
          }
          const accessAddQuote = getAccessOption(tabOptions, 'Add_Quote');
          if (!accessAddQuote) {
            allowedFunction = allowedFunction.filter((functionId) => functionId != userFunctions.Add_Quote)
          }
          const accessOrders = getAccessOption(tabOptions, 'Orders');
          if (!accessOrders) {
            allowedFunction = allowedFunction.filter((functionId) => functionId != userFunctions.Orders)
          }
          const accessOrderDetails = getAccessOption(tabOptions, 'Order_Details');
          if (!accessOrderDetails) {
            allowedFunction = allowedFunction.filter((functionId) => functionId != userFunctions.Order_Details)
          }
          const accessAddOrder = getAccessOption(tabOptions, 'Add_Order');
          if (!accessAddOrder) {
            allowedFunction = allowedFunction.filter((functionId) => functionId != userFunctions.Add_Order)
          }
          const accessOrderStatus = getAccessOption(tabOptions, 'Order_Status');
          if (!accessOrderStatus) {
            allowedFunction = allowedFunction.filter((functionId) => functionId != userFunctions.Order_Status)
          }
          const accessHardwareList = getAccessOption(tabOptions, 'Hardware_List');
          if (!accessHardwareList) {
            allowedFunction = allowedFunction.filter((functionId) => functionId != userFunctions.Hardware_List)
          }
          const accessAddHardware = getAccessOption(tabOptions, 'Add_Hardware');
          if (!accessAddHardware) {
            allowedFunction = allowedFunction.filter((functionId) => functionId != userFunctions.Add_Hardware)
          }
          const accessImportFromExcel = getAccessOption(tabOptions, 'Import_From_Excel');
          if (!accessImportFromExcel) {
            allowedFunction = allowedFunction.filter((functionId) => functionId != userFunctions.Import_From_Excel)
          }
          const accessDeployments = getAccessOption(tabOptions, 'Deployments');
          if (!accessDeployments) {
            allowedFunction = allowedFunction.filter((functionId) => functionId != userFunctions.Deployments)
          }
          const accessUsers = getAccessOption(tabOptions, 'Users');
          if (!accessUsers) {
            allowedFunction = allowedFunction.filter((functionId) => functionId != userFunctions.Users)
          }
          const accessInvoices = getAccessOption(tabOptions, 'Invoices');
          if (!accessInvoices) {
            allowedFunction = allowedFunction.filter((functionId) => functionId != userFunctions.Invoices)
          }
          const accessSupport = getAccessOption(tabOptions, 'Support');
          if (!accessSupport) {
            allowedFunction = allowedFunction.filter((functionId) => functionId != userFunctions.Support)
          }

          const accessContracts = getAccessOption(tabOptions, 'Contracts');
          if (!accessContracts) {
            allowedFunction = allowedFunction.filter((functionId) => functionId != userFunctions.Contracts)
          }

          const accessStandards = getAccessOption(tabOptions, 'Standards');
          if (!accessStandards) {
            allowedFunction = allowedFunction.filter((functionId) => functionId != userFunctions.Standards)
          }

          const accessRequestQuote = getAccessOption(tabOptions, 'Request_Quote');
          if (!accessRequestQuote) {
            allowedFunction = allowedFunction.filter((functionId) => functionId != userFunctions.RequestQuote)
          }

          const accessOpportunities = getAccessOption(tabOptions, 'Opportunities');
          if (!accessOpportunities) {
            allowedFunction = allowedFunction.filter((functionId) => functionId != userFunctions.Opportunities)
          }

          const accessReports = getAccessOption(tabOptions, 'Reports');
          if (!accessReports) {
            allowedFunction = allowedFunction.filter((functionId) => functionId != userFunctions.Reports)
          }

          const accessDashboard = getAccessOption(tabOptions, 'Dashboard');
          if (!accessDashboard) {
            Vue.prototype.$showDashboard = false;
          }
          Vue.prototype.$defaultView  = '/dashboard';
          if (!Vue.prototype.$showDashboard) {
            Vue.prototype.$defaultView = accessQuotes ? '/quotes' : accessOrders ? '/orders' : accessHardwareList ? '/hardware' : accessDeployments ? '/deployments' : accessUsers ? '/customerList' : '/admin'
          }
        } else {
          Vue.prototype.$hideEmployeePlanNumbers = getAccess.hideEmployeePlanNumbers;
          Vue.prototype.$pinEmployeePlanStats = getAccess.pinEmployeePlanStats;
          Vue.prototype.$employeePlanIds = getAccess.employeePlanIds;
          Vue.prototype.$dueDateAlerts = getAccess.dueDateAlerts;

          // reseller
          if (!allowedFunction.includes(userFunctions.Dashboard) && !systemGroups.includes("sys_admin")) {
            Vue.prototype.$showDashboard = false;
          }
        }
        
        // eslint-disable-next-line
        Vue.prototype.$allowedFunctions = allowedFunction;
        // eslint-disable-next-line
        Vue.prototype.$systemGroups = systemGroups;
        Vue.prototype.$isCustomer = getAccess["isCustomer"];
        Vue.prototype.$isHideReseller = getAccess["isHideReseller"];
        Vue.prototype.$alertCountReseller = getAccess["alertCountReseller"];
        // Setting the variable as a Vue prototype
        Vue.prototype.$VARtemplate = localStorage.getItem('VARtemplate');


        if (Vue.prototype.$VARtemplate && Vue.prototype.$VARtemplate != "") {
          let VARtemplate = Vue.prototype.$VARtemplate;
          loadTemplateCSS(Vue.prototype.$VARtemplate);
          import(`./components/Header_${VARtemplate}.vue`).then((module) => {
            Vue.component('add-header', module.default)
          });
        } else {
          import('./components/Header.vue').then((module) => {
            Vue.component('add-header', module.default)
          });
        }

        var canAccess = true;
        switch (to.name) {
          case "Dashboard": // check if reseller has "Dashboard" function
            if (!getAccess["isCustomer"] && !allowedFunction.includes(userFunctions.Dashboard) && !systemGroups.includes("sys_admin")) {
              canAccess = false;
            }
            break;
          case "SecurityGroups"://  /securityGroups
            if (!allowedFunction.includes(userFunctions.Security_Groups) && !systemGroups.includes("sys_admin")) {
              canAccess = false;
            }
            break;
          case "UserAdd": // /userAdd
            if (!getAccess["isCustomer"]) {//check for reseller
              if (!allowedFunction.includes(userFunctions.Users) && !systemGroups.includes("user_admin")) {
                canAccess = false;
              }
            }
            break;
          case "SecurityGroupAdd": // /SecurityGroupAdd
            if (!allowedFunction.includes(userFunctions.Security_Groups) && !systemGroups.includes("sys_admin")) {
              canAccess = false;
            }
            break;
          case "Orders": // /Orders
            if (!allowedFunction.includes(userFunctions.Orders)) {
              canAccess = false;
            }
            break;
          case "Quotes": // /Quotes
            if (!allowedFunction.includes(userFunctions.Quotes)) {
              canAccess = false;
            }
            break;
          case "QuoteCreate": // /quoteCreate
            if (!allowedFunction.includes(userFunctions.Add_Quote)) {
              canAccess = false;
            }
            break;
          case "Invoices": // /invoices
            if (!allowedFunction.includes(userFunctions.Invoices)) {
              canAccess = false;
            }
            break;
          case "InvoiceCreate": // /invoiceCreate/0
            if (!allowedFunction.includes(userFunctions.Add_Invoice)) {
              canAccess = false;
            }
            break;
          case "OrderCreate": // /orderCreate/
            if (!allowedFunction.includes(userFunctions.Add_Order)) {
              canAccess = false;
            }
            break;
          case "GroupDetails": // /groupDetails/79
            if (!allowedFunction.includes(userFunctions.Security_Groups) && !systemGroups.includes("sys_admin")) {
              canAccess = false;
            }
            break;
          case "EditUser": // /editUser/23
            if (!getAccess["isCustomer"]) {//check for reseller
              if (!allowedFunction.includes(userFunctions.Users) && !systemGroups.includes("user_admin")) {
                canAccess = false;
              }
            }
            break;
          case "InvoiceDetails": // /invoiceDetails/19121345
            if (!allowedFunction.includes(userFunctions.Invoice_Details)) {
              canAccess = false;
            }
            break;
          case "ViewOrder": // /orderDetails/30678
            if (!allowedFunction.includes(userFunctions.Order_Details)) {
              canAccess = false;
            }
            break;
          case "ViewQuote": // /quoteDetails/19121683
            if (!allowedFunction.includes(userFunctions.Quote_Details)) {
              canAccess = false;
            }
            break;
          case "Payroll": // /Payroll
            if (!allowedFunction.includes(userFunctions.Paychecks) && !systemGroups.includes("financial_admin")) {
              canAccess = false;
            }
            break;
          case "AgentReports": // /hpagents
            if (!allowedFunction.includes(userFunctions.HP_Agent_Reports)) {
              canAccess = false;
            }
            break;
          case "AgentReportsDetails": // /hpagent detail page
            if (!allowedFunction.includes(userFunctions.HP_Agent_Reports)) {
              canAccess = false;
            }
            break;
          case "Users": // /users
            if (!allowedFunction.includes(userFunctions.Users) && !systemGroups.includes("user_admin")) {
              canAccess = false;
            }
            break;
          case "Finances":
            if (!allowedFunction.includes(userFunctions.Finances)) {
              canAccess = false;
            }
            break;
          case "Boards":
            if (!allowedFunction.includes(userFunctions.Boards)) {
              canAccess = false;
            }
            break;
          case "BoardDetails":
            if (!allowedFunction.includes(userFunctions.Boards)) {
              canAccess = false;
            }
            break;
          case "Distribution":
            if (!allowedFunction.includes(userFunctions.Finances)) {
              canAccess = false;
            }
            break;
          case "Cashflow":
              if (!allowedFunction.includes(userFunctions.Cashflow)) {
                canAccess = false;
              }
              break;
          case "Ledgers":
            if (!allowedFunction.includes(userFunctions.Ledgers)) {
                canAccess = false;
              }
              break;
          case "Spiffs":
            if (!allowedFunction.includes(userFunctions.SPIFFS)) {
              canAccess = false;
            }
            break;
          case "Indirectorders":
            if (!allowedFunction.includes(userFunctions.Resale_Indirect)) {
              canAccess = false;
            }
            break;
          case "Contracts":
            if (!allowedFunction.includes(userFunctions.Contracts)) {
              canAccess = false;
            }
            break;
          case "EmployeePlanEdit":
            if (!allowedFunction.includes(userFunctions.CompAdmin)) {
              canAccess = false;
            }
            break;
          case "PlanGroups":
            if (!allowedFunction.includes(userFunctions.CompAdmin)) {
              canAccess = false;
            }
            break;
          case "EmployeePlans":
            if (!allowedFunction.includes(userFunctions.CompAdmin)) {
              canAccess = false;
            }
            break;
          case "PlanGroupEdit":
            if (!allowedFunction.includes(userFunctions.CompAdmin)) {
              canAccess = false;
            }
            break;
          case "PlanGroupDetails":
            if (!allowedFunction.includes(userFunctions.CompAdmin)) {
              canAccess = false;
            }
            break;
          case "Supports":
            if (!allowedFunction.includes(userFunctions.Support)) {
              canAccess = false;
            }
            break;
          case "Customers":
            if (!allowedFunction.includes(userFunctions.Customers) && !allowedFunction.includes(userFunctions.All_Milestone_Accounts)) {
              canAccess = false;
            }
            break;
          case "ViewCustomer"://user need access to customers list first
            if (!allowedFunction.includes(userFunctions.Customers) && !allowedFunction.includes(userFunctions.All_Milestone_Accounts)) {
              canAccess = false;
            }
            break;
          case "Reports":
          case "Alerts":
            if (!allowedFunction.includes(userFunctions.Reports)) {
              canAccess = false;
            }
            break;
          case "ExportSetting":
            if (!allowedFunction.includes(userFunctions.Setting_Export)) {
              canAccess = false;
            }
            break;
          case "external":
            if (!allowedFunction.includes(userFunctions.External)) {
              canAccess = false;
            }
            break;
          case "apikeys":
          case "HPDirectOSSAuto":
          case "ReportBuilder":
            // prevent customer access
            if (getAccess["isCustomer"]) {
              canAccess = false;
            }
            break;
          case "ReportBuilderDetails":
            // prevent customer access
            // if (getAccess["isCustomer"]) {
            //   canAccess = false;
            // }
            if (!allowedFunction.includes(userFunctions.Reports)) {
              canAccess = false;
            }
            break;
          case "subscription":
            if (!allowedFunction.includes(userFunctions.Subscription)) {
              canAccess = false;
            }
            break;
          case "AddHardware":
            if (!allowedFunction.includes(userFunctions.Add_Hardware)) {
              canAccess = false;
            }
            break;
          case "Hardware":
            if (!allowedFunction.includes(userFunctions.Hardware_List)) {
              canAccess = false;
            }
            break;
          case "ImportHardwares":
            if (!allowedFunction.includes(userFunctions.Import_From_Excel)) {
              canAccess = false;
            }
            break;
          case "RequestNewQuotes": // /requestNewQuote
          case "RequestEditQuotes": // /requestNewQuote/:qID
            if (!allowedFunction.includes(userFunctions.RequestQuote)) {
              canAccess = false;
            }
            break;
          case "QuoteStandards":
          case "QuoteStandardDetail":
            if (!allowedFunction.includes(userFunctions.Standards)) {
              canAccess = false;
            }
            break;
          case "QuoteStandardEdit":
            if (!allowedFunction.includes(userFunctions.Standards)) {
              canAccess = false;
            }

            if (getAccess["isCustomer"]) {
              canAccess = false;
            }
            break;
          case "CustomerPortals":
            if (!allowedFunction.includes(userFunctions.Customers) && !allowedFunction.includes(userFunctions.All_Milestone_Accounts)) {
              canAccess = false;
            }
            break;
          case "UpdatePortal":
            if (!allowedFunction.includes(userFunctions.Customers) && !allowedFunction.includes(userFunctions.All_Milestone_Accounts)) {
              canAccess = false;
            }
            break;
          case "CreatePortal":
            if (!allowedFunction.includes(userFunctions.Customers) && !allowedFunction.includes(userFunctions.All_Milestone_Accounts)) {
              canAccess = false;
            }
            break;
          case "AccountTags":
            if (!allowedFunction.includes(userFunctions.AccountTag)) { canAccess = false; }
            break;
          case "UpdateTag":
            if (!allowedFunction.includes(userFunctions.AccountTag)) { canAccess = false; }
            break;
          case "CreateTag":
            if (!allowedFunction.includes(userFunctions.AccountTag)) { canAccess = false; }
            break;
          case "CustomData":
            if (!allowedFunction.includes(userFunctions.CustomData)) { canAccess = false; }
            break;
          case "CustomAdd":
            if (!allowedFunction.includes(userFunctions.CustomData)) { canAccess = false; }
            break;
          case "CustomdataUpdate":
            if (!allowedFunction.includes(userFunctions.CustomData)) { canAccess = false; }
            break;
          case "Activities"://just allow reseller access
            if (getAccess["isCustomer"]) { canAccess = false; }
            break;
          case "QuoteEdit":
            // customer cannot edit a quote
            if (getAccess["isCustomer"]) {
              canAccess = false;
            }
            break;
          case "OrderEdit":
            // customer cannot edit an order
            if (getAccess["isCustomer"]) {
              canAccess = false;
            }
            break;
          case "InvoiceEdit":
            // customer cannot edit an invoice
            if (getAccess["isCustomer"]) {
              canAccess = false;
            }
            break;
          case "GlobalParams":
            if (!systemGroups.includes("sys_admin")) {
              canAccess = false;
            }
            break;
          case "DuplicateOrder":
            // customer cannot edit an order
            if (getAccess["isCustomer"]) {
              canAccess = false;
            }
            break;
          case "Engine":
            if (!allowedFunction.includes(userFunctions.Engine)) {
              canAccess = false;
            }
            break;
          case "Opportunities":
            if (!allowedFunction.includes(userFunctions.Opportunities)) {
              canAccess = false;
            }
            break;
          case "OpportunityDetails":
            if (!allowedFunction.includes(userFunctions.Opportunities)) {
              canAccess = false;
            }
            break;
          case "opportunityCreate":
            if (!allowedFunction.includes(userFunctions.Opportunities)) {
              canAccess = false;
            }
            break;
          case "IncognitoMode":
            if (!allowedFunction.includes(userFunctions.IncognitoMode)) {
              canAccess = false;
            }
            break;
          case "Audit":
            if (!allowedFunction.includes(userFunctions.Audit)) {
              canAccess = false;
            }
            break;
          case "ledgerPayments":
            if (!allowedFunction.includes(userFunctions.Ledgers)) {
              canAccess = false;
            }
            break;
          case "ledgerReports":
            if (!allowedFunction.includes(userFunctions.Ledgers)) {
              canAccess = false;
            }
            break;
          case "GlobalTags":
            if (!allowedFunction.includes(userFunctions.GlobalTag)) {
              canAccess = false;
            }
            break;
          case "UpdateGlobalTag":
            if (!allowedFunction.includes(userFunctions.GlobalTag)) {
              canAccess = false;
            }
            break;
          case "CreateGlobalTag":
            if (!allowedFunction.includes(userFunctions.GlobalTag)) {
              canAccess = false;
            }
            break;
          case "CommissionStatement":
            if (!allowedFunction.includes(userFunctions.CommissionStatement)) {
              canAccess = false;
            }
            break;
          case "Deployments": // /Deployments
            if (!allowedFunction.includes(userFunctions.Deployments)) {
              canAccess = false;
            }
            break;
          case "CreateDeployment":
            if (!allowedFunction.includes(userFunctions.Deployments)) {
              canAccess = false;
            }
            break;
          case "ViewDeployment": // /Deployments detail page
            if (!allowedFunction.includes(userFunctions.Deployments)) {
              canAccess = false;
            }
            break;
          case "OrderStatus":
            if (!allowedFunction.includes(userFunctions.Order_Status)) {
              canAccess = false;
            }
            break;
          case "Files":
            if (!allowedFunction.includes(userFunctions.Files)) {
              canAccess = false;
            }
            break;
          case "Logs":
            if (!allowedFunction.includes(userFunctions.Logs) || getAccess["isCustomer"]) {
              canAccess = false;
            }
            break;
          case "GlobalSecurity"://  /GlobalSecurity
            if (!allowedFunction.includes(userFunctions.Global_Security) && !systemGroups.includes("sys_admin")) {
              canAccess = false;
            }
            break;
          case "GlobalPortal":
            if (!allowedFunction.includes(userFunctions.Customers) && !allowedFunction.includes(userFunctions.All_Milestone_Accounts)) {
              canAccess = false;
            }
            break;
          case "FinanceAudit":
            if (!allowedFunction.includes(userFunctions.FinanceAudit)) {
              canAccess = false;
            }
            break;
        }
        if (!canAccess) {
          resetRouterData()
          let hasDashboardAccess = true;
          if (!getAccess["isCustomer"] && !allowedFunction.includes(userFunctions.Dashboard) && !systemGroups.includes("sys_admin")) {
            hasDashboardAccess = false;
          }
          Vue.set(routerData.errorData, 'message', 'You have no access to this.')
          if(hasDashboardAccess) {
            Vue.set(routerData.errorData, 'redirectName', 'Dashboard')
            Vue.set(routerData.errorData, 'redirectQuery', {});
          }
          const win = (window as any)
          win.errorData = routerData.errorData
          next()
          return;
        }

        var navInfo = {
          ReportDetails : "Reports",
          ViewQuote : "Quotes",
          ViewOrder : "Orders",
          ViewSupport : "Supports",
          InvoiceDetails: "Invoices",
          OpportunityDetails: "Opportunities"
        };
        var listPage = navInfo[`${from["name"]}`];
        // if(listPage){
          // console.log("from",from,"to",to)
          // console.log("session",sessionStorage.pageDetails,JSON.parse(sessionStorage.pageDetails))
          // console.log("navInfo",listPage)
          // }
          // else{
          if(!listPage && from["name"] != null && from["name"] != to["name"] && JSON.parse(sessionStorage.pageDetails)[`${to["name"]}`] != undefined ){
            // console.log("Reset page",JSON.parse(sessionStorage.pageDetails)[`${to["name"]}`])
            var oldData = JSON.parse(sessionStorage.pageDetails);
            oldData[`${to["name"]}`] = 1;
            sessionStorage.setItem('pageDetails',JSON.stringify(oldData))
            // JSON.parse(sessionStorage.pageDetails)[`${to["name"]}`] = 1;
            // console.log("Reset page",JSON.parse(sessionStorage.pageDetails)[`${to["name"]}`])
          }
          if (!listPage && from["name"] != null && from["name"] != to["name"] && JSON.parse(sessionStorage.filterDetails)[`${to["name"]}`] != undefined) {
            var oldData = JSON.parse(sessionStorage.filterDetails);
            oldData[`${to["name"]}`] = {};
            sessionStorage.setItem('filterDetails', JSON.stringify(oldData))
          }
          // }
        //need to check more, for some cases such as cannot acess to accounts that he is not set to handle...
        if (to.name != "" && to.name != null) {
          var checkAccessData = await userRepo.checkAccessData(to);
          if (checkAccessData.CANACCESS == false) {
            let tmpQuery = (typeof checkAccessData.REDIRECTQUERY != "undefined" ? checkAccessData.REDIRECTQUERY : {});
            Vue.set(routerData.errorData, 'message', 'Item not exist or you have no access to this.')
            Vue.set(routerData.errorData, 'redirectName', checkAccessData.REDIRECTNAME)
            Vue.set(routerData.errorData, 'redirectQuery', tmpQuery);
            const win = (window as any)
            win.errorData = routerData.errorData
            next()
            return;
          }
        }
      } else if (session == null) {
        if (from.path != '/' && from.path != '/logout' && from.path != '/pay' && from.path != '/payinvoice' && from.path != '/demo' && from.path != '/deploy'
        && from.name != 'DeploymentSchedule'
        ) {
          (document.getElementById("urlParams") as HTMLInputElement).value = from.path;
          (document.getElementById("urlParamsExist") as HTMLInputElement).value = '1';
        }
      }
      if ($("#dashboardClone #container").is(":visible")) {
        $("#dashboardClone #container").hide();
      }
      resetRouterData()
      next();
    } catch (error) {
      //handle error
    }

    let cssFile = '';
    const fillColor = Vue.prototype.$primaryColor || '';
    if (fillColor && getAccess["isCustomer"]) {
      cssFile += `
        .customer-role #page-header .page-title,
        .customer-role .left-section .card .card-summary.total,
        .customer-role .segment-container li.active,
        .customer-role .segment-container li:hover,
        .customer-role .datapoints .dp-content .data-section .ds-title h3 small,
        .customer-role .navTitle,
        .customer-role #card-info .right .top .number,
        .customer-role #card-info .right .total .number,
        .customer-role #quote-totals .right .top .number,
        .customer-role #quote-totals .right .total .number,
        .customer-role #page-total-price ul li .number,
        .customer-role .btn.teal:not(.actionsBtn):hover,
        .customer-role #page-header-details .notes.empty,
        .customer-role #page-header-details .notes .note-label,
        .customer-role #dashboard-header .dashboard-header-data,
        .customer-role .financesDashboard .trends-box .category-trends-count,
        .customer-role #orderNav div:hover,
        .customer-role #orderNav div.active,
        .customer-role #pagination .selection,
        .customer-role .hamburger-menu a:hover,
        .customer-role .tmp-info .tmp-info-title,
        .customer-role .tmp-status-name,
        .customer-role .skuCount,
        .customer-role .tmp-section-title .tmp-segments .tmp-segment-item,
        .customer-role .secTitle,
        .customer-role .infoBubble .currency,
        .customer-role .totalContainer .orderTotal,
        .customer-role .deploymentDashboard .v-content .small-box .blue,
        .customer-role .tmp-list table tbody .tmp-status,
        .customer-role .actionsBtn .action-value,
        .customer-role .tmp-card .tmp-card-title,
        .customer-role .tmp-card .tmp-card-percent * {
            color: ${fillColor} !important;
        }
        
        .customer-role #navbar .right-menu-ico svg path {
            fill: ${fillColor} !important;
        }
        
        .customer-role #navbar.new-header .nav-item.currentPage:before, .customer-role #navbar.new-header .nav-item:hover:before,
        .customer-role #navbar.new-header .nav-item.currentPage:after, .customer-role #navbar.new-header .nav-item:hover:after,
        .customer-role #quote-buy #quote-btn,
        .customer-role .quote-buy-form-buttons div.active,
        .customer-role .PrimaryButton,
        .customer-role select + label:before,
        .customer-role input[placeholder="Date"] + label:before,
        .customer-role #new-page-header input[type="text"]:focus+label:before,
        .customer-role #new-page-header input[type="password"]:focus+label:before,
        .customer-role .datepicker-panel>ul>li.picked,
        .customer-role .datepicker-panel>ul>li.picked:hover,
        .customer-role .datepicker-panel>ul>li:hover,
        .customer-role .datepicker-panel>ul>li.highlighted:hover,
        .customer-role .btn-primary,
        .customer-role .btn.teal,
        .customer-role .btn.no-check,
        .customer-role #page-header .commentsContainer .actionsBtn,
        .customer-role #chat-window .customer,
        .customer-role .active .cardBtn,
        .customer-role .input-label[data-v-bd5d18b8]:not(.noborder):after,
        .customer-role input[type="text"]+label:after,
        .customer-role input[type="password"]+label:after,
        .customer-role input[type="number"]+label:after,
        .customer-role input[type="email"]+label:after,
        .customer-role textArea+label:after,
        .customer-role #dashboard-header .trends-box,
        .customer-role .dashboard #quick-link ul li .count,
        .customer-role .quick-link ul li .count,
        .customer-role #quote-buy-form-buttons div.active,
        .customer-role .custom-control-input:checked~.custom-control-label:before,
        .customer-role .tags-list .checkbox-container input:checked ~ .checkmark,
        .customer-role .tags-list .checkbox-container:hover input ~ .checkmark:not(.disabledCheckbox),
        .customer-role #searchBox .checkbox-container input:checked~.checkmark,
        .customer-role .devicesColor,
        .customer-role .tmp-section-title .tmp-segments .tmp-segment-item.active:after,
        .customer-role .quantityColor,
        .customer-role .calloutExpand,
        .customer-role .deploymentDashboard .v-content .small-box .process .percent-box .percent-value.full,
        .customer-role .quoteUsers,
        .customer-role .eu-info .total-cnt,
        .customer-role .saveBtn,
        .customer-role .multiOrders span,
        .customer-role .qUsers span {
            background-color: ${fillColor} !important;
        }
        
        .customer-role .btn.teal:not(.actionsBtn):hover,
        .customer-role .dashboard #quick-link ul li .text,
        .customer-role .quick-link ul li .text,
        .customer-role .quoteUsers {
            border: 1px solid ${fillColor} !important;
        }
        
        .customer-role #orderNav div.active:before {
            border-top: 2px solid ${fillColor};
        }
        
        .customer-role .btn-primary:hover,
        .customer-role #navbar.new-header .nav-item:before {
            background: ${fillColor} !important;
        }
        
        .customer-role .checkbox-container input:checked~.checkmark,
        .customer-role .checkbox-container:hover input~.checkmark:not(.disabledCheckbox),
        .customer-role .btn-primary,
        .customer-role .dropdown-container .item .item-checkbox:checked ~ .checkmark,
        .customer-role .dropdown-container .item:hover .item-checkbox ~ .checkmark,
        .customer-role #footer-search input:focus,
        .customer-role .dropdown-container .header .filter-wrapper .input:checked ~ .checkmark,
        .customer-role .dropdown-container .header .filter-wrapper .input:hover ~ .checkmark,
        .customer-role .custom-control-input:checked~.custom-control-label:before {
            border-color: ${fillColor} !important;
            background-color: transparent;
        }
        
        .customer-role .segment-container li.active,
        .customer-role .segment-container li:hover {
            border-bottom-color: ${fillColor} !important;
        }
        
        .customer-role .tmp-card .tmp-card-percent svg circle:nth-child(2) {
            stroke: ${fillColor} !important;
        }
        
        .customer-role  #page-header .buttonContainer .btn.gray,
        .customer-role  #page-header .buttonContainer .btn.teal,
        .customer-role .popover-content .PrimaryButton {
            background-color: ${fillColor} !important;
        }
        
        .customer-role .page-title .dropdown-toggle:after,
        .customer-role  #page-header .buttonContainer .actionsBtn:not(.customize-button):not(.isFilter) {
            background: url(../images/icon_white_dropdown_rounded.svg) top 14px right 25px no-repeat;
        }
        
        .customer-role .timeline-item.complete .status {
            border-top: 4px solid ${fillColor} !important;
        }
        
        .customer-role .timeline-item.complete h4:before {
            background-color: ${fillColor} !important;
            border: 1px solid ${fillColor} !important;
        }
        
        .customer-role .order-list-new .list-item .order-progress-bar-customer .order-progress {
            background-image: linear-gradient(to right, ${fillColor}, ${fillColor});
        }
        
        .customer-role #searchResults-box .third.ib.right.tar {
            color: ${fillColor} !important;
        }
        
        .customer-role #page-header .page-title[data-type=dashboard] {
            background: url('data:image/svg+xml, <svg width="32" height="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M22 12H18L15 21L9 3L6 12H2" stroke="${fillColor.replace('#', '%23')}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>') center left no-repeat !important;
        }
        
        
        .customer-role #page-header .page-title[data-type=orders] {
            background: url('data:image/svg+xml, <svg width="32" height="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6 2L3 6V20C3 20.5304 3.21071 21.0391 3.58579 21.4142C3.96086 21.7893 4.46957 22 5 22H19C19.5304 22 20.0391 21.7893 20.4142 21.4142C20.7893 21.0391 21 20.5304 21 20V6L18 2H6Z" stroke="${fillColor.replace('#', '%23')}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M3 6H21" stroke="${fillColor.replace('#', '%23')}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M16 10C16 11.0609 15.5786 12.0783 14.8284 12.8284C14.0783 13.5786 13.0609 14 12 14C10.9391 14 9.92172 13.5786 9.17157 12.8284C8.42143 12.0783 8 11.0609 8 10" stroke="${fillColor.replace('#', '%23')}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>') center left no-repeat !important;
        }
        
        .customer-role #page-header .page-title[data-type=quotes] {
            background: url('data:image/svg+xml, <svg width="32" height="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20.59 13.41L13.42 20.58C13.2343 20.766 13.0137 20.9135 12.7709 21.0141C12.5281 21.1148 12.2678 21.1666 12.005 21.1666C11.7422 21.1666 11.4819 21.1148 11.2391 21.0141C10.9963 20.9135 10.7757 20.766 10.59 20.58L2 12V2H12L20.59 10.59C20.9625 10.9647 21.1716 11.4716 21.1716 12C21.1716 12.5284 20.9625 13.0353 20.59 13.41Z" stroke="${fillColor.replace('#', '%23')}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M7 7H7.01" stroke="${fillColor.replace('#', '%23')}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>') center left no-repeat !important;
        }
        
        .customer-role #page-header .page-title[data-type=profile],
        .customer-role #page-header .page-title[data-type=users] {
            background: url('data:image/svg+xml, <svg width="32" height="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20 21V19C20 17.9391 19.5786 16.9217 18.8284 16.1716C18.0783 15.4214 17.0609 15 16 15H8C6.93913 15 5.92172 15.4214 5.17157 16.1716C4.42143 16.9217 4 17.9391 4 19V21" stroke="${fillColor.replace('#', '%23')}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z" stroke="${fillColor.replace('#', '%23')}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>') center left no-repeat !important;
        }
        
        .customer-role #page-header .page-title[data-type=finances] {
            background: url('data:image/svg+xml, <svg width="32" height="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M21 4H3C1.89543 4 1 4.89543 1 6V18C1 19.1046 1.89543 20 3 20H21C22.1046 20 23 19.1046 23 18V6C23 4.89543 22.1046 4 21 4Z" stroke="${fillColor.replace('#', '%23')}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M1 10H23" stroke="${fillColor.replace('#', '%23')}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>') center left no-repeat !important;
        }
        
        .customer-role #page-header .page-title[data-type=hardware] {
            background: url('data:image/svg+xml, <svg width="32" height="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M22 12H2" stroke="${fillColor.replace('#', '%23')}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M5.45 5.11L2 12V18C2 18.5304 2.21071 19.0391 2.58579 19.4142C2.96086 19.7893 3.46957 20 4 20H20C20.5304 20 21.0391 19.7893 21.4142 19.4142C21.7893 19.0391 22 18.5304 22 18V12L18.55 5.11C18.3844 4.77679 18.1292 4.49637 17.813 4.30028C17.4967 4.10419 17.1321 4.0002 16.76 4H7.24C6.86792 4.0002 6.50326 4.10419 6.18704 4.30028C5.87083 4.49637 5.61558 4.77679 5.45 5.11Z" stroke="${fillColor.replace('#', '%23')}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M6 16H6.01" stroke="${fillColor.replace('#', '%23')}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M10 16H10.01" stroke="${fillColor.replace('#', '%23')}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>') center left no-repeat !important;
        }
        
        .customer-role #page-header .page-title[data-type=profile],
        .customer-role #page-header .page-title[data-type=users] {
            background: url('data:image/svg+xml, <svg width="32" height="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20 21V19C20 17.9391 19.5786 16.9217 18.8284 16.1716C18.0783 15.4214 17.0609 15 16 15H8C6.93913 15 5.92172 15.4214 5.17157 16.1716C4.42143 16.9217 4 17.9391 4 19V21" stroke="${fillColor.replace('#', '%23')}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z" stroke="${fillColor.replace('#', '%23')}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>') center left no-repeat !important;
        }
        
        .customer-role #page-header .page-title[data-type=customers] {
            background: url('data:image/svg+xml, <svg width="32" height="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17 21V19C17 17.9391 16.5786 16.9217 15.8284 16.1716C15.0783 15.4214 14.0609 15 13 15H5C3.93913 15 2.92172 15.4214 2.17157 16.1716C1.42143 16.9217 1 17.9391 1 19V21" stroke="${fillColor.replace('#', '%23')}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M9 11C11.2091 11 13 9.20914 13 7C13 4.79086 11.2091 3 9 3C6.79086 3 5 4.79086 5 7C5 9.20914 6.79086 11 9 11Z" stroke="${fillColor.replace('#', '%23')}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M23 20.9999V18.9999C22.9993 18.1136 22.7044 17.2527 22.1614 16.5522C21.6184 15.8517 20.8581 15.3515 20 15.1299" stroke="${fillColor.replace('#', '%23')}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M16 3.12988C16.8604 3.35018 17.623 3.85058 18.1676 4.55219C18.7122 5.2538 19.0078 6.11671 19.0078 7.00488C19.0078 7.89305 18.7122 8.75596 18.1676 9.45757C17.623 10.1592 16.8604 10.6596 16 10.8799" stroke="${fillColor.replace('#', '%23')}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>') center left no-repeat !important;
        }
        
        .customer-role #page-header .page-title[data-type=deployments] {
            background: url('data:image/svg+xml, <svg width="32" height="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.5 9.39996L7.5 4.20996" stroke="${fillColor.replace('#', '%23')}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M21 15.9999V7.9999C20.9996 7.64918 20.9071 7.30471 20.7315 7.00106C20.556 6.69742 20.3037 6.44526 20 6.2699L13 2.2699C12.696 2.09437 12.3511 2.00195 12 2.00195C11.6489 2.00195 11.304 2.09437 11 2.2699L4 6.2699C3.69626 6.44526 3.44398 6.69742 3.26846 7.00106C3.09294 7.30471 3.00036 7.64918 3 7.9999V15.9999C3.00036 16.3506 3.09294 16.6951 3.26846 16.9987C3.44398 17.3024 3.69626 17.5545 4 17.7299L11 21.7299C11.304 21.9054 11.6489 21.9979 12 21.9979C12.3511 21.9979 12.696 21.9054 13 21.7299L20 17.7299C20.3037 17.5545 20.556 17.3024 20.7315 16.9987C20.9071 16.6951 20.9996 16.3506 21 15.9999Z" stroke="${fillColor.replace('#', '%23')}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M3.27002 6.95996L12 12.01L20.73 6.95996" stroke="${fillColor.replace('#', '%23')}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M12 22.08V12" stroke="${fillColor.replace('#', '%23')}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>') top 5px left no-repeat !important;
        }
        
        .customer-role #page-header .page-title[data-type=support] {
            background: url('data:image/svg+xml, <svg width="32" height="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M21 11.5C21.0034 12.8199 20.6951 14.1219 20.1 15.3C19.3944 16.7118 18.3098 17.8992 16.9674 18.7293C15.6251 19.5594 14.0782 19.9994 12.5 20C11.1801 20.0035 9.87812 19.6951 8.7 19.1L3 21L4.9 15.3C4.30493 14.1219 3.99656 12.8199 4 11.5C4.00061 9.92179 4.44061 8.37488 5.27072 7.03258C6.10083 5.69028 7.28825 4.6056 8.7 3.90003C9.87812 3.30496 11.1801 2.99659 12.5 3.00003H13C15.0843 3.11502 17.053 3.99479 18.5291 5.47089C20.0052 6.94699 20.885 8.91568 21 11V11.5Z" stroke="${fillColor.replace('#', '%23')}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>') center left no-repeat !important;
        }
        
        .customer-role #page-header .page-title[data-type=reports] {
            background: url('data:image/svg+xml, <svg width="32" height="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M21 8V21H3V8" stroke="${fillColor.replace('#', '%23')}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M23 3H1V8H23V3Z" stroke="${fillColor.replace('#', '%23')}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M10 12H14" stroke="${fillColor.replace('#', '%23')}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>') center left no-repeat !important;
        }
        
        .customer-role #page-header .page-title[data-type=portals] {
            background: url('data:image/svg+xml, <svg width="32" height="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3Z" stroke="${fillColor.replace('#', '%23')}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M3 9H21" stroke="${fillColor.replace('#', '%23')}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M9 21V9" stroke="${fillColor.replace('#', '%23')}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>') center left no-repeat !important;
        }
        
        .customer-role #page-header .page-title[data-type=engine] {
            background: url('data:image/svg+xml, <svg width="32" height="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13 2L3 14H12L11 22L21 10H12L13 2Z" stroke="${fillColor.replace('#', '%23')}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>') center left no-repeat !important;
        }
        
        .customer-role #page-header .page-title[data-type=admin] {
            background: url('data:image/svg+xml, <svg width="32" height="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20 7H4C2.89543 7 2 7.89543 2 9V19C2 20.1046 2.89543 21 4 21H20C21.1046 21 22 20.1046 22 19V9C22 7.89543 21.1046 7 20 7Z" stroke="${fillColor.replace('#', '%23')}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M16 21V5C16 4.46957 15.7893 3.96086 15.4142 3.58579C15.0391 3.21071 14.5304 3 14 3H10C9.46957 3 8.96086 3.21071 8.58579 3.58579C8.21071 3.96086 8 4.46957 8 5V21" stroke="${fillColor.replace('#', '%23')}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>') center left no-repeat !important;
        }
        
        .customer-role #page-header .page-title[data-type=custom] {
            background: url('data:image/svg+xml, <svg width="32" height="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M22 19C22 19.5304 21.7893 20.0391 21.4142 20.4142C21.0391 20.7893 20.5304 21 20 21H4C3.46957 21 2.96086 20.7893 2.58579 20.4142C2.21071 20.0391 2 19.5304 2 19V5C2 4.46957 2.21071 3.96086 2.58579 3.58579C2.96086 3.21071 3.46957 3 4 3H9L11 6H20C20.5304 6 21.0391 6.21071 21.4142 6.58579C21.7893 6.96086 22 7.46957 22 8V19Z" stroke="${fillColor.replace('#', '%23')}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>') center left no-repeat !important;
        }
        
        .customer-role #page-header .page-title[data-type="logs"] {
            background: url('data:image/svg+xml, <svg width="32" height="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 3H8C9.06087 3 10.0783 3.42143 10.8284 4.17157C11.5786 4.92172 12 5.93913 12 7V21C12 20.2044 11.6839 19.4413 11.1213 18.8787C10.5587 18.3161 9.79565 18 9 18H2V3Z" stroke="${fillColor.replace('#', '%23')}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M22 3H16C14.9391 3 13.9217 3.42143 13.1716 4.17157C12.4214 4.92172 12 5.93913 12 7V21C12 20.2044 12.3161 19.4413 12.8787 18.8787C13.4413 18.3161 14.2044 18 15 18H22V3Z" stroke="${fillColor.replace('#', '%23')}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>') center left no-repeat !important;
        }
        
        .customer-role #page-header .page-title[data-type="settings"] {
            background: url('data:image/svg+xml, <svg  width="30" height="30" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M480 384H249.2C236.9 355.8 208.8 336 176 336S115.1 355.8 102.8 384H32c-17.67 0-32 14.33-32 32s14.33 32 32 32h70.75C115.1 476.2 143.2 496 176 496s60.89-19.77 73.25-48H480c17.67 0 32-14.33 32-32S497.7 384 480 384zM176 448c-17.64 0-32-14.36-32-32s14.36-32 32-32s32 14.36 32 32S193.6 448 176 448zM480 224h-70.75C396.9 195.8 368.8 176 336 176S275.1 195.8 262.8 224H32C14.33 224 0 238.3 0 256s14.33 32 32 32h230.8C275.1 316.2 303.2 336 336 336s60.89-19.77 73.25-48H480c17.67 0 32-14.33 32-32S497.7 224 480 224zM336 288c-17.64 0-32-14.36-32-32s14.36-32 32-32s32 14.36 32 32S353.6 288 336 288zM32 128h102.8C147.1 156.2 175.2 176 208 176s60.89-19.77 73.25-48H480c17.67 0 32-14.33 32-32s-14.33-32-32-32h-198.8C268.9 35.77 240.8 16 208 16S147.1 35.77 134.8 64H32C14.33 64 0 78.33 0 96S14.33 128 32 128zM208 64c17.64 0 32 14.36 32 32s-14.36 32-32 32s-32-14.36-32-32S190.4 64 208 64z" fill="${fillColor.replace('#', '%23')}" /></svg>') center left no-repeat !important;
        }  
        
        .customer-role .support-list .list-item .progress-bar .progress,
        .customer-role .support-list .support-row-item .progress-bar .progress {
            background-image: linear-gradient(to right, #efefef 0%, ${fillColor}) !important;
        }
        
        .customer-role .process-section .process-item .process-content dl dd .percent-box .percent-value {
            background: linear-gradient(90deg, #efefef 0%, ${fillColor} 100%) !important;
        }
        
        .customer-role .deploymentDashboard .hasCheck:after {
            background: url('data:image/svg+xml;utf8,<svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(%23clip0_794_18)"><path d="M10.0832 5.07835V5.50002C10.0826 6.48838 9.76257 7.45008 9.17078 8.24169C8.579 9.0333 7.74718 9.61241 6.79938 9.89264C5.85158 10.1729 4.83858 10.1392 3.91147 9.7967C2.98436 9.45418 2.1928 8.82115 1.65486 7.99201C1.11692 7.16287 0.861408 6.18205 0.926439 5.19583C0.99147 4.20961 1.37356 3.27084 2.01572 2.51951C2.65787 1.76819 3.5257 1.24457 4.48976 1.02675C5.45382 0.808936 6.46246 0.90859 7.36526 1.31085" stroke="%230091FF" stroke-width="1.69231" stroke-linecap="round" stroke-linejoin="round"/><path d="M10.0833 1.83325L5.5 6.42117L4.125 5.04617" stroke="${fillColor.replace('#', '%23')}" stroke-width="1.69231" stroke-linecap="round" stroke-linejoin="round"/></g><defs><clipPath id="clip0_794_18"><rect width="11" height="11" fill="white"/></clipPath></defs></svg>') center left no-repeat !important;
        }
        
        .customer-role input:checked+.slider {
            background-color: ${fillColor} !important;
        }
        
        .customer-role .productCountColor {
            background-color: ${fillColor} !important;
        }
        
        .customer-role .contracts-list .tab-active, 
        .customer-role .pricing-list .tab-active {
          border-bottom: 3px solid ${fillColor};
        }
        
        .customer-role .invoice-details-page .actionsBtn .menu li:not(.alertCount):hover,
        .customer-role .invoice-details-page .actionsBtn .menu li.active,
        .customer-role .invoice-details-page .actionsBtn .menu .li.active {
            color: ${fillColor};
        }
        `;
    }
    $("head style[id=customerStyle]").remove();
    $("head").append(`<style id='customerStyle'>${cssFile}</style>`);
  }
)

declare global {
  interface Window {
    router: Router
  }
}

window.router = router

export default router
