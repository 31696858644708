import { render, staticRenderFns } from "./CustomerDetails.vue?vue&type=template&id=5b9baebd&scoped=true"
import script from "./CustomerDetails.vue?vue&type=script&lang=tsx"
export * from "./CustomerDetails.vue?vue&type=script&lang=tsx"
import style0 from "./CustomerDetails.vue?vue&type=style&index=0&id=5b9baebd&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b9baebd",
  null
  
)

export default component.exports